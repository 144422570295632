define("webcostume/apai/entity/stockin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stockinfieldName = void 0;
  let stockinfieldName = {
    stockInID: 'intStockInID',
    referenceNo: 'strStockInReferenceNo',
    stockInDate: 'dteStockInDate',
    inputDate: 'dtmStockInInputDate',
    userID: 'intStockInPHUserID',
    remark: 'strStockInRemark'
  };
  _exports.stockinfieldName = stockinfieldName;
});