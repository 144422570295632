define("webcostume/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;

  function isEqual([param1, param2]
  /*, hash*/
  ) {
    return param1 == param2;
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});