define("webcostume/controllers/transaction-exchange/index", ["exports", "webcostume/apai/gridline/transaction-sale-line", "moment"], function (_exports, _transactionSaleLine, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getTotal(arrTransactionLine) {
    let total = 0;
    arrTransactionLine.forEach(function (item, index) {
      total += numeral(item.subtotal).value();
    });
    return total;
  }

  function makeNewBeginning(this_is_this) {
    let arrTransactionLine = this_is_this.get('arrTransactionLine');
    let arrReturnLine = this_is_this.get('arrReturnLine');
    arrTransactionLine.clear();
    arrReturnLine.clear();
    this_is_this.set('currExchange', this_is_this.get('store').createRecord('exchange', {
      exchangeDate: new Date(),
      remark: '',
      adjustment: 0
    }));
    clearLineItem(this_is_this);
    clearLineItemForTransaction(this_is_this);
    this_is_this.set('currentData.exchangeItemList', Ember.A([]));
    this_is_this.set('currentData.exchangeItemProductStockList', Ember.A([]));
    this_is_this.set('currentTransaction.salePricing', '');
    this_is_this.set('currentData.discountAmount', 0);
    this_is_this.set('currentData.customerName', '');
    this_is_this.set('currentData.generation', 0);
    this_is_this.set('currExchange.saleID', 0);
    this_is_this.set('currentData.invoiceNo', '');
    this_is_this.set('status', 'NEW'); // console.log('------');
    // console.log('Here 2');

    let paymentSelectionHandler = this_is_this.get('paymentSelectionHandler'); // let paymentDetail = paymentSelectionHandler.getDetail();

    if (paymentSelectionHandler != null && !paymentSelectionHandler.get('isDestroyed')) {
      paymentSelectionHandler.clearData();
    }

    this_is_this.set('currPaymentType', 'NETS');
    this_is_this.set('myCheque', {
      chequeDate: '',
      chequeNo: '',
      bankSelection: ''
    });
  }

  function setLineItemForTransaction(this_is_madness, data, productSize, existing, productSizeInputHandler, transactionLine) {
    let myPromise = productSize.getSizes(data['sizeGroup']);
    let outsideData = data;
    myPromise.then(data => {
      this_is_madness.set('arrProductSizeTransaction', data);

      if (!existing) {
        this_is_madness.set('productSizeChangedTransaction', new Date()); // here to reset arrTextBox 
      } else {
        // no reset needed cos we are setting it.
        productSizeInputHandler.set('arrTextBox', transactionLine.arrTextBox);
        this_is_madness.set('currentTransaction.currentLineTotalQty', productSizeInputHandler.getTotalQty());
      } // call function here to load product size 


      productSizeInputHandler.loadRemainingQty(outsideData['strProductItemCode']);
    });
    this_is_madness.set('currentTransaction.currProduct', data);
    this_is_madness.set('currentTransaction.itemCode', data['strProductItemCode']);
    this_is_madness.set('currentTransaction.productDescription', data['strProductDescription']);

    if (!existing) {
      this_is_madness.set('currentTransaction.currentLineTotalQty', 0); // follow the invoice pricing 

      if (this_is_madness.get('currentTransaction.salePricing').toUpperCase() == 'RETAIL') {
        this_is_madness.set('currentTransaction.sellingPrice', data['dclProductSellingPrice']);
      } else {
        this_is_madness.set('currentTransaction.sellingPrice', data['decProductWholesalePrice']);
      }
    } else {
      this_is_madness.set('currentTransaction.note', transactionLine.note);
      this_is_madness.set('currentTransaction.sellingPrice', transactionLine.sellingPrice);
    }
  }

  function clearLineItem(this_is_madness) {
    let productsizeHandler = this_is_madness.get('productSizeInputHandler');
    console.log('clearLineItem'); // this check is to prevent error when route is not visible

    console.log(productsizeHandler);

    if (productsizeHandler !== null) {
      if (productsizeHandler.get('isDestroyed') || productsizeHandler.get('isDestroying')) {
        return;
      }
    }

    productsizeHandler.clearAll();
    this_is_madness.set('currentData.currExchangeItem', null);
    this_is_madness.set('currentData.sellingPrice', 0);
    this_is_madness.set('currentData.currentLineTotalQty', 0);
    this_is_madness.set('currentData.note', '');
  }

  function clearLineItemForTransaction(this_is_madness) {
    this_is_madness.set('arrProductSizeTransaction', []);
    this_is_madness.set('productSizeChangedTransaction', new Date());
    this_is_madness.set('currentTransaction.currProduct', null);
    this_is_madness.set('currentTransaction.itemCode', '');
    this_is_madness.set('currentTransaction.productDescription', '');
    this_is_madness.set('currentTransaction.sellingPrice', 0);
    this_is_madness.set('currentTransaction.currentLineTotalQty', 0);
    this_is_madness.set('currentTransaction.note', '');
  }

  function searchArrProductQty(this_is_this, index) {
    let exchangeItem = this_is_this.get('currentData.exchangeItemList').objectAt(index);
    let exchangeItemProductStockList = this_is_this.get('currentData.exchangeItemProductStockList');
    let salelnGroup = exchangeItem['intGroup'];
    let productID = exchangeItem['ProductID'];
    let sellingPrice = numeral(exchangeItem['SellingPrice']).format('0.0000'); // same as the server formatting

    let arrProductQtyFound = exchangeItemProductStockList[salelnGroup][productID][sellingPrice]; // format it so that product size input can use directly

    let arrProductQtyForPSI = [];
    arrProductQtyFound.forEach(item => {
      arrProductQtyForPSI.pushObject(Ember.Object.create({
        qty: item.TotalQty,
        sizeID: item.strSalelnProductSizeStockSizeID
      }));
    });
    return arrProductQtyForPSI;
  } // data must contain {sizeGroup, }


  function setLineItem(this_is_madness, existing, productSizeInputHandler, indexOfComboBox) {
    let exchangeItem = this_is_madness.get('currentData.exchangeItemList').objectAt(indexOfComboBox);
    let sizeGroup = exchangeItem['SizeGroup']; // searchArrProductQty  contains {sizeID, qty }

    let arrProductQty = searchArrProductQty(this_is_madness, indexOfComboBox);
    console.log('preparing to load remaining qty off line');
    console.log(productSizeInputHandler);
    productSizeInputHandler.loadRemainingQtyOffline(sizeGroup, arrProductQty);

    if (!existing) {
      this_is_madness.set('currentData.currentLineTotalQty', 0);
      this_is_madness.set('currentData.sellingPrice', exchangeItem.SellingPrice);
    } else {
      this_is_madness.set('currSaleln.note', transactionLine.note);
      this_is_madness.set('currSaleln.sellingPrice', transactionLine.sellingPrice);
    }

    console.log('end set line item ***');
  }

  let ExchangeItem = Ember.Object.extend({});

  function manageExchangeData(this_is_this, data) {
    let exchangeListDownloaded = data.exchangeList;
    let listOfExchangeItem = [];
    exchangeListDownloaded.forEach(function (item) {
      console.log(item);
      let exchangeItem = ExchangeItem.create({
        itemCode: item.itemCode,
        itemName: item.strProductDescription,
        intGroup: item.intGroup,
        ProductID: item.ProductID,
        SellingPrice: item.SellingPrice,
        SizeGroup: item.strSalelnProductSizeStockSizeGroup,
        enabled: true
      });
      listOfExchangeItem.push(exchangeItem);
    });
    this_is_this.set('currentData.exchangeItemList', listOfExchangeItem);
    this_is_this.set('currentData.exchangeItemProductStockList', data.productStock);
    this_is_this.set('currentTransaction.salePricing', data.basicSaleInfo.pricing);
    this_is_this.set('currentData.discountAmount', data.basicSaleInfo.discountAmount);
    this_is_this.set('currentData.customerName', data.customerName);
    this_is_this.set('currentData.generation', data.generation);
    this_is_this.set('currExchange.saleID', data.basicSaleInfo.saleID);
    this_is_this.set('currentData.invoiceNo', data.basicSaleInfo.invoiceNo);
  }

  let ExchangeItemManager = Ember.Object.extend({
    addToCombo() {},

    AddItemComboBox() {},

    RemoveItemComboBox() {}

  });

  function validateForm(this_is_this) {
    if (!this_is_this.get('currExchange')) {
      alert('Please select a exchange.'); //$('#actr_customerName').focus();

      return false;
    } else if (!this_is_this.arrTransactionLine || this_is_this.arrTransactionLine.length == 0) {
      alert('There is no exchange line');
      return false;
    } else if (!this_is_this.arrReturnLine || this_is_this.arrReturnLine.length == 0) {
      alert('There is no return  line');
      return false;
    } else if (!this_is_this.getPayment()) {
      return false;
    }

    return true;
  }

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    paymentSelectionHandler: null,
    objExchangeItemManager: ExchangeItemManager.create({}),
    myCheque: {
      chequeDate: '',
      chequeNo: '',
      bankSelection: ''
    },
    arrTransactionLineChanged: Ember.observer('arrTransactionLine.@each.subtotal', function () {
      let total = getTotal(this.arrTransactionLine);
      this.set('currentData.exchangeGetTotal', numeral(total).format('0,0.00')); // let servicePHSetting = this.get('servicePHSetting');
      // let GST =total * parseFloat(servicePHSetting.getKey('GST'));
      // this.set('current.grandGST', GST);
      // this.set('current.grandTotal', total + GST);
    }),
    arrReturnLineChanged: Ember.observer('arrReturnLine.@each.subtotal', function () {
      let total = getTotal(this.arrReturnLine);
      this.set('currentData.exchangeReturnTotal', numeral(total).format('0,0.00')); // let servicePHSetting = this.get('servicePHSetting');
      // let GST =total * parseFloat(servicePHSetting.getKey('GST'));
      // this.set('current.grandGST', GST);
      // this.set('current.grandTotal', total + GST);
    }),
    grandTotalObserver: Ember.observer('currentData.exchangeReturnTotal', 'currentData.exchangeGetTotal', 'currExchange.adjustment', function () {
      let total = -numeral(this.get('currentData.exchangeReturnTotal')).value() + numeral(this.get('currentData.exchangeGetTotal')).value() - numeral(this.get('currExchange.adjustment')).value();
      console.log('here');
      console.log(total);
      this.set('currentData.grandTotal', total);
    }),
    onTotalQtyPricechanged: Ember.observer('currentData.currentLineTotalQty', 'currentData.sellingPrice', function () {
      let subtotal = numeral(this.get('currentData.currentLineTotalQty')).value() * numeral(this.get('currentData.sellingPrice')).value();
      this.set('currentData.subTotal', numeral(subtotal).format('0,0.00'));
    }),
    onTotalQtyPriceTransactionchanged: Ember.observer('currentTransaction.currentLineTotalQty', 'currentTransaction.sellingPrice', function () {
      let subtotal = numeral(this.get('currentTransaction.currentLineTotalQty')).value() * numeral(this.get('currentTransaction.sellingPrice')).value();
      this.set('currentTransaction.subTotal', numeral(subtotal).format('0,0.00'));
    }),
    entitySale: Ember.inject.service('entity-sale'),
    entityExchange: Ember.inject.service('entity-exchange'),
    entityProductSize: Ember.inject.service('entity-productsize'),
    saleListController: Ember.inject.controller('transaction-sale.sale-list'),
    appController: Ember.inject.controller('application'),
    commonUtility: Ember.inject.service(),
    productSizeInputHandler: 'a',
    // handler for size control
    productSizeInputHandlerTransaction: '',
    saleID: '',
    // this variable is to communicate with sale list, when it sets, it will be checked during didTransition
    myInitDone: false,
    currExchange: null,

    isDirty() {
      console.log('isDirty');
      console.log(this.get('currentData.invoiceNo'));
      return this.get('currentData.invoiceNo') !== '';
    },

    myInit() {
      if (!this.get('myInitDone')) {
        this.set('currExchange', this.get('store').createRecord('exchange', {
          exchangeDate: new Date(),
          adjustment: 0,
          remark: ''
        }));
        let currExchange = this.get('currExchange');
        this.get('entityExchange').getExchangeNo().then(function (data) {
          currExchange.set('referenceNo', data);
        }); // let productSize = this.get('entityProductSize');

        let service = this.get('servicePHSetting');
        this.set('myInitDone', true);
      }
    },

    /**
     * This is needed because when you transition out and back in, the component is destroyed. 
     * so you need to invoke this from didTransition
     */
    loadDataFromSaleList() {
      let saleID = this.get('saleID');
      this.set('saleID', '');
      if (!saleID || saleID == '') return;
      let commonUtility = this.get('commonUtility');
      let this_is_this = this;
      commonUtility.HTTPGetSomething('getexchangeitemsbysaleid', {
        saleID: saleID
      }).then(data => {
        manageExchangeData(this_is_this, data);
        console.log(this_is_this.get('currentData.exchangeItemList'));
      });
    },

    setInvoiceNo(saleID, invoiceNo) {
      this.set('currentData.invoiceNo', invoiceNo);
      this.set('saleID', saleID);
    },

    currPaymentType: 'NETS',
    openPayment: false,

    getTransaction() {
      let transactLine = [];
      this.arrTransactionLine.forEach(function (item, value) {
        let qtyList = [];
        item.arrTextBox.forEach(function (itemTextBox, indexTextBox) {
          if (itemTextBox.qty > 0) {
            qtyList.pushObject({
              sizeID: itemTextBox.sizeID,
              qty: itemTextBox.qty
            });
          }
        });
        let currObject = {
          itemCode: item.itemCode,
          sellingPrice: item.sellingPrice,
          note: item.note,
          qtyList: qtyList //console.log(currObject);

        };
        transactLine.pushObject(currObject);
      });
      let arrReturnLine = [];
      console.log('this.arrReturnLine');
      this.arrReturnLine.forEach(function (item, value) {
        let qtyList = [];
        item.arrTextBox.forEach(function (itemTextBox, indexTextBox) {
          if (itemTextBox.qty > 0) {
            qtyList.pushObject({
              sizeID: itemTextBox.sizeID,
              qty: itemTextBox.qty
            });
          }
        });
        let currObject = {
          itemCode: item.itemCode,
          sellingPrice: item.sellingPrice,
          note: item.note,
          qtyList: qtyList //console.log(currObject);

        };
        arrReturnLine.pushObject(currObject);
      }); //console.log('get payment');
      //console.log(data);

      return {
        returnLine: arrReturnLine,
        transactionLine: transactLine
      };
    },

    getPayment() {
      let paymentSelectionHandler = this.get('paymentSelectionHandler');
      let chequeDetail = null;

      if (this.get('currPaymentType').toUpperCase() == 'CHEQUE') {
        let paymentDetail = paymentSelectionHandler.getDetail();
        chequeDetail = JSON.parse(JSON.stringify(paymentDetail));

        if (paymentDetail.cheque.chequeNo === '') {
          alert('Please input cheque no');
          return null;
        } else if (this.get('cheque.chequeDate') == '') {
          alert('Please fill in cheque date');
          return null;
        }
      }

      return {
        paymentMethod: this.get('currPaymentType'),
        cheque: this.get('myCheque')
      };
    },

    startSaving() {
      let appController = this.get('appController');
      appController.openDialog('Saving...');
      let exchangeController = this;
      let originalStatus = this.get('status');
      let commonUtility = this.get('commonUtility');
      let url = 'exchange/save';
      let headers = commonUtility.giveMeHeader();
      let myPromiseToSaveYou = new Promise(function (resolve, reject) {
        //console.log('exchangeController promise');
        let data = {
          transaction: exchangeController.getTransaction(),
          payment: exchangeController.getPayment(),
          exchange: JSON.parse(JSON.stringify(exchangeController.get('currExchange')))
        };
        console.log(data);
        commonUtility.HTTPPostSomething(url, data).then(function (data) {
          console.log('save');
          exchangeController.set('currExchange.referenceNo', data.referenceNo);
          exchangeController.set('currExchange.exchangeID', data.exchangeID);
          exchangeController.set('currExchange.generation', data.generation); //set total generation

          exchangeController.set('currentData.generation', data.totalGeneration);
          resolve('done');
        }).catch(function () {
          resolve('error');
        });
      });
      myPromiseToSaveYou.then(function (data) {
        if (data == 'error') {
          alert('Save failed.');
        } else {
          exchangeController.set('status', 'SAVED');
        }

        appController.closeDialog();
      });
    },

    makeNewBeginning() {
      makeNewBeginning(this);
    },

    actions: {
      btnPrint_click() {
        let commonUtility = this.get('commonUtility');
        let session = this.get('session');
        let headerToSend = {
          exchangeID: this.get('currExchange.exchangeID'),
          sessionID: session.giveMeSessionID()
        };
        let url = commonUtility.giveMeFullUrl('exchange/report', headerToSend);
        window.open(url);
      },

      trytrydate(dateValue) {
        console.log('try try date');
        console.log(dateValue);
      },

      btnNew_click() {
        if (this.get('status') === 'NEW') {
          let answer = confirm('Are you sure you want to start new exchange?');

          if (!answer) {
            return;
          }
        }

        makeNewBeginning(this);
      },

      btnVoidTransaction_click() {
        let currExchange = this.get('currExchange');
        console.log(this.get('currExchange.generation'));
        console.log(this.get('currentData.generation'));

        if (this.get('currExchange.generation') !== this.get('currentData.generation')) {
          alert('Please delete the latest generation first before proceeding to delete this.');
          return;
        }

        let answer = confirm('Are you sure you want to delete this transaction?');

        if (!answer) {
          console.log('phew...');
          return;
        }

        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('exchange/void/' + this.get('currExchange.exchangeID'));
        let headers = commonUtility.giveMeHeader();
        let this_is_this = this;
        let applicationController = this.get('appController');
        applicationController.openDialog('Deleting..');
        $.ajax({
          type: 'DELETE',
          url: url,
          headers: headers
        }).then(function (datasent) {
          makeNewBeginning(this_is_this);
          alert('Transaction voided.');
        }).catch(function (error) {
          console.log(error);
          alert('Transaction not voided.');
        }).then(function () {
          applicationController.closeDialog();
        });
      },

      btnPaymentOkay_click() {
        this.set('openPayment', false);
      },

      btnSaveExchange_click() {
        if (!validateForm(this)) {
          return;
        }

        this.startSaving();
      },

      btnOpenPayment() {
        if (this.get('disabledControl')) {
          return;
        }

        this.set('openPayment', true);
      },

      modal_openPayment_onHide() {
        this.set('openPayment', false);
      },

      btnTest() {
        let appController = this.get('appController');
        appController.openDialog('heheh');
      },

      btnAddExchangeItem_click(event) {
        //console.log('btnAddClick');
        let currentExchangeItem = this.get('currentData.currentExchangeItem');

        if (!currentExchangeItem) {
          alert('There is nothing to add.');
          $('#actrl_description').focus();
          return; // } else if (this.get('currentData.sellingPrice') == 0) {
          //     alert('Price is zero');
          //     $('#actrl_price').focus();
          //     return; Request by Derek to disable price checking
        } else if (this.get('currentData.currentLineTotalQty') <= 0) {
          alert('Please enter some qty.');
          return;
        }

        let productSizeHandler = this.get('productSizeInputHandler');

        if (!productSizeHandler.checkEnoughQty()) {
          alert('Not enough qty to be deduced.');
          return;
        }

        console.log('Additing exchange item');
        console.log(currentExchangeItem);

        let transactionline = _transactionSaleLine.default.create({
          itemCode: currentExchangeItem.itemCode,
          description: currentExchangeItem.itemName,
          qtyDescription: productSizeHandler.getQtyDescription(),
          // make this a computed from arrTextBox
          sellingPrice: currentExchangeItem.SellingPrice,
          totalQty: this.get('currentData.currentLineTotalQty'),
          subtotal: this.get('currentData.subTotal'),
          note: '',
          sizeGroup: currentExchangeItem.SizeGroup,
          arrTextBox: productSizeHandler.get('arrTextBox'),
          data: currentExchangeItem
        });

        console.log('adding return line');
        console.log(transactionline);
        this.arrReturnLine.pushObject(transactionline);
        currentExchangeItem.set('enabled', false);
        this.set('firstSelected', true);
        clearLineItem(this);
      },

      qtyChanged(totalQty) {
        this.set('currentData.currentLineTotalQty', totalQty);
      },

      qtyChangedTransaction(totalQty) {
        this.set('currentTransaction.currentLineTotalQty', totalQty);
      },

      cboItemChange(item) {
        console.log('combo item changed');
        let indexToProcess = item.target.selectedIndex - 1; // first one is blank 

        let productSizeInputHandler = this.get('productSizeInputHandler');
        setLineItem(this, false, productSizeInputHandler, indexToProcess);
        let exchangeItem = this.get('currentData.exchangeItemList').objectAt(indexToProcess);
        this.set('currentData.currentExchangeItem', exchangeItem);
        this.set('firstSelected', false);
      },

      btnInvoiceSearch_click() {
        if (this.get('disabledControl')) {
          return;
        }

        console.log('btnSearch click');
        let saleListController = this.get('saleListController');
        let currentRoute = 'transaction-exchange.index';
        saleListController.sendToMeInstead(this, currentRoute);
        this.transitionToRoute('transaction-sale.sale-list');
      },

      btnDeleteExchangeLine_click(itemToDelete) {
        //console.log('btnDeleteTransctionLine_click');
        let data = itemToDelete.data;
        data.set('enabled', true); // can be reselected again

        this.arrReturnLine.removeObject(itemToDelete); //this.set('firstSelected', true); // move the selection to first

        return new Promise(function (resolve, reject) {
          resolve({
            success: true
          });
        });
      },

      btnDeleteTransctionLine_click(itemToDelete) {
        //console.log('btnDeleteTransctionLine_click');
        let productSize = this.get('entityProductSize');
        let data = itemToDelete.data;
        setLineItemForTransaction(this, data, productSize, true, this.productSizeInputHandlerTransaction, itemToDelete);
        this.arrTransactionLine.removeObject(itemToDelete);
        return new Promise(function (resolve, reject) {
          resolve({
            success: true
          });
        });
      },

      productKeyDown(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('openSearchProduct', true);
          let entitySaleService = this.get('entitySale');
          let keyword = this.get('currentTransaction.productDescription');
          entitySaleService.searchProduct(keyword).then(results => {
            this.set('productSearchResults', results);
          });
        }
      },

      modal_closeSearch_onHide() {
        this.set('openSearchProduct', false);
        console.debug($('#actrl_description').focus());
      },

      itemOnFocus(event) {
        let itemCode = this.get('current.itemCode');
        this.set('oldItemCode', itemCode);
      },

      itemLostFocus(event) {
        let oldItemCode = this.get('oldItemCode');
        let currentItemCode = this.get('current.itemCode');
        let this_is_this = this;

        if (oldItemCode !== currentItemCode) {
          if (currentItemCode.length == 6) {
            let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
            let headers = this.get('commonUtility').giveMeHeader();
            let ajaxResult = $.ajax({
              type: 'GET',
              url: url,
              headers: headers
            }).then(function (datasent) {
              let data = datasent.data;

              if (data) {
                let productSizeHandler = this_is_this.get('productSizeInputHandler');
                let productSize = this_is_this.get('entityProductSize');
                data['sizeGroup'] = data['strProductProductSizeGroup'];
                setLineItem(this_is_this, datasent.data, productSize, false, productSizeHandler);
              } else {
                clearLineItem(this_is_this);
              }
            }).catch(function (error) {
              console.log(error);
              clearLineItem(this);
            });
          } else {
            // clear line item
            clearLineItem(this);
          }
        }
      },

      itemCodeKeyUp(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('current.itemCode', '');
          schedulePromise(word, this); //    let itemCode =  word.substr(0, 6);
          //    let size = word.substr(6);
          //    // check transaction line for item. 
          //    // if not, get the data and add it. 
          //    // ask productsizehandler to generate arrtextbox
          //     let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
          //     let headers = this.get('commonUtility').giveMeHeader();
          //     let ajaxResult = $.ajax({
          //             type : 'GET',
          //             url : url,                                         
          //             headers : headers,                         
          //     }).then(function(data) {
          //             console.log(data);
          //     }).catch(function(error) {
          //             console.log(error);
          //     });              
        }
      },

      searchSelect(source, data) {
        if (source == 'productSearchTable') {
          this.set('openSearchProduct', false);
          let productSize = this.get('entityProductSize'); // bridging for delete when loading from sale

          data.sizeGroup = data['strProductProductSizeGroup'];
          setLineItemForTransaction(this, data, productSize, false, this.productSizeInputHandlerTransaction);
        }
      },

      btnAddTransaction_click(event) {
        //console.log('btnAddClick');
        if (!this.get('currentTransaction.currProduct')) {
          alert('There is no product.');
          $('#actrl_description').focus();
          return; // } else if (this.get('currentTransaction.sellingPrice') == 0) {
          //     alert('Price is zero');
          //     $('#actrl_price').focus();
          //     return;
        } else if (this.get('currentTransaction.currentLineTotalQty') == 0) {
          alert('Please enter some qty.');
          return;
        } else if (this.get('currentTransaction.currentLineTotalQty') < 0) {
          let answer = confirm('You are entering min qty. Continue?');

          if (!answer) {
            return;
          }
        }

        console.log('after btnAddTransaction');
        let productSizeHandler = this.get('productSizeInputHandlerTransaction');

        let transactionline = _transactionSaleLine.default.create({
          itemCode: this.get('currentTransaction.itemCode'),
          description: this.get('currentTransaction.productDescription'),
          qtyDescription: productSizeHandler.getQtyDescription(),
          // make this a computed from arrTextBox
          sellingPrice: this.get('currentTransaction.sellingPrice'),
          totalQty: this.get('currentTransaction.currentLineTotalQty'),
          subtotal: this.get('currentTransaction.subTotal'),
          note: this.get('currentTransaction.note'),
          sizeGroup: this.get('currentTransaction.currProduct')['strProductProductSizeGroup'],
          arrTextBox: productSizeHandler.get('arrTextBox'),
          data: this.get('currentTransaction.currProduct')
        });

        this.arrTransactionLine.pushObject(transactionline);
        clearLineItemForTransaction(this);
      }

    },
    disabledControl: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED' || this.get('status').toUpperCase() == 'EDIT';
    }),
    disabledControlExceptEdit: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED';
    }),
    status: 'NEW',
    arrReturnLine: Ember.A([]),
    arrProductSizeTransaction: null,
    // this is for component inputcontrol
    arrTransactionLine: Ember.A([]),
    productSizeChangedTransaction: null,
    currentData: {
      grandTotal: 0,
      adjustment: 0,
      discountAmount: 0,
      invoiceNo: '',
      exchangeItemList: null,
      sellingPrice: 0,
      subTotal: 0,
      currentLineTotalQty: 0,
      currentExchangeItem: null,
      productDescription: ''
    },
    currentTransaction: {
      itemCode: '',
      salePricing: '',
      invoiceNo: '',
      exchangeItemList: null,
      sellingPrice: 0,
      subTotal: 0,
      currentLineTotalQty: 0,
      currProduct: null,
      productDescription: '',
      note: ''
    },
    exchangeWebTable: {
      columns: [{
        label: 'Description',
        valuePath: 'description',
        esizable: true,
        width: '300px'
      }, {
        label: 'Qty',
        valuePath: 'qtyDescription'
      }, {
        label: 'Total Qty',
        valuePath: 'totalQty'
      }, {
        label: 'Selling Price',
        valuePath: 'sellingPrice'
      }, {
        label: 'Sub Total',
        valuePath: 'subtotal'
      }, {
        label: 'Note',
        valuePath: 'note'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    transactionWebTable: {
      columns: [{
        label: 'Item Code',
        valuePath: 'itemCode'
      }, {
        label: 'Description',
        valuePath: 'description',
        resizable: true,
        width: '300px'
      }, {
        label: 'Qty',
        valuePath: 'qtyDescription'
      }, {
        label: 'Total Qty',
        valuePath: 'totalQty'
      }, {
        label: 'Selling Price',
        valuePath: 'sellingPrice'
      }, {
        label: 'Sub Total',
        valuePath: 'subtotal'
      }, {
        label: 'Note',
        valuePath: 'note'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    productTableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'UniqueID'
      }, {
        label: 'Item Code',
        valuePath: 'strProductItemCode',
        cellComponent: 'web-table-thumbnail'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription'
      }, {
        label: 'Location',
        valuePath: 'strLocationName'
      }, {
        label: 'Size Group',
        valuePath: 'strProductProductSizeGroup'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    firstSelected: true,
    openSearchProduct: false,

    loadExchange(exchangeID) {
      let applicationController = this.get('appController'); //applicationController.openDialog('Loading sales');

      let commonUtility = this.get('commonUtility');
      let url = 'exchange/load';
      let data = {
        exchangeID: exchangeID
      };
      let this_is_this = this;
      commonUtility.HTTPGetSomething(url, data).then(function (data) {
        this_is_this.loadBasicInfo(data);
        this_is_this.loadTransactionLine(data.transaction);
        this_is_this.set('status', 'SAVED');
      }).catch(function (error) {
        console.log('loading error');
        console.log(error);
      }).then(function (data) {
        applicationController.closeDialog();
      });
    },

    loadBasicInfo(data) {
      this.set('currExchange', this.get('store').createRecord('exchange', {
        exchangeDate: new Date(),
        remark: '',
        adjustment: 0
      }));
      this.set('currentData.discountAmount', data.basicSaleInfo.discountAmount);
      this.set('currentData.customerName', data.customerName);
      this.set('currentData.generation', data.generation);
      this.set('currExchange.saleID', data.basicSaleInfo.saleID);
      this.set('currentData.invoiceNo', data.basicSaleInfo.invoiceNo);
      this.set('currExchange.remark', data.basicExchangeInfo.remark);
      this.set('currExchange.adjustment', data.basicExchangeInfo.adjustment);
      this.set('currExchange.exchangeDate', (0, _moment.default)(data.basicExchangeInfo.exchangeDate).toDate());
      this.set('currExchange.exchangeID', data.basicExchangeInfo.exchangeID);
      this.set('currExchange.referenceNo', data.basicExchangeInfo.referenceNo);
      this.set('currExchange.generation', data.basicExchangeInfo.generation);
    },

    loadTransactionLine(transaction) {
      console.log('load Transaction line');
      console.log(transaction);
      let targetPushInto = [this.arrReturnLine, this.arrTransactionLine];
      [transaction.returnLine, transaction.transactionLine].forEach(function (lines, lineIndex) {
        targetPushInto[lineIndex].clear();
        lines.forEach(function (item, index) {
          //item.sellingPrice = item.decExchangelnSellingPrice; // bridging for delete
          // cos when delete product size handler only accepts ember Object
          let theBridgingArrTextBox = Ember.A([]); // item.arrTextBox.forEach(function(item, index) {
          //         theBridgingArrTextBox.addObject(Ember.Object.create({
          //             qty : item.qty, 
          //             sizeID : item.sizeID
          //         }));
          // });

          item.sizeGroup = item.strSalelnProductSizeStockSizeGroup;

          let transactionline = _transactionSaleLine.default.create({
            itemCode: item.strProductItemCode,
            description: item.strProductDescription,
            qtyDescription: item.Qty,
            // make this a computed from arrTextBox
            sellingPrice: item.decExchangelnSellingPrice,
            totalQty: item.totalQty,
            subtotal: numeral(item.totalQty).value() * numeral(item.decExchangelnSellingPrice).value(),
            note: item.strSalelnNote,
            sizeGroup: item.sizeGroup,
            arrTextBox: null,
            data: item
          });

          targetPushInto[lineIndex].pushObject(transactionline);
        });
      });
    }

  });

  _exports.default = _default;
});