define("webcostume/initializers/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Debug.registerDeprecationHandler((message, options, next) => {
      if (options && options.until && options.until !== '3.8.1') {
        return;
      } else {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});