define("webcostume/controllers/login", ["exports", "webcostume/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sessionid'],
    commonUtility: Ember.inject.service('common-utility'),
    webtreepermission: Ember.inject.service('webtreepermission'),
    application: Ember.inject.controller(),
    session: Ember.inject.service('session'),
    sessionid: null,
    permissionList: Ember.A([]),

    checkPermission() {
      return true;
    },

    setupController() {
      alert(this.sessionid);
    },

    init() {
      if (_environment.default.environment == 'development') {
        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('login');
        let data = {
          userID: 'main',
          password: 'LoaNkHKQhTmSsz8P1xfnarA0qOmKTSdE3VIN9cygNrLAs5/9FrgTiLiOfp0tMOvmWd10OzVr5RxYG7Y/g2bfqw=='
        };
        let this_is_this = this;
        $.ajax({
          type: 'POST',
          url: url,
          contentType: 'application/json',
          data: JSON.stringify(data),
          dataType: "json"
        }).then(function (data) {
          //console.log(data);
          this_is_this.get('session').setSessionID(data.sessionID, data.user);
          this_is_this.get('application').set('user', data.user);
          this_is_this.get('application').set('permissionList', data.permissionList);
          this_is_this.get('webtreepermission').set('allowedPermission', data.allowedPermission);
          this_is_this.transitionToRoute('index');
          this_is_this.clearEverything();
        }).catch(function (errorResponse) {
          console.log('error during auto login');
          console.log(errorResponse);
          let hello = errorResponse.responseJSON.errors[0].error;
          alert(' Login error ' + hello);
        });
      }
    },

    actions: {
      btnLogin_click() {
        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('login');

        if (this.userID == '') {
          alert('User ID is blank');
          return;
        }

        if (this.password == '') {
          alert('Password is blank');
          return;
        }

        var shaObj = new jsSHA("SHA-512", "TEXT");
        shaObj.update(this.get('password'));
        var hash = shaObj.getHash("B64");
        let data = {
          userID: this.userID,
          password: hash
        };
        let this_is_this = this;
        $.ajax({
          type: 'POST',
          url: url,
          contentType: 'application/json',
          data: JSON.stringify(data),
          dataType: "json"
        }).then(function (data) {
          console.log(data);
          this_is_this.get('session').setSessionID(data.sessionID, data.user);
          this_is_this.get('application').set('user', data.user);
          this_is_this.get('application').set('permissionList', data.permissionList);
          this_is_this.get('webtreepermission').set('allowedPermission', data.allowedPermission);
          this_is_this.transitionToRoute('index');
          this_is_this.clearEverything();
        }).catch(function (errorResponse) {
          console.log(errorResponse);
          let hello = errorResponse.responseJSON.errors[0].error;
          alert(' Login error ' + hello);
        });
      }

    },

    clearEverything() {
      this.set('password', '');
    },

    userID: 'main',
    password: ''
  });

  _exports.default = _default;
});