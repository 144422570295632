define("webcostume/routes/transaction-exchange/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition() {
        let exchangeController = this.controllerFor('transaction-exchange.index');
        exchangeController.myInit();
        console.log('didTransition happen');

        if (exchangeController.get('saleID') != '') {
          // clear data first
          exchangeController.makeNewBeginning();
          exchangeController.loadDataFromSaleList();
        }
      },

      willTransition(transition) {
        let exchangeController = this.controllerFor('transaction-exchange.index');

        if (exchangeController.isDirty()) {
          let answer = confirm('Are you sure you want to close? All progress will be lost.');

          if (answer) {
            return true;
          } else {
            transition.abort();
          }
        }
      }

    }
  });

  _exports.default = _default;
});