define("webcostume/templates/components/web-table-linkto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RLL86LnP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[[23,[\"column\",\"linkTo\"]],[23,[\"rawValue\"]]],null,{\"statements\":[[1,[23,[\"column\",\"linkDescription\"]],false]],\"parameters\":[]},null],[0,\" \\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-linkto.hbs"
    }
  });

  _exports.default = _default;
});