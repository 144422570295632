define("webcostume/components/custom-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    actionWhenEnter: null,

    didInsertElement() {
      this.$().keypress(event => {
        if (event.keyCode == 13) {
          if (this.get('actionWhenEnter')) {
            this.get('actionWhenEnter')();
          }

          event.preventDefault();
        }
      });
    }

  });

  _exports.default = _default;
});