define("webcostume/services/common-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    commonUtility: Ember.inject.service(),

    getStockInNo() {
      let host = this.get('commonUtility').get('host');
      let url = host + "/commonutility/getstockinno";
      let headers = this.get('commonUtility').giveMeHeader();
      return $.ajax({
        dataType: "json",
        url: url,
        headers: headers
      }).then(function (data) {
        return data.stockinno;
      }).catch(function () {
        return '';
      });
    }

  });

  _exports.default = _default;
});