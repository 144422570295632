define("webcostume/components/web-table-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    commonUtility: Ember.inject.service('common-utility'),
    imageUrl: Ember.computed(function () {
      let commonUtility = this.get('commonUtility');
      return commonUtility.giveMeFullUrl('product/getimagethumbnail');
    }),
    sessionID: Ember.computed(function () {
      let session = this.get('session');
      return session.giveMeSessionID();
    }),
    randomizeValue: Ember.computed(function () {
      return new Date().valueOf();
    })
  });

  _exports.default = _default;
});