define("webcostume/routes/setup-productcategory/list-productcategory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let listProductCategoryContoller = this.controllerFor('setup-productcategory.list-productcategory');
      listProductCategoryContoller.refreshproductCategoryList();
    }

  });

  _exports.default = _default;
});