define("webcostume/controllers/setup-bank/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {},

    appController: Ember.inject.controller('application'),
    actions: {
      findRecords() {//    this.send('refreshData');
      },

      fetchingRecords: false,

      deleteThisRecord(record) {
        let appController = this.get('appController');
        let bankRecord = this.store.peekRecord('bank', record.get('id'));
        let answer = confirm('Are you sure you want to delete this record?');
        if (!answer) return;
        let meparent = this;
        appController.openDialog('deleting record...');

        if (bankRecord) {
          bankRecord.destroyRecord().then(results => {
            meparent.send('refreshData');
            alert('Record deleted succesfully');
            appController.closeDialog();
          }).catch(reason => {
            bankRecord.rollbackAttributes();
            alert('Delete failed.' + reason.errors[0].reason);
            appController.closeDialog();
          });
        } else {
          alert('Cannot find bank record');
        }
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Bank Code',
        valuePath: 'bankCode'
      }, {
        label: 'Bank Name',
        valuePath: 'bankName'
      }, {
        label: 'Edit',
        valuePath: 'bankCode',
        linkTo: 'setup-bank.setup-bank-input',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Edit'
      }, {
        label: 'Delete',
        valuePath: 'bankCode',
        cellComponent: 'web-table-scroll-button',
        linkDescription: 'Delete'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});