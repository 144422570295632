define("webcostume/controllers/application", ["exports", "webcostume/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    openProcessing: false,
    user: {
      userFullName: 'Guest'
    },
    message: 'Loading...',
    header: Ember.computed(function () {
      return ''.concat(_environment.default.environment, '(', _environment.default.APP.host, ')');
    }),
    environment: Ember.computed(function () {
      return _environment.default.environment;
    }),

    isFromRoute(routeName) {
      return routeName == this.get('fromRoute');
    },

    setRouteName(routeName) {
      this.set('fromRoute', routeName);
    },

    fromRoute: '',
    // when doing transition you can set this so that the destination route knows where you're from 
    isLogin: Ember.computed('session.sessionID', function () {
      return this.get('session').isLogin();
    }),
    actions: {
      openLinkAgain() {
        window.open('/login?sessionid=' + this.session.giveMeSessionID(), '_blank');
      },

      openDialog() {
        this.set('openProcessing', true);
      },

      closeDialog() {
        this.set('openProcessing', false);
      },

      logout() {
        this.get('session').logout();
        this.set('user', {
          userFullName: 'Guest'
        });
        this.transitionToRoute('login');
      }

    },

    openDialog(message = 'Loading...') {
      this.set('message', message);
      this.set('openProcessing', true);
    },

    closeDialog() {
      this.set('openProcessing', false);
    }

  });

  _exports.default = _default;
});