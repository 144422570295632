define("webcostume/models/customerinfo", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    customerInfoID: (0, _attr.default)('number'),
    customerID: (0, _attr.default)('string'),
    contactPerson: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    ICNo: (0, _attr.default)('string'),
    contactNumber: (0, _attr.default)('string'),

    friendlyName() {
      return this.get('contactPerson');
    }

  });

  _exports.default = _default;
});