define("webcostume/components/com-setup-customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabledIfNotEmpty: Ember.computed('model', function () {
      return this.get('model').get('bankCode') != null;
    }),
    open: false,
    // this one is to set from parent to control open close of save
    openConfirm: false,
    cancelAction: null,
    // when cancel button is clicked in parent        
    closeAction: null,
    // this one is to set from parent  when save succesffully was hit
    headerMessage: {
      // error message
      message: 'Yahoo',
      showMessage: false,
      type: 'success'
    },
    submitAction: null,
    model: null,
    // this one is to set from parent
    actions: {}
  });

  _exports.default = _default;
});