define("webcostume/templates/components/web-table-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CzVKMDR0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"row\",\"isEditting\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[9],[0,\"save\"],[3,\"action\",[[22,0,[]],\"btnSave_click\"]],[10],[0,\"    \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\"],[9],[0,\"edit\"],[3,\"action\",[[22,0,[]],\"btnEdit_click\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-edit.hbs"
    }
  });

  _exports.default = _default;
});