define("webcostume/controllers/transaction-sale/payment", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getTotalAmount(paymentLines) {
    let totalAmountPaid = 0;
    paymentLines.forEach(function (item, index) {
      totalAmountPaid += numeral(item.amount).value();
    });
    return totalAmountPaid;
  }

  function clearEverything(paymentController) {
    paymentController.set('customerName', 'Abeng');
    paymentController.set('paymentAmount', 100);
    paymentController.set('amount', 0);
    paymentController.set('paymentLines', Ember.A([]));
    paymentController.set('currentSaleID', -1);
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),

    initialPayment(customerName, paymentAmount, paymentType, saleType, saleDate, isEdit = false) {
      this.set('customerName', customerName);
      this.set('paymentAmount', paymentAmount.toFixed(2));
      this.set('amount', this.get('remainingAmount'));
      this.set('allowedTransition', true);
      this.set('currPaymentType', paymentType);
      this.set('saleType', saleType);
      this.set('currSaleDate', saleDate);
      this.set('paymentLocked', false);
      this.set('isEdit', isEdit);
    },

    isEdit: false,
    currSaleDate: '',
    saleType: '',
    currPaymentType: 'NETS',
    allowedTransition: false,
    customerName: 'Abeng',
    paymentAmount: 100,
    amount: 0,
    paymentSelectionHandler: null,
    paymentLines: Ember.A([]),
    paymentTableData: {
      columns: [{
        label: 'Payment Date',
        valuePath: 'paymentDate'
      }, {
        label: 'Payment Type',
        valuePath: 'paymentType'
      }, {
        label: 'Amount',
        valuePath: 'amount'
      }, {
        label: 'Detail',
        valuePath: 'detail'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },

    onPaymentOkay_function() {
      let paymentHandler = this.get('paymentSelectionHandler');
      let totalAmountPaid = getTotalAmount(this.paymentLines);
      let detail = paymentHandler.getDetail(); //console.log('detail');
      //console.log(detail);

      let nextAmount = numeral(detail.amount).value();

      if (this.paymentAmount < totalAmountPaid + nextAmount) {
        if (detail.paymentType === 'Cash') {
          detail.detail = 'Change : ' + numeral(numeral(detail.amount).value() - this.get('remainingAmount')).value();
          detail.amount = this.get('remainingAmount');
        } else {
          alert('The amount has exceeded the total amount.');
          return;
        }
      }

      this.paymentLines.pushObject(detail);
      paymentHandler.clearData();
    },

    btnDone_click_function() {
      //console.log('btnDone_click');
      if (this.get('paymentLines').length == 0) {
        alert('No payment is made. Unable to continue.');
        return;
      } else if (this.get('remainingAmount') != 0) {
        if (this.saleType.toUpperCase() == 'POS') {
          alert('Remaining amount is not zero. This is not allowed in POS sales. To enable partial payment. Please switch the sale type to invoice.');
          return;
        }

        let answer = confirm('Remaining amount is not zero. This will be treated as partial payment. Continue?');

        if (!answer) {
          return;
        }
      }

      this.set('paymentDone', true);
      this.transitionToRoute('transaction-sale.index');
    },

    actions: {
      deleteRecord(record) {
        //console.log('Delete record from payment list');
        this.get('paymentLines').removeObject(record);
        return new Promise(function (resolve, reject) {
          resolve();
        });
      },

      onPaymentOkay() {
        this.onPaymentOkay_function();
      },

      onPaymentOkayAndDone() {
        this.onPaymentOkay_function();
        this.btnDone_click_function();
      },

      btnDone_click() {
        this.btnDone_click_function();
      },

      btnCancel_click() {
        //console.log('btnCancel_click');
        if (!this.paymentLocked) {
          // payment locked means only avail for viewing, no cleaning is needed.
          let answer = confirm('Cancel payment?');

          if (!answer) {
            return;
          }

          if (!this.isEdit) {
            // don't clear while the guys is editing
            clearEverything(this);
          } else {
            // reload payment 
            let commonUtility = this.get('commonUtility');
            let saleID = this.get('currentSaleID');
            let data = {
              saleID: saleID
            };
            let this_is_this = this;
            commonUtility.HTTPGetSomething('sale/loadpayment', data).then(function (data) {
              console.log('loadpayment');
              console.log(data);
              this_is_this.loadPaymentLine(data.paymentLines, saleID);
            });
          }
        }

        this.transitionToRoute('transaction-sale.index');
      }

    },
    remainingAmount: Ember.computed('paymentAmount', 'paymentLines.[]', function () {
      let totalAmountPaid = getTotalAmount(this.paymentLines);
      return (this.paymentAmount - totalAmountPaid).toFixed(2); // to solve funny rounding error
    }),
    paymentDone: false,

    clearEverything() {
      clearEverything(this);
    },

    getPayment() {
      let paymentList = [];
      this.get('paymentLines').forEach(function (item, index) {
        let detail = {
          paymentType: item.paymentType,
          amount: item.amount,
          paymentDate: item.paymentDate,
          paymentNo: item.paymentNo
        };

        if (item.paymentType.toUpperCase() == 'CHEQUE') {
          detail['cheque'] = {
            chequeDate: item.cheque.chequeDate,
            chequeNo: item.cheque.chequeNo,
            bankSelection: item.cheque.bankSelection
          };
        }

        paymentList.pushObject(detail);
      });
      return paymentList;
    },

    lockPayment(value = true) {
      this.set('paymentLocked', value);
      this.set('allowedTransition', value);
    },

    paymentLocked: false,
    isPOS: Ember.computed('saleType', function () {
      //console.log(this.saleType);
      return this.get('saleType').toUpperCase() == 'POS';
    }),

    setPaymentNo(paymentList) {
      //console.log(paymentList);
      let i = 0;
      this.get('paymentLines').forEach(function (item, index) {
        if (item.paymentNo == '') {
          item.paymentNo = paymentList[i];
          i += 1;
        }
      });
    },

    currentSaleID: -1,

    loadPaymentLine(paymentLines, saleID) {
      this.set('currentSaleID', saleID);
      let paymentLine = this.paymentLines;
      paymentLine.clear();
      paymentLines.forEach(function (item, index) {
        let payment = {
          paymentDate: item.dtePaymentDate,
          paymentType: item.strPaymentMethod,
          amount: item.decPaymentlnAmount,
          detail: '',
          cheque: '',
          paymentNo: item.strPaymentNo
        };

        if (item['cheque']) {
          let cheque = item.cheque;
          payment.detail = cheque.bankCode + '(' + cheque.chequeNo + ') - ' + (0, _moment.default)(cheque.chequeDate).format('DD/MM/YYYY');
        }

        paymentLine.pushObject(payment);
      });
    }

  });

  _exports.default = _default;
});