define("webcostume/controllers/setup-bank/setup-bank-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    open: false,
    appController: Ember.inject.controller('application'),
    openConfirm: false,
    actions: {
      transitionBack() {
        this.transitionToRoute('setup-bank');
      },

      cancelAction() {
        let answer = confirm('Are you sure you want to cancel?');

        if (answer) {
          this.get('model').rollbackAttributes();
          this.send('transitionBack');
        }
      },

      submit() {
        let appController = this.get('appController');
        appController.openDialog('Saving...');
        var baba = this;
        this.get('model').save().then(function (event) {
          alert('Save succesfully.');
          appController.closeDialog();
          baba.send('transitionBack');
        }).catch(function (event) {
          alert('Save failed.');
          appController.closeDialog();
          console.log(event);
        });
      }

    }
  });

  _exports.default = _default;
});