define("webcostume/routes/admin-stockin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition() {
        let mycontroller = this.controllerFor('admin-stockin.index');
        mycontroller.myInit();
      },

      willTransition(transition) {
        let adminStockin = this.controllerFor('admin-stockin.index');

        if (adminStockin.isDirty()) {
          let answer = confirm('Are you sure you want to close? All progress will be lost.');

          if (answer) {
            return true;
          } else {
            transition.abort();
          }
        }
      }

    }
  });

  _exports.default = _default;
});