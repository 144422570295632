define("webcostume/apai/entity/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customerfieldName = void 0;
  let customerfieldName = {
    customerID: 'strCustomerId',
    name: 'strCustomerName',
    address: 'strCustomerAddress',
    contact: 'strCustomerContact',
    isBlacklisted: 'blnBlacklisted',
    remark: 'strCustomerRemark',
    fax: 'strCustomerFax',
    isCustomer: 'blnCustomerIsCustomer',
    isSupplier: 'blnCustomerIsSupplier',
    contactPerson: 'strCustomerContactPerson'
  };
  _exports.customerfieldName = customerfieldName;
});