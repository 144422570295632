define("webcostume/adapters/application", ["exports", "webcostume/config/environment", "ember-data/adapters/rest"], function (_exports, _environment, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.APP.host,
    headers: Ember.computed('session.sessionID', function () {
      //console.log(this.get('session.sessionID'));
      return {
        sessionid: this.get('session.sessionID')
      };
    })
  });

  _exports.default = _default;
});