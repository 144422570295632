define("webcostume/controllers/transaction-reserve/reserve-index", ["exports", "webcostume/apai/gridline/transaction-sale-line", "moment"], function (_exports, _transactionSaleLine, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function setCustomerTextBox(this_is_this, data) {
    this_is_this.set('currReserve.customerID', data.get('customerID'));
    this_is_this.set('currentData.customerName', data.get('name'));
  } // function getTotal(arrTransactionLine) {
  //     let total = 0;
  //     arrTransactionLine.forEach(function (item, index) {
  //         total += numeral(item.subtotal).value();
  //     });
  //     return total;
  // }


  function makeNewBeginning(this_is_this) {
    let arrTransactionLine = this_is_this.get('arrTransactionLine');
    arrTransactionLine.clear();
    this_is_this.set('currReserve', this_is_this.get('store').createRecord('reserve', {
      reserveDate: new Date(),
      customerID: '',
      remark: ''
    }));
    let currReserve = this_is_this.get('currReserve');
    this_is_this.get('entityReserve').getReserveNo().then(function (data) {
      currReserve.set('referenceNo', data);
    }); //clearLineItem(this_is_this);

    clearLineItemForTransaction(this_is_this);
    this_is_this.set('currentData.customerName', '');
    this_is_this.set('currReserve.customerID', '');
    this_is_this.set('status', 'NEW');
  }

  function setLineItemForTransaction(this_is_madness, data, productSize, existing, productSizeInputHandler, transactionLine) {
    let myPromise = productSize.getSizes(data['sizeGroup']);
    let outsideData = data;
    myPromise.then(data => {
      this_is_madness.set('arrProductSizeTransaction', data);

      if (!existing) {
        this_is_madness.set('productSizeChangedTransaction', new Date()); // here to reset arrTextBox 
      } else {
        // no reset needed cos we are setting it.
        productSizeInputHandler.set('arrTextBox', transactionLine.arrTextBox);
        this_is_madness.set('currentTransaction.currentLineTotalQty', productSizeInputHandler.getTotalQty());
      } // call function here to load product size 


      productSizeInputHandler.loadRemainingQty(outsideData['strProductItemCode']);
    });
    this_is_madness.set('currentTransaction.currProduct', data);
    this_is_madness.set('currentTransaction.itemCode', data['strProductItemCode']);
    this_is_madness.set('currentTransaction.productDescription', data['strProductDescription']);

    if (!existing) {
      this_is_madness.set('currentTransaction.currentLineTotalQty', 0); // follow the invoice pricing 

      if (this_is_madness.get('currentTransaction.salePricing').toUpperCase() == 'RETAIL') {
        this_is_madness.set('currentTransaction.sellingPrice', data['dclProductSellingPrice']);
      } else {
        this_is_madness.set('currentTransaction.sellingPrice', data['decProductWholesalePrice']);
      }
    } else {
      this_is_madness.set('currentTransaction.note', transactionLine.note);
      this_is_madness.set('currentTransaction.sellingPrice', transactionLine.sellingPrice);
    }
  } // function clearLineItem(this_is_madness) {
  //         let productsizeHandler = this_is_madness.get('productSizeInputHandler')    ;
  //         productsizeHandler.clearAll();
  //         this_is_madness.set('currentData.currReserveItem', null);
  //         this_is_madness.set('currentData.sellingPrice', 0);
  //         this_is_madness.set('currentData.currentLineTotalQty', 0);
  //         this_is_madness.set('currentData.note', '');
  //     }


  function clearLineItemForTransaction(this_is_madness) {
    this_is_madness.set('arrProductSizeTransaction', []);
    this_is_madness.set('productSizeChangedTransaction', new Date());
    this_is_madness.set('currentTransaction.currProduct', null);
    this_is_madness.set('currentTransaction.itemCode', '');
    this_is_madness.set('currentTransaction.productDescription', '');
    this_is_madness.set('currentTransaction.currentLineTotalQty', 0);
    this_is_madness.set('currentTransaction.note', '');
  } // function searchArrProductQty(this_is_this, index) {
  //     let exchangeItem = this_is_this.get('currentData.exchangeItemList').objectAt(index);
  //     let exchangeItemProductStockList = this_is_this.get('currentData.exchangeItemProductStockList');
  //     let salelnGroup = exchangeItem['intGroup'];
  //     let productID =exchangeItem['ProductID'];
  //     let sellingPrice = numeral(exchangeItem['SellingPrice']).format('0.0000'); // same as the server formatting
  //     let arrProductQtyFound = exchangeItemProductStockList[salelnGroup][productID][sellingPrice];
  //     // format it so that product size input can use directly
  //     let arrProductQtyForPSI = [];
  //     arrProductQtyFound.forEach((item) => {
  //         arrProductQtyForPSI.pushObject(Ember.Object.create({            
  //             qty : item.TotalQty, 
  //             sizeID: item.strSalelnProductSizeStockSizeID
  //         }));
  //     });
  //     return arrProductQtyForPSI;
  // }
  // data must contain {sizeGroup, }
  // function setLineItem(this_is_madness,  existing, productSizeInputHandler, indexOfComboBox) {
  //     let exchangeItem = this_is_madness.get('currentData.exchangeItemList').objectAt(indexOfComboBox);
  //     let sizeGroup = exchangeItem['SizeGroup'];
  //     // searchArrProductQty  contains {sizeID, qty }
  //     let arrProductQty = searchArrProductQty(this_is_madness, indexOfComboBox);
  //     console.log('preparing to load remaining qty off line');
  //     console.log(productSizeInputHandler);
  //     productSizeInputHandler.loadRemainingQtyOffline(sizeGroup,arrProductQty);
  //     if (!existing) {
  //         this_is_madness.set('currentData.currentLineTotalQty', 0);                    
  //         this_is_madness.set('currentData.sellingPrice', exchangeItem.SellingPrice);
  //     } else {
  //         this_is_madness.set('currSaleln.note', transactionLine.note);
  //         this_is_madness.set('currSaleln.sellingPrice', transactionLine.sellingPrice);
  //     }
  //     console.log('end set line item ***');
  // }


  let ExchangeItem = Ember.Object.extend({}); // function manageExchangeData(this_is_this, data) {
  //     let exchangeListDownloaded = data.exchangeList
  //     let listOfExchangeItem = [];
  //     exchangeListDownloaded.forEach(function(item) {
  //         console.log(item);
  //         let exchangeItem = ExchangeItem.create({
  //             itemCode : item.itemCode,
  //             itemName : item.strProductDescription, 
  //             intGroup : item.intGroup,
  //             ProductID : item.ProductID,
  //             SellingPrice : item.SellingPrice,
  //             SizeGroup : item.strSalelnProductSizeStockSizeGroup,
  //             enabled : true
  //         });
  //         listOfExchangeItem.push(exchangeItem);
  //     });
  //     this_is_this.set('currentData.exchangeItemList', listOfExchangeItem)
  //     this_is_this.set('currentData.exchangeItemProductStockList', data.productStock);
  //     this_is_this.set('currentTransaction.salePricing', data.basicSaleInfo.pricing);
  //     this_is_this.set('currentData.discountAmount', data.basicSaleInfo.discountAmount);
  //     this_is_this.set('currentData.customerName', data.customerName);
  //     this_is_this.set('currentData.generation', data.generation);
  //     // this_is_this.set('currReserve.saleID', data.basicSaleInfo.saleID);
  // }

  let ExchangeItemManager = Ember.Object.extend({
    addToCombo() {},

    AddItemComboBox() {},

    RemoveItemComboBox() {}

  });

  function validateForm(this_is_this) {
    console.log('validating form');
    console.log(this_is_this.get('currReserve'));

    if (this_is_this.get('currReserve.customerID') === '') {
      alert('There is no customer');
      $('#actr_customerName').focus();
      return false;
    } else if (!this_is_this.arrTransactionLine || this_is_this.arrTransactionLine.length == 0) {
      alert('There is no reservation line');
      return false;
    }

    return true;
  }

  var _default = Ember.Controller.extend({
    paymentSelectionHandler: null,
    objExchangeItemManager: ExchangeItemManager.create({}),
    // arrTransactionLineChanged: Ember.observer('arrTransactionLine.@each.subtotal', function () {
    //     let total = getTotal(this.arrTransactionLine);
    //     this.set('currentData.exchangeGetTotal', numeral(total).format('0,0.00'));
    //     // let servicePHSetting = this.get('servicePHSetting');
    //     // let GST =total * parseFloat(servicePHSetting.getKey('GST'));
    //     // this.set('current.grandGST', GST);
    //     // this.set('current.grandTotal', total + GST);
    // }),
    // grandTotalObserver : Ember.observer('currentData.exchangeReturnTotal', 'currentData.exchangeGetTotal', 'currReserve.adjustment', 
    //      function() {
    //          let total = -numeral(this.get('currentData.exchangeReturnTotal')).value()  +
    //              numeral(this.get('currentData.exchangeGetTotal')).value() - numeral(this.get('currReserve.adjustment')).value();
    //          this.set('currentData.grandTotal',  total);
    //      }
    //  ), 
    // onTotalQtyPricechanged: Ember.observer('currentData.currentLineTotalQty', 'currentData.sellingPrice', function () {
    //             let subtotal = numeral(this.get('currentData.currentLineTotalQty')).value() * numeral(this.get('currentData.sellingPrice')).value();
    //             this.set('currentData.subTotal', numeral(subtotal).format('0,0.00'));
    // }),
    // onTotalQtyPriceTransactionchanged: Ember.observer('currentTransaction.currentLineTotalQty', 'currentTransaction.sellingPrice', function () {
    //             let subtotal = numeral(this.get('currentTransaction.currentLineTotalQty')).value() * numeral(this.get('currentTransaction.sellingPrice')).value();
    //             this.set('currentTransaction.subTotal', numeral(subtotal).format('0,0.00'));
    // }),
    entitySale: Ember.inject.service('entity-sale'),
    entityReserve: Ember.inject.service('entity-reserve'),
    entityProductSize: Ember.inject.service('entity-productsize'),
    saleController: Ember.inject.controller('transaction-sale.index'),
    appController: Ember.inject.controller('application'),
    commonUtility: Ember.inject.service(),
    productSizeInputHandler: 'a',
    // handler for size control
    productSizeInputHandlerTransaction: '',
    saleID: '',
    // this variable is to communicate with sale list, when it sets, it will be checked during didTransition
    myInitDone: false,
    currReserve: null,
    computed_arrTransactionLineLength: Ember.computed('arrTransactionLine.@each', function () {
      console.log('computer line ');
      console.log(this.get('arrTransactionLine'));
      return this.get('arrTransactionLine').length;
    }),

    myInit() {
      if (!this.get('myInitDone')) {
        this.set('currReserve', this.get('store').createRecord('reserve', {
          reserveDate: new Date(),
          customerID: '',
          remark: ''
        }));
        let currReserve = this.get('currReserve');
        this.get('entityReserve').getReserveNo().then(function (data) {
          currReserve.set('referenceNo', data);
        }); // let productSize = this.get('entityProductSize');

        let service = this.get('servicePHSetting');
        this.set('myInitDone', true);
      }
    },

    /**
     * This is needed because when you transition out and back in, the component is destroyed. 
     * so you need to invoke this from didTransition
     */
    // loadDataFromSaleList() {
    //     let saleID = this.get('saleID');
    //     this.set('saleID', '');
    //     if (!saleID || saleID == '') 
    //         return;
    //     let commonUtility = this.get('commonUtility');
    //     let this_is_this = this;        
    //     commonUtility.HTTPGetSomething('getexchangeitemsbysaleid', {saleID : saleID}).then((data) => {                    
    //         manageExchangeData(this_is_this, data);
    //         console.log(this_is_this.get('currentData.exchangeItemList'));
    //     });
    // },
    getTransaction() {
      let transactLine = [];
      this.arrTransactionLine.forEach(function (item, value) {
        let qtyList = [];
        item.arrTextBox.forEach(function (itemTextBox, indexTextBox) {
          if (itemTextBox.qty > 0) {
            qtyList.pushObject({
              sizeID: itemTextBox.sizeID,
              qty: itemTextBox.qty
            });
          }
        });
        let currObject = {
          itemCode: item.itemCode,
          note: item.note,
          qtyList: qtyList
        };
        transactLine.pushObject(currObject);
      });
      return transactLine;
    },

    startSaving() {
      let appController = this.get('appController');
      appController.openDialog('Saving...');
      let reserveController = this;
      let originalStatus = this.get('status');
      let commonUtility = this.get('commonUtility');
      let url = 'reserve/save';
      let headers = commonUtility.giveMeHeader();
      let myPromiseToSaveYou = new Promise(function (resolve, reject) {
        let data = {
          transaction: reserveController.getTransaction(),
          reserve: JSON.parse(JSON.stringify(reserveController.get('currReserve')))
        };
        console.log(data);
        commonUtility.HTTPPostSomething(url, data).then(function (data) {
          console.log('save');
          reserveController.set('currReserve.referenceNo', data.referenceNo);
          reserveController.set('currReserve.reserveID', data.reserveID);
          resolve('done');
        }).catch(function () {
          resolve('error');
        });
      });
      myPromiseToSaveYou.then(function (data) {
        if (data == 'error') {
          alert('Save failed.');
        } else {
          reserveController.set('status', 'SAVED');
        }

        appController.closeDialog();
      });
    },

    openSearch: false,
    expiredAlready: Ember.computed.equal('currReserve.expired', 1),
    actions: {
      btnConvertToSale_click() {
        if (!this.get('expiredAlready')) {
          alert('Please mark this as expired before converting to sale.');
          return;
        }

        let saleController = this.get('saleController');
        saleController.loadReserve(this.get('currReserve.reserveID'));
        this.transitionToRoute('transaction-sale.index');
      },

      btnMarkExpired_click() {
        let answer = confirm('Are you sure you want to mark as expired?');

        if (!answer) {
          return;
        }

        let appController = this.get('appController');
        appController.openDialog('Mark as expiring...');
        let commonUtility = this.get('commonUtility');
        let url = 'reserve/markasexpired';
        let data = {
          reserveID: this.get('currReserve.reserveID')
        };
        let this_is_this = this;
        commonUtility.HTTPPostSomething(url, data).then(function (data) {
          alert('Marking successfully.');
          this_is_this.set('currReserve.expired', 1);
          appController.closeDialog();
        }).catch(function (error) {
          alert('Marking failed.');
          appController.closeDialog();
        });
      },

      customerKeyDown() {
        this.set('openSearch', true);
        let requestString = {
          search: this.get('currentData.customerName')
        };
        console.log(requestString);
        let dataPromise = this.store.query('customer', requestString).then(results => {
          this.set('searchResults', results);
        });
      },

      modal_closeSearchCustomer_onHide() {
        this.set('openSearch', false);
      },

      btnNew_click() {
        if (this.get('status') === 'NEW') {
          let answer = confirm('Are you sure you want to start new exchange?');

          if (!answer) {
            return;
          }
        }

        makeNewBeginning(this);
      },

      btnVoidTransaction_click() {
        let currReserve = this.get('currReserve');
        let answer = confirm('Are you sure you want to delete this transaction?');

        if (!answer) {
          console.log('phew...');
          return;
        }

        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('reserve/void/' + this.get('currReserve.reserveID'));
        let headers = commonUtility.giveMeHeader();
        let this_is_this = this;
        let applicationController = this.get('appController');
        applicationController.openDialog('Deleting..');
        $.ajax({
          type: 'DELETE',
          url: url,
          headers: headers
        }).then(function (datasent) {
          makeNewBeginning(this_is_this);
          alert('Transaction voided.');
        }).catch(function (error) {
          console.log(error);
          alert('Transaction not voided.');
        }).then(function () {
          applicationController.closeDialog();
        });
      },

      btnPaymentOkay_click() {
        this.set('openPayment', false);
      },

      btnSaveReservation_click() {
        if (!validateForm(this)) {
          return;
        }

        this.startSaving();
      },

      btnOpenPayment() {
        if (this.get('disabledControl')) {
          return;
        }

        this.set('openPayment', true);
      },

      btnTest() {
        let appController = this.get('appController');
        appController.openDialog('heheh');
      },

      qtyChanged(totalQty) {
        this.set('currentData.currentLineTotalQty', totalQty);
      },

      qtyChangedTransaction(totalQty) {
        this.set('currentTransaction.currentLineTotalQty', totalQty);
      },

      btnDeleteTransctionLine_click(itemToDelete) {
        //console.log('btnDeleteTransctionLine_click');
        let productSize = this.get('entityProductSize');
        let data = itemToDelete.data;
        setLineItemForTransaction(this, data, productSize, true, this.productSizeInputHandlerTransaction, itemToDelete);
        this.arrTransactionLine.removeObject(itemToDelete);
        return new Promise(function (resolve, reject) {
          resolve({
            success: true
          });
        });
      },

      productKeyDown(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('productSearchResults', Ember.A([]));
          this.set('openSearchProduct', true);
          let entitySaleService = this.get('entitySale');
          let keyword = this.get('currentTransaction.productDescription');
          entitySaleService.searchProduct(keyword).then(results => {
            this.set('productSearchResults', results);
          });
        }
      },

      modal_closeSearch_onHide() {
        this.set('openSearchProduct', false);
        $('#actrl_description').focus();
      },

      itemOnFocus(event) {
        let itemCode = this.get('current.itemCode');
        this.set('oldItemCode', itemCode);
      },

      itemLostFocus(event) {
        console.log('item lost focus');
        let oldItemCode = this.get('oldItemCode');
        let currentItemCode = this.get('current.itemCode');
        let this_is_this = this;

        if (oldItemCode !== currentItemCode) {
          if (currentItemCode.length == 6) {
            let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
            let headers = this.get('commonUtility').giveMeHeader();
            let ajaxResult = $.ajax({
              type: 'GET',
              url: url,
              headers: headers
            }).then(function (datasent) {
              let data = datasent.data;

              if (data) {
                let productSizeHandler = this_is_this.get('productSizeInputHandler');
                let productSize = this_is_this.get('entityProductSize');
                data['sizeGroup'] = data['strProductProductSizeGroup'];
                setLineItem(this_is_this, datasent.data, productSize, false, productSizeHandler);
              } else {
                clearLineItem(this_is_this);
              }
            }).catch(function (error) {
              console.log(error);
              clearLineItem(this);
            });
          } else {
            // clear line item
            clearLineItem(this);
          }
        }
      },

      itemCodeKeyUp(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('current.itemCode', '');
          schedulePromise(word, this); //    let itemCode =  word.substr(0, 6);
          //    let size = word.substr(6);
          //    // check transaction line for item. 
          //    // if not, get the data and add it. 
          //    // ask productsizehandler to generate arrtextbox
          //     let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
          //     let headers = this.get('commonUtility').giveMeHeader();
          //     let ajaxResult = $.ajax({
          //             type : 'GET',
          //             url : url,                                         
          //             headers : headers,                         
          //     }).then(function(data) {
          //             console.log(data);
          //     }).catch(function(error) {
          //             console.log(error);
          //     });              
        }
      },

      searchSelect(source, data) {
        if (source == 'product') {
          this.set('openSearchProduct', false);
          let productSize = this.get('entityProductSize'); // bridging for delete when loading from sale

          data.sizeGroup = data['strProductProductSizeGroup'];
          setLineItemForTransaction(this, data, productSize, false, this.productSizeInputHandlerTransaction);
        } else {
          this.set('openSearch', false);
          setCustomerTextBox(this, data);
        }
      },

      btnAddTransaction_click(event) {
        //console.log('btnAddClick');
        if (!this.get('currentTransaction.currProduct')) {
          alert('There is no product.');
          $('#actrl_description').focus();
          return;
        } else if (this.get('currentTransaction.currentLineTotalQty') == 0) {
          alert('Please enter some qty.');
          return;
        } else if (this.get('currentTransaction.currentLineTotalQty') < 0) {
          let answer = confirm('You are entering min qty. Continue?');

          if (!answer) {
            return;
          }
        }

        console.log('after btnAddTransaction');
        let productSizeHandler = this.get('productSizeInputHandlerTransaction');

        let transactionline = _transactionSaleLine.default.create({
          itemCode: this.get('currentTransaction.itemCode'),
          description: this.get('currentTransaction.productDescription'),
          qtyDescription: productSizeHandler.getQtyDescription(),
          // make this a computed from arrTextBox                
          totalQty: this.get('currentTransaction.currentLineTotalQty'),
          note: this.get('currentTransaction.note'),
          sizeGroup: this.get('currentTransaction.currProduct')['strProductProductSizeGroup'],
          arrTextBox: productSizeHandler.get('arrTextBox'),
          data: this.get('currentTransaction.currProduct')
        });

        this.arrTransactionLine.pushObject(transactionline);
        clearLineItemForTransaction(this);
      }

    },
    disabledControl: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED' || this.get('status').toUpperCase() == 'EDIT';
    }),
    disabledControlExceptEdit: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED';
    }),
    status: 'NEW',
    arrReturnLine: Ember.A([]),
    arrProductSizeTransaction: null,
    // this is for component inputcontrol
    arrTransactionLine: Ember.A([]),
    productSizeChangedTransaction: null,
    currentData: {
      exchangeItemList: null,
      currentLineTotalQty: 0,
      currentExchangeItem: null,
      productDescription: ''
    },
    currentTransaction: {
      itemCode: '',
      salePricing: '',
      invoiceNo: '',
      exchangeItemList: null,
      currentLineTotalQty: 0,
      currProduct: null,
      productDescription: '',
      note: ''
    },
    transactionWebTable: {
      columns: [{
        label: 'Item Code',
        valuePath: 'itemCode'
      }, {
        label: 'Description',
        valuePath: 'description'
      }, {
        label: 'Qty',
        valuePath: 'qtyDescription'
      }, {
        label: 'Total Qty',
        valuePath: 'totalQty'
      }, {
        label: 'Note',
        valuePath: 'note'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    productTableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'UniqueID'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription'
      }, {
        label: 'Location',
        valuePath: 'strLocationName'
      }, {
        label: 'Size Group',
        valuePath: 'strProductProductSizeGroup'
      }, {
        label: 'selection',
        valuePath: 'UniqueID',
        cellComponent: 'web-table-scroll-button'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    firstSelected: true,
    openSearchProduct: false,

    loadReserve(reserveID) {
      let applicationController = this.get('appController'); //applicationController.openDialog('Loading sales');

      let commonUtility = this.get('commonUtility');
      let url = 'reserve/load';
      let data = {
        reserveID: reserveID
      };
      let this_is_this = this;
      commonUtility.HTTPGetSomething(url, data).then(function (data) {
        this_is_this.loadBasicInfo(data);
        this_is_this.loadTransactionLine(data.transaction);
        this_is_this.set('status', 'SAVED');
      }).catch(function (error) {
        console.log('loading error');
        console.log(error);
      }).then(function (data) {
        applicationController.closeDialog();
      });
    },

    loadBasicInfo(data) {
      this.set('currReserve', this.get('store').createRecord('reserve', {
        reserveDate: new Date(),
        remark: '',
        customerID: ''
      }));
      console.log('loading basic info on reserve date');
      console.log(this.get('currReserve'));
      console.log(data.basicReserveInfo.reserveDate);
      this.set('currentData.customerName', data.customerName);
      this.set('currReserve.remark', data.basicReserveInfo.remark);
      this.set('currReserve.customerID', data.basicReserveInfo.customerID);
      this.set('currReserve.reserveDate', (0, _moment.default)(data.basicReserveInfo.reserveDate).toDate());
      this.set('currReserve.reserveID', data.basicReserveInfo.reserveID);
      this.set('currReserve.expired', data.basicReserveInfo.expired);
      this.set('currReserve.referenceNo', data.basicReserveInfo.referenceNo);
    },

    loadTransactionLine(transactionLines) {
      console.log('load Transaction line');
      console.log(transactionLines);
      let arrTranLine = this.arrTransactionLine;
      arrTranLine.clear();
      transactionLines.forEach(function (item, index) {
        item.sizeGroup = item.strSalelnProductSizeStockSizeGroup;

        let transactionline = _transactionSaleLine.default.create({
          itemCode: item.strProductItemCode,
          description: item.strProductDescription,
          qtyDescription: item.Qty,
          // make this a computed from arrTextBox                        
          totalQty: item.totalQty,
          note: item.note,
          sizeGroup: item.sizeGroup,
          arrTextBox: null,
          data: item
        });

        arrTranLine.pushObject(transactionline);
      });
    },

    customerTableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'Remark',
        valuePath: 'remark'
      }, {
        label: 'Blacklisted',
        valuePath: 'blacklistedString'
      }, {
        label: 'selection',
        valuePath: 'id',
        cellComponent: 'web-table-scroll-button'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});