define("webcostume/routes/setup-user/listuser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let listUserController = this.controllerFor('setup-user.listuser');
      listUserController.refreshUserList();
    }

  });

  _exports.default = _default;
});