define("webcostume/routes/transaction-sale/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as injectService } from '@ember/service';
  // import { get, set } from '@ember/object';
  var _default = Ember.Route.extend({
    // keyManager: injectService(),
    actions: {
      didTransition() {
        this._super(...arguments);

        console.log('index sale didTransition');
        let saleController = this.controllerFor('transaction-sale.index');
        saleController.myInit();
        let paymentController = this.controllerFor('transaction-sale.payment');

        if (paymentController.get('paymentDone')) {
          saleController.startSaving();
        }

        let appController = this.controllerFor('application');

        if (appController.isFromRoute('setup-customer.setup-customer-input')) {
          console.log('run refreshcustomer in index routes of transaction sale');
          saleController.refreshCustomer();
        } else if (appController.isFromRoute('setup-customer.setup-customer-info')) {
          console.log('run refreshcustomer in index routes of transaction sale');
          saleController.refreshCustomerInfoList();
        } else if (appController.isFromRoute('transaction-sale.payment')) {} else {
          console.log('run refreshcustomer in index routes of transaction sale');
          saleController.makeNewBeginning();
        } // const addToListMacro = get(this, 'keyManager').addMacro({
        //     callback: bind(saleController, saleController.get('actions.btnAdd_click')),
        //     executionKey: 'a',
        //     modifierKeys : ['Alt'],
        //     priority: 100,
        //     keyEvent: 'keydown',
        //   });
        //   set(this, 'addToListMacro', addToListMacro);

      },

      willTransition(transition) {
        // transition to payment no need to ask
        if (transition.intent.name === 'transaction-sale.payment') {
          return true;
        } else if (transition.intent.name === 'setup-customer.setup-customer-input') {
          return true;
        } else if (transition.intent.name === 'setup-customer.setup-customer-info') {
          return true;
        } else {
          let saleController = this.controllerFor('transaction-sale.index');

          if (saleController.get('status').toUpperCase() === 'SAVED') {
            return true;
          }
        }

        let answer = confirm('Are you sure you want to close? All progress will be lost.');

        if (answer) {
          // this._super(...arguments);
          // const addToListMacro = get(this, 'addToListMacro');
          // get(this, 'keyManager').removeMacro(addToListMacro);
          return true;
        } else {
          transition.abort();
        }
      }

    }
  });

  _exports.default = _default;
});