define("webcostume/services/entity-reserve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    commonUtility: Ember.inject.service(),

    getReserveNo() {
      let url = "commonutility/getreserveno";
      let commonUtility = this.get('commonUtility');
      return commonUtility.HTTPGetSomething(url).then(function (data) {
        console.log('after getting exchange no- success ');
        return data.reserveno;
      }).catch(function (data) {
        console.log('after getting exchange no- fail ');
        return '';
      });
    },

    searchProduct(keyword) {}

  });

  _exports.default = _default;
});