define("webcostume/services/entity-productsize", ["exports", "webcostume/apai/entity/productsize"], function (_exports, _productsize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getMeTheThing(hello) {
    var myPromise = new Promise(function (presolve, preject) {
      let url = hello.get('commonUtility').giveMeFullUrl('productsizes');
      let headers = hello.get('commonUtility').giveMeHeader();
      $.ajax({
        url: url,
        headers: headers
      }).then(function (data) {
        hello.set('data', data);
        var productSize = {};
        data.data.forEach(function (item) {
          let sizeGroup = item[_productsize.productsizefieldName.sizeGroup];

          if (!(sizeGroup in productSize)) {
            productSize[sizeGroup] = [];
          }

          productSize[sizeGroup].push(item);
          hello.set('data', productSize);
        });
        hello.set('isDone', true);
        presolve();
      }).catch(reason => {
        console.log('get me the thing error.');
        console.log(reason);
        preject();
      });
    });
    return myPromise;
  }

  var _default = Ember.Service.extend({
    commonUtility: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('myPromise', getMeTheThing(this));
    },

    isDone: false,

    getSizes(sizeGroup) {
      let hello = this;

      if (this.get('isDone')) {
        return this.get('myPromise').then(function () {
          return hello.get('data')[sizeGroup];
        });
      } else {
        let mypromise = getMeTheThing(this).then(function () {
          return hello.get('data')[sizeGroup];
        });
        this.set('myPromise', mypromise);
        return mypromise;
      }
    },

    data: null
  });

  _exports.default = _default;
});