define("webcostume/routes/transaction-sale/sale-void-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let reportUnpaidCustomer = this.controllerFor('report.report-unpaid-customer');
      reportUnpaidCustomer.search();
    },

    actions: {
      didTransition() {
        this.controllerFor('transaction-sale').set('title', 'Sales - List');
      },

      willTransition(transition) {
        // reset memory once goes out
        // this is because when transaction-exchange.index instruction to send to them 
        // is still remembered next time it's accessed from the menu
        this.controllerFor('transaction-sale.sale-list').sendToMeInstead(null, null);
      }

    }
  });

  _exports.default = _default;
});