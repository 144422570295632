define("webcostume/controllers/report/report-productsummary", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function searchCustomer(this_is_this) {
    let commonUtility = this_is_this.get('commonUtility');
    let url = 'report/productsummary';
    this_is_this.set('isLoading', true);
    commonUtility.HTTPGetSomething(url).then(function (data) {
      this_is_this.set('customerResult', data.data);
      this_is_this.set('isLoading', false);
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      this_is_this.set('isLoading', false);
      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),

    init() {
      console.log('i am here for product summary');
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    isLoading: false,

    searchCustomer() {
      searchCustomer(this);
    },

    actions: {
      findRecords() {
        searchCustomer(this);
      },

      btnExport() {
        let commonUtility = this.get('commonUtility');
        let url = 'report/productsummaryexport';
        let data = this.get('tableData');
        let fileName = "productsummary_" + commonUtility.giveMeUniqueDate() + ".csv";
        commonUtility.HTTPAjaxDownload(url, fileName, data).catch(function (error) {
          alert('Error occurred while downloading');
        });
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Description',
        valuePath: 'strProductDescription'
      }, {
        label: 'Price',
        valuePath: 'dclProductSellingPrice'
      }, {
        label: 'Total Selling Price',
        valuePath: 'TotalSellingPrice'
      }, {
        label: 'Cost',
        valuePath: 'dclProductCost'
      }, {
        label: 'Total Cost',
        valuePath: 'dclTotalCost'
      }, {
        label: 'Stock',
        valuePath: 'CurrentStock'
      }, {
        label: 'Reservation',
        valuePath: 'CurrentReservation'
      }, {
        label: 'Size',
        valuePath: 'strStockFlowlnProductSizeID'
      }]
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: '',
      endDate: ''
    },
    customerResult: Ember.A([])
  });

  _exports.default = _default;
});