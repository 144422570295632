define("webcostume/apai/entity/productsizestock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.productsizestockfieldName = void 0;
  let productsizestockfieldName = {
    productCategoryID: 'strProductSizeStockProductCategoryID',
    productID: 'strProductSizeStockProductID',
    productSizeID: 'strProductSizeStockProductSizeID',
    qty: 'intProductSizeStockQty',
    sizeGroup: 'strProductSizeStockProductSizeGroup',
    maxQty: 'intProductSizeStockMaxQty',
    topUp: 'decProductSizeStockPercentTopUp',
    reorderPoint: 'intProductSizeStockReorderPoint'
  };
  _exports.productsizestockfieldName = productsizestockfieldName;
});