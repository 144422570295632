define("webcostume/controllers/report/report-moneymovement", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function searchMoneyMovement(this_is_this) {
    this_is_this.set('fetchingReport', true);
    let value = this_is_this.get('selectedValue');
    let startDate = (0, _moment.default)().format(officialDateFormat);
    let endDate = (0, _moment.default)().format(officialDateFormat);

    if (value === '7days') {
      startDate = (0, _moment.default)().add(-7, 'days').format(officialDateFormat);
    } else if (value === 'date') {
      startDate = (0, _moment.default)(this_is_this.get('current.startDate')).format(officialDateFormat), endDate = (0, _moment.default)(this_is_this.get('current.endDate')).format(officialDateFormat);
    }

    let data = {
      startDate: startDate,
      endDate: endDate
    };
    let commonUtility = this_is_this.get('commonUtility');
    let url = commonUtility.giveMeFullUrl('report/moneymovement');
    let headers = commonUtility.giveMeHeader();
    $.ajax({
      type: 'GET',
      dataType: "json",
      url: url,
      data: data,
      headers: headers,
      success: function (data) {
        console.log(data);
        this_is_this.set('results', data.data);
        let total = 0;
        data.summary.forEach(function (item, index) {
          total += numeral(item.TotalReceived).value();
        }); // temporary fix. best to use BigDecimal or Decimal.js

        this_is_this.set('summaryTotal', total.toFixed(2));
        this_is_this.set('summaryData', data.summary);
        this_is_this.set('fetchingReport', false);
      }
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),
    fetchingReport: false,

    init() {
      console.log('i am  here');
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    selectedValue: 'today',

    searchCustomer() {
      searchCustomer(this);
    },

    disableDate: Ember.computed('selectedValue', function () {
      return this.get('selectedValue') !== 'date';
    }),
    summaryTotal: 0,
    actions: {
      selectRadioButton(event) {
        let target = event.target;
        let value = target.value;

        if (target.checked) {
          this.set('selectedValue', value);
        }
      },

      findRecords() {
        searchMoneyMovement(this);
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Trans. Date',
        valuePath: 'dteMoneyMovementDate',
        cellComponent: 'web-table-date'
      }, {
        label: 'Cust Name',
        valuePath: 'strCustomerName'
      }, {
        label: 'Source Payment',
        valuePath: 'strMoneyMovementSource'
      }, {
        label: 'Reference No',
        valuePath: 'ReferenceNo'
      }, {
        label: 'Method',
        valuePath: 'strMoneyMovementPaymentMethod'
      }, {
        label: 'Amount',
        valuePath: 'decMoneyMovementAmount'
      }, {
        label: 'Sub Total',
        valuePath: 'TotalReceived'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    customerTableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'Remark',
        displayOrder: 'remark'
      }, {
        label: 'Blacklisted',
        valuePath: 'isBlacklisted'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: '',
      endDate: ''
    },
    openSearch: false,
    customerResult: Ember.A([])
  });

  _exports.default = _default;
});