define("webcostume/templates/setup-product/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xx8epBDY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header bg-info\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"m-0 font-weight-bold\"],[11,\"style\",\"color:white\"],[9],[0,\"Search\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n\"],[1,[27,\"input\",null,[[\"type\",\"insert-newline\",\"value\"],[\"text\",[27,\"action\",[[22,0,[]],\"findRecords\"],null],[23,[\"tableData\",\"searchField\"]]]]],false],[0,\" \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Search\"],[3,\"action\",[[22,0,[]],\"findRecords\"]],[10],[0,\"\\n\\n    \\n\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header \"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"setup-product.setup-product-input\",\"new\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"New Product \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n\"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"columns\",\"fetchingRecords\",\"editPage\"],[[23,[\"productList\"]],[23,[\"tableData\",\"columns\"]],[23,[\"fetchingRecords\"]],\"setup-product.setup-product-input\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-product/index.hbs"
    }
  });

  _exports.default = _default;
});