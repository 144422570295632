define("webcostume/templates/setup-productcategory/list-productcategory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gMuZ5vs7",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"link-to\",[\"setup-productcategory.setup-productcategory-input\",\"new\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"New category\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"columns\",\"selectAction\"],[[23,[\"productCategoryList\"]],[23,[\"tableData\",\"columns\"]],[27,\"action\",[[22,0,[]],\"deleteThisRecord\"],null]]]],false],[0,\"\\n\\n \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-productcategory/list-productcategory.hbs"
    }
  });

  _exports.default = _default;
});