define("webcostume/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    commonUtility: Ember.inject.service('common-utility'),
    webtreepermission: Ember.inject.service('webtreepermission'),
    session: Ember.inject.service('session'),

    model(params) {
      if (params.sessionid != null) {
        let data = {
          sessionid: params.sessionid
        };
        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('login');
        let this_is_this = this;
        $.ajax({
          type: 'POST',
          url: url,
          contentType: 'application/json',
          data: JSON.stringify(data),
          dataType: "json"
        }).then(function (data) {
          //console.log(data);
          this_is_this.get('session').setSessionID(data.sessionID, data.user);
          this_is_this.controllerFor('application').set('user', data.user);
          this_is_this.controllerFor('application').set('permissionList', data.permissionList);
          this_is_this.get('webtreepermission').set('allowedPermission', data.allowedPermission);
          this_is_this.transitionTo('index');
        }).catch(function (errorResponse) {
          console.log('error during auto login');
          console.log(errorResponse);
          let hello = errorResponse.responseJSON.errors[0].error;
          alert(' Login error ' + hello);
        }); // try to auto login here. 
      }
    }

  });

  _exports.default = _default;
});