define("webcostume/routes/setup-customer/setup-customer-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      if (params.customerID) {
        var record = this.store.peekRecord('customer', params.customerID);

        if (record == null) {
          record = this.store.findRecord('customer', params.customerID);
        }

        return record;
      } else {
        console.log('here');
      }
    },

    actions: {
      didTransition() {
        this.controllerFor('setup-customer').set('title', 'Setup Customer - Edit');
      },

      willTransition() {
        console.log('setup customer input will transition');
        this.controllerFor('application').setRouteName('setup-customer.setup-customer-input');
      }

    }
  });

  _exports.default = _default;
});