define("webcostume/apai/entity/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentfieldName = void 0;
  let paymentfieldName = {
    userID: 'intPaymentUserID',
    chequeID: 'intPaymentChequeID',
    remark: 'strPaymentRemark',
    method: 'strPaymentMethod',
    referenceNo: 'strPaymentReferenceNo',
    inputDate: 'dtePaymentInputDate',
    customerID: 'strPaymentCustomerID',
    paymentID: 'intPaymentID',
    paymentNo: 'strPaymentNo',
    paymentDate: 'dtePaymentDate'
  };
  _exports.paymentfieldName = paymentfieldName;
});