define("webcostume/templates/components/web-table-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WF5icFh3",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"row\",\"identifier\"]],[23,[\"column\",\"identifier\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"size\"],[\"text\",[23,[\"rawValue\"]],3]]],false],[0,\" \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-save\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"saveEditable\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"value\"],false],[0,\" \\n\\n\"],[4,\"if\",[[23,[\"row\",\"identifier\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"href\",\"\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-edit\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"setEditable\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-input.hbs"
    }
  });

  _exports.default = _default;
});