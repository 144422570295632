define("webcostume/components/web-table-scroll", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getID(this_is_this, id) {
    return '#' + this_is_this.elementId + id;
  } // displayField should expect a list


  var _default = Ember.Component.extend({
    fetchingRecords: false,
    // please set from the parent component
    comName: 'source_webtable',
    isLoading: false,
    canSelect: true,
    //    WTRowUpdateAction : null, // << this one is for calling component to set so we can pass in data down, action up
    selectAction: null,
    // for use by web-table-scroll-button
    updateRowAction: null,

    didInsertElement() {
      this.set('myself', this); //console.debug( getID(this, 'actr_table'));  
    },

    myself: null,

    // set this so that the parent could have the handler
    init() {
      this._super(...arguments);

      this.set('dataDisplay', Ember.A([]));
      this.set('internalData', Ember.A([])); //console.log(this.data);

      let table = new _emberLightTable.default(this.columns, this.dataDisplay, {
        enableSync: true
      });
      this.set('table', table);

      if (this.data) {
        this.internalData.pushObjects(this.data.toArray());
        this.dataDisplay.pushObjects(this.internalData.splice(0, 100));
        this.set('totalRecords', this.data.length);
      }
    },

    totalRecords: 0,
    loadedRecords: Ember.computed('dataDisplay.length', function () {
      return this.dataDisplay.length;
    }),
    sortBy: '',
    internalData: Ember.A([]),

    refreshData(sortBy, direction) {
      // console.log('sortyBy');
      // console.log(sortBy);
      let internalData = this.internalData;
      internalData.clear();

      if (sortBy === '') {
        this.data.forEach(function (item) {
          internalData.pushObject(item);
        });
      } else {
        let sortData = null;
        if (direction === 'asc') sortData = this.data.sortBy(sortBy);else sortData = this.data.sortBy(sortBy).reverse();
        sortData.forEach(function (item) {
          internalData.pushObject(item);
        });
      }

      let noOfInitialLoaded = 100;
      this.set('totalRecords', this.data.length);
      this.dataDisplay.clear();
      let onehundredRecordsfirst = this.get('internalData').splice(0, noOfInitialLoaded);
      this.get('dataDisplay').pushObjects(onehundredRecordsfirst);
    },

    // data.[] is to cater model change when deleting, the whole model won't be replaced 
    // but added only 
    dataChanged: Ember.observer('data', 'data.[]', function () {
      console.log('data changed for component');
      this.refreshData('');
    }),
    dataDisplay: Ember.A([]),
    data: null,
    scrollHeight: '100vh',
    table: null,
    actions: {
      onColumnClick(column) {
        this.get('dataDisplay').clear();

        if (column.get('ascending')) {
          this.refreshData(column.get('valuePath'), 'asc');
        } else {
          this.refreshData(column.get('valuePath'), 'desc');
        }
      },

      onScrolledToBottom() {
        this.set('isLoading', true);

        if (this.get('internalData').length > 0) {
          let tenRecords = this.get('internalData').splice(0, 10);
          this.dataDisplay.pushObjects(tenRecords);
        }

        this.set('isLoading', false);
      }

    }
  });

  _exports.default = _default;
});