define("webcostume/models/customer", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    customerID: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    contact: (0, _attr.default)('string'),
    isBlacklisted: (0, _attr.default)('number'),
    remark: (0, _attr.default)('string'),
    fax: (0, _attr.default)('string'),
    isCustomer: (0, _attr.default)('number'),
    isSupplier: (0, _attr.default)('string'),
    contactPerson: (0, _attr.default)('string'),

    friendlyName() {
      return this.get('name');
    },

    blacklistedString: Ember.computed('isBlacklisted', function () {
      if (this.get('isBlacklisted')) {
        return 'Yes';
      } else {
        return 'No';
      }
    })
  });

  _exports.default = _default;
});