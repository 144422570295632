define("webcostume/templates/components/web-table-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e0rvoS5c",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Select\"],[3,\"action\",[[22,0,[]],[23,[\"tableActions\",\"selectAction\"]],[23,[\"row\",\"content\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-button.hbs"
    }
  });

  _exports.default = _default;
});