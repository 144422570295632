define("webcostume/routes/setup-product/setup-product-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      console.log('setup product input route model()');
      console.log(params);
      let productInputController = this.controllerFor('setup-product.setup-product-input');
      productInputController.loadingNecessaryData(params.productID);
    },

    didTransition() {}

  });

  _exports.default = _default;
});