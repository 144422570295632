define("webcostume/templates/components/web-table-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vWKFhQdr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[9],[0,\"Delete\"],[3,\"action\",[[22,0,[]],[23,[\"tableActions\",\"deleteAction\"]],[23,[\"row\",\"content\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-delete.hbs"
    }
  });

  _exports.default = _default;
});