define("webcostume/helpers/dict-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dictValue = dictValue;
  _exports.default = void 0;

  function dictValue([array, value]
  /*, hash*/
  ) {
    // try to support ember array and normal array
    if (Ember.typeOf(array) == 'instance') {
      return array.get(value);
    } else {
      return array[value];
    }
  }

  var _default = Ember.Helper.helper(dictValue);

  _exports.default = _default;
});