define("webcostume/apai/entity/saleln", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.salelnfieldName = void 0;
  let salelnfieldName = {
    saleID: 'intSalelnID',
    salelnID: 'intSalelnSaleID',
    productCost: 'decSalelnProductCost',
    productCategoryID: 'strSalelnProductCategoryID',
    productID: 'strSalelnProductID',
    sizeGroup: 'strSalelnProductSizeStockSizeGroup',
    sizeID: 'strSalelnProductSizeStockSizeID',
    sellingPrice: 'decSalelnSellingPrice',
    qty: 'decSalelnQty',
    note: 'strSalelnNote',
    group: 'intSalelnGroup'
  };
  _exports.salelnfieldName = salelnfieldName;
});