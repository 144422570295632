define("webcostume/models/bank", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    bankCode: (0, _attr.default)('string'),
    bankName: (0, _attr.default)('string'),

    friendlyName() {
      return this.get('bankName');
    }

  });

  _exports.default = _default;
});