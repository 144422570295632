define("webcostume/apai/entity/paymentln", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentlnfieldName = void 0;
  let paymentlnfieldName = {
    paymentlnID: 'intPaymentlnID',
    amount: 'decPaymentlnAmount',
    discount: 'decPaymentlnDiscount',
    paymentID: 'intPaymentlnPaymentID',
    saleID: 'intPaymentlnSaleID'
  };
  _exports.paymentlnfieldName = paymentlnfieldName;
});