define("webcostume/controllers/report/report-lowstock", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function refreshLowStock(this_is_this, toExport) {
    this_is_this.set('fetchingReport', true);
    let commonUtility = this_is_this.get('commonUtility');
    let url = 'report/lowstock';
    this_is_this.set('isLoading', true);
    let valueList = [];

    if (this_is_this.get('selectedDate') == 'predefined') {
      let periodListSelected = this_is_this.get('periodListSelected');
      valueList = periodListSelected.value.split(",");
    } else {
      let currentStartDate = (0, _moment.default)(this_is_this.get('current.startDate'));
      let currentEndDate = (0, _moment.default)(this_is_this.get('current.endDate'));
      let startMonth = currentStartDate.year() * 12 + currentStartDate.month() + 1;
      let endMonth = currentEndDate.year() * 12 + currentEndDate.month() + 1;
      valueList = [startMonth, endMonth];
    }

    let productSearch = this_is_this.get('productSearch');

    if (!toExport) {
      commonUtility.HTTPGetSomething(url, {
        fromXMonth: valueList[0],
        toXMonth: valueList[1],
        lowStock: this_is_this.get('currentData.lowStock') ? 1 : 0,
        search: productSearch
      }).then(function (data) {
        this_is_this.set('lowStockResult', data.data);
        this_is_this.set('fetchingReport', false);
      }).catch(function (error) {
        let errorMessage = error.responseJSON.errors[0].error;

        if (!errorMessage) {
          errorMessage = '';
        }

        this_is_this.set('fetchingReport', false);
        alert('Saving Error. ' + errorMessage);
      });
    } else {
      let fileName = "lowstockreport_" + commonUtility.giveMeUniqueDate() + ".csv";
      let url = 'report/lowstockexport';
      let table = this_is_this.get('tableData');
      commonUtility.HTTPAjaxDownload(url, fileName, table, {
        fromXMonth: valueList[0],
        toXMonth: valueList[1],
        lowStock: this_is_this.get('currentData.lowStock') ? 1 : 0,
        search: productSearch
      }).catch(function (error) {
        alert('Error downloading');
      });
    }
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),
    periodList: Ember.A([]),
    periodListSelected: '',
    fetchingReport: false,
    productSearch: '',

    init() {
      let periodList = this.get('periodList');
      let currentMoment = (0, _moment.default)();
      let currentMonth = currentMoment.year() * 12 + currentMoment.month() + 1;

      for (let i = 12; i <= 12; i++) {
        periodList.pushObject({
          desc: "Last " + String(i) + "month ",
          value: String(currentMonth - i) + "," + String(currentMonth)
        });
      }

      periodList.pushObject({
        desc: "Year " + String((0, _moment.default)().year()),
        value: String(currentMoment.year() * 12 + 1) + "," + String(currentMonth)
      });
      periodList.pushObject({
        desc: "Year " + String((0, _moment.default)().year() - 1),
        value: String((currentMoment.year() - 1) * 12 + 1) + "," + String(currentMoment.year() * 12)
      });
      this.set('periodListSelected', periodList.objectAt(0));
    },

    isLoading: false,

    refreshLowStock() {
      refreshLowStock(this);
    },

    selectedDate: 'predefined',
    disableDate: Ember.computed('selectedDate', function () {
      return this.get('selectedDate') !== 'date';
    }),
    actions: {
      selectRadioButton(event) {
        let target = event.target;
        let value = target.value;

        if (target.checked) {
          this.set('selectedDate', value);
        }

        console.log(value);
      },

      findRecords() {
        refreshLowStock(this);
      },

      btnExport() {
        refreshLowStock(this, true);
      },

      saveRow(row, identifier) {
        let productIDColumn = 'ProductID';
        let sizeGroupColumn = 'strStockFlowlnProductSizeGroup';
        let topUpPercentColumn = 'decProductSizeStockPercentTopUp';
        let sizeIDColumn = 'strStockFlowlnProductSizeID';
        let maxQtyColumn = 'intProductSizeStockMaxQty';
        let maxQty = row.get(maxQtyColumn);
        let productID = row.get(productIDColumn);
        let sizeGroup = row.get(sizeGroupColumn);
        let sizeID = row.get(sizeIDColumn);
        let currentStock = row.get('CurrentStock');
        let absolutePercentTopUp = row.get(topUpPercentColumn);
        let percentDecimalTopUp = absolutePercentTopUp / 100;
        let reorderPoint = row.get('ReorderPoint');
        let lowStockResult = this.get('lowStockResult');
        let commonUtility = this.get('commonUtility');

        if (identifier == 'TopUpPercent') {
          let url = 'report/savelowstocktopuppercent';
          commonUtility.HTTPPostSomething(url, {
            productID: productID,
            percentDecimalTopUp: percentDecimalTopUp,
            sizeGroup: sizeGroup
          }).then(function (data) {
            for (let i = 0; i < lowStockResult.length; i++) {
              let rowItem = lowStockResult.objectAt(i);
              let rowItemProductID = rowItem.ProductID;
              let rowItemSizeGroup = rowItem.strStockFlowlnProductSizeGroup;

              if (productID == rowItemProductID && sizeGroup == rowItemSizeGroup) {
                console.log(absolutePercentTopUp);
                console.log(Ember.get(rowItem, 'HistoricalValue') / 100);
                let estimateTopUp = absolutePercentTopUp * Ember.get(rowItem, 'HistoricalValue') / 100 - Ember.get(rowItem, 'CurrentStock');
                estimateTopUp = estimateTopUp.toFixed(2);
                Ember.set(rowItem, 'EstimateTopUp', estimateTopUp);
                Ember.set(rowItem, topUpPercentColumn, absolutePercentTopUp);
              }
            }
          }).catch(function (error) {
            let errorMessage = error.responseJSON.errors[0].error;

            if (!errorMessage) {
              errorMessage = '';
            }

            alert('Saving Error. ' + errorMessage);
          });
        } else if (identifier == 'MaxQty' || identifier == 'ReorderPoint') {
          let url = 'report/savelowstockreordermaxqty';
          commonUtility.HTTPPostSomething(url, {
            productID: productID,
            percentDecimalTopUp: percentDecimalTopUp,
            maxQty: maxQty,
            reorderPoint: reorderPoint,
            sizeGroup: sizeGroup,
            sizeID: sizeID
          }).then(function (data) {
            let totopup = maxQty - currentStock;
            Ember.set(row, 'ToTopUp', totopup);
          }).catch(function (error) {
            let errorMessage = error.responseJSON.errors[0].error;

            if (!errorMessage) {
              errorMessage = '';
            }

            alert('Saving Error. ' + errorMessage);
          });
        }
      }

    },
    tableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'ProductID',
        width: '100px'
      }, {
        label: 'Product Name',
        valuePath: 'strProductDescription',
        resizable: true,
        width: '300px'
      }, {
        label: 'Size Type',
        valuePath: 'strStockFlowlnProductSizeID',
        width: '60px'
      }, {
        label: 'Current Stock',
        valuePath: 'CurrentStock',
        width: '100px'
      }, {
        label: 'Historical Sale',
        valuePath: 'HistoricalValue',
        width: '100px'
      }, {
        label: '% Top Up',
        valuePath: 'decProductSizeStockPercentTopUp',
        cellComponent: 'web-table-input',
        identifier: 'TopUpPercent',
        classNames: 'columnBlue',
        width: '120px'
      }, {
        label: 'Estimate Top Up',
        valuePath: 'EstimateTopUp',
        classNames: 'columnBlue',
        width: '100px'
      }, {
        label: 'Order To',
        valuePath: 'intProductSizeStockMaxQty',
        classNames: 'columnGreen',
        cellComponent: 'web-table-input',
        identifier: 'MaxQty'
      }, {
        label: 'Order Qty',
        classNames: 'columnGreen',
        valuePath: 'ToTopUp'
      }, {
        label: 'Reorder Point',
        valuePath: 'ReorderPoint',
        classNames: 'columnRed',
        cellComponent: 'web-table-input',
        identifier: 'ReorderPoint',
        width: '100px'
      }, {
        label: 'Low Stock',
        valuePath: 'lowStock',
        cellComponent: 'web-table-icon',
        classNames: 'columnRed',
        iftrueicon: 'tick',
        iffalseicon: 'cross',
        width: '70px'
      }]
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: new Date(),
      endDate: new Date()
    },
    currentData: {
      lowStock: false
    },
    lowStockResult: Ember.A([])
  });

  _exports.default = _default;
});