define("webcostume/components/payment-selection", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // singlePayment is just for selection only. 
  var _default = Ember.Component.extend({
    didInsertElement() {
      console.log('Payment selection insert');
      this.set('myself', this);
    },

    buttonName: 'Add',
    myself: null,
    // set this so that the parent could have the handler
    remainingAmount: 0,
    // from parent
    store: Ember.inject.service(),
    singlePayment: false,
    changeAmount: Ember.computed('remainingAmount', 'amount', function () {
      // only when cash tender
      let result = numeral(this.get('amount')).subtract(this.get('remainingAmount'));
      console.log(result);
      return result.value();
    }),
    transactionTypeList: ['[ALL]', 'Invoice', 'POS'],
    selectedTransaction: '[ALL]',

    init() {
      console.log('paymetn selection init');

      this._super(...arguments);

      console.log(arguments);
      let store = this.get('store'); // apparently the component remembers state so have to explicitly clear it

      console.log(this.get('cheque'));
      console.log(this.get('cheque.chequeDate'));
      console.log(this.get('cheque.chequeNo'));
      console.log(this.get('cheque.bankSelection'));
      let bankSelection = this.get('cheque.bankSelection');
      store.query('bank', {
        search: ''
      }).then(results => {
        this.set('banks', results);

        if (bankSelection == null || bankSelection == '') {
          this.set('cheque.bankSelection', results.objectAt(0).bankCode);
        }
      });
      this.set('cheque', {
        chequeDate: '',
        chequeNo: '',
        bankSelection: ''
      });
      this.set('currSelection', this.paymentType.objectAt(0));
      this.set('paymentDate', new Date());
      console.log('done init payment selection');
    },

    willDestroyElement() {// this.set('cheque.bankSelection', results.objectAt(0).get('bankCode'));
      //     this.set('cheque.chequeDate', '');
      //     this.set('cheque.chequeNo', '');
    },

    // onBanksChange : Ember.observer('banks', function() {
    // }), 
    amount: 0,
    banks: null,
    paymentType: ['NETS', 'Cash', 'Bank Transfer', 'Cheque'],
    chequeNo: '',
    currSelection: '',
    buttonOkayClickedEvent: null,
    buttonOkayAndSavedEvent: null,
    actions: {
      onPaymentChange(event) {
        this.set('currSelection', event.target.value);
      },

      onBankChange(event) {
        this.set('cheque.bankSelection', event.target.value);
      },

      btnOkay_click() {
        if (this.currSelection.toUpperCase() == 'CHEQUE') {
          console.log(this.get('cheque.chequeNo'));

          if (this.get('cheque.chequeNo') == null || this.get('cheque.chequeNo') == '') {
            alert('Please fill in cheque no.');
            $('#' + this.elementId + 'actr_chequeNo').focus();
            return;
          } else if (this.get('cheque.chequeDate') == null || this.get('cheque.chequeDate') == '') {
            alert('Please fill in cheque date');
            $('#' + this.elementId + 'actr_chequeDate').focus();
            return;
          }
        }

        if (!this.get('singlePayment')) {
          let amount = numeral(this.get('amount')).value();

          if (!amount || amount === 0) {
            alert('Please key in the amount ');
            $('#' + this.elementId + 'actr_amount').focus();
            return;
          }
        }

        if (this.buttonOkayClickedEvent) {
          this.buttonOkayClickedEvent();
        }
      },

      btnOkayAndDone_click() {
        if (this.currSelection.toUpperCase() == 'CHEQUE') {
          console.log(this.get('cheque.chequeNo'));

          if (this.get('cheque.chequeNo') == null || this.get('cheque.chequeNo') == '') {
            alert('Please fill in cheque no.');
            $('#' + this.elementId + 'actr_chequeNo').focus();
            return;
          } else if (this.get('cheque.chequeDate') == null || this.get('cheque.chequeDate') == '') {
            alert('Please fill in cheque date');
            $('#' + this.elementId + 'actr_chequeDate').focus();
            return;
          }
        }

        if (!this.get('singlePayment')) {
          let amount = numeral(this.get('amount')).value();

          if (!amount || amount === 0) {
            alert('Please key in the amount ');
            $('#' + this.elementId + 'actr_amount').focus();
            return;
          }
        }

        if (this.buttonOkayAndSavedEvent) {
          this.buttonOkayAndSavedEvent();
        }
      }

    },
    cheque: {
      chequeDate: '',
      chequeNo: '',
      bankSelection: ''
    },

    getDetail() {
      console.log('paymentselection getdetail');
      let detail = ''; // get detail will only work if the component is visible at the screen,
      // if the modal is closed, meaning the object will be destroyed. 

      if (this.currSelection.toUpperCase() == 'CHEQUE') {
        let chequeDateFormat = (0, _moment.default)(this.get('cheque.chequeDate')).format('DD/MM/YYYY');
        detail = this.get('cheque.bankSelection') + '(' + this.get('cheque.chequeNo') + ') - ' + chequeDateFormat;
      }

      return {
        paymentDate: (0, _moment.default)(this.paymentDate).format('DD/MM/YYYY'),
        paymentType: this.currSelection,
        amount: this.amount,
        detail: detail,
        cheque: this.cheque,
        paymentNo: ''
      };
    },

    clearData() {
      this.set('amount', 0);
      this.set('currSelection', 'NETS');
      this.set('cheque', {
        chequeDate: '',
        chequeNo: '',
        bankSelection: this.banks.objectAt(0).get('bankCode')
      });
    },

    disabledPayment: false,
    disabledPaymentDate: false,
    paymentDate: ''
  });

  _exports.default = _default;
});