define("webcostume/routes/setup-user/userpermission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let userID = params.userID;
      let userPermissionController = this.controllerFor('setup-user.userpermission');
      userPermissionController.refreshPermission(userID);
    }

  });

  _exports.default = _default;
});