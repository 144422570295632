define("webcostume/templates/setup-user/listuser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HkRz/5lL",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n\\n\"],[4,\"link-to\",[\"setup-user.setup-user-input\",\"new\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"New User \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n\\n\"],[1,[27,\"web-table\",null,[[\"data\",\"columns\",\"totalRecord\",\"currentPage\",\"deleteAction\"],[[23,[\"userList\"]],[23,[\"tableData\",\"columns\"]],[23,[\"totalRecord\"]],[23,[\"tableData\",\"page\"]],[27,\"action\",[[22,0,[]],\"deleteThisRecord\"],null]]]],false],[0,\"\\n\\n \\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-user/listuser.hbs"
    }
  });

  _exports.default = _default;
});