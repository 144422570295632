define("webcostume/controllers/admin-stockin/index", ["exports", "webcostume/apai/gridline/transaction-sale-line", "moment"], function (_exports, _transactionSaleLine, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function makeNewBeginning(this_is_this) {
    let arrTransactionLine = this_is_this.arrTransactionLine;
    arrTransactionLine.clear();
    this_is_this.set('current.stockInDate', new Date());
    this_is_this.set('current.remark', '');
    this_is_this.set('current.stockInID', -1);

    if (this_is_this.get('current.autoGenerate')) {
      this_is_this.get('commonNumber').getStockInNo().then(function (data) {
        this_is_this.set('current.referenceNo', data);
      });
    }

    this_is_this.set('status', 'NEW');
  }

  function forceRefreshTransactionLine(this_is_this) {
    let omgThis = Ember.A([]);
    this_is_this.arrTransactionLine.forEach(function (item, index) {
      let transactionline = _transactionSaleLine.default.create({
        itemCode: item.itemCode,
        description: item.description,
        qtyDescription: item.qtyDescription,
        // make this a computed from arrTextBox                    
        totalQty: item.totalQty,
        sizeGroup: item.sizeGroup,
        arrTextBox: item.arrTextBox,
        data: item.data
      });

      omgThis.addObject(transactionline);
    });
    this_is_this.set('arrTransactionLine', omgThis);
  }

  function schedulePromise(word, this_is_this) {
    let itemCode = word.substr(0, 6);
    let sizeID = word.substr(6);
    this_is_this.get('queue').add(function () {
      return new Promise(function (resolve, reject) {
        let found = false;
        let added = false;
        let productSizeHandler = this_is_this.get('productSizeInputHandler'); // check whether inside the transaction line

        this_is_this.arrTransactionLine.forEach(function (item, index) {
          if (item.itemCode == itemCode) {
            //console.log('sucess in locating item code');
            item.arrTextBox.forEach(function (textBoxItem, textBoxindex) {
              if (textBoxItem.sizeID == sizeID) {
                textBoxItem.set('qty', numeral(textBoxItem.get('qty')).value() + 1);
                let totalQty = productSizeHandler.getTotalQty(item.arrTextBox);
                item.qtyDescription = productSizeHandler.getQtyDescription(item.arrTextBox);
                item.totalQty = totalQty;
                item.subtotal = totalQty * item.sellingPrice;
                added = true; // console.log(item);
              }
            });
            found = true;
          }
        }); //console.log('not found');

        if (!found) {
          let url = this_is_this.get('commonUtility').giveMeFullUrl('product/' + itemCode);
          let headers = this_is_this.get('commonUtility').giveMeHeader();
          let ajaxResult = $.ajax({
            type: 'GET',
            url: url,
            headers: headers,
            async: false
          });

          if (ajaxResult.status == 0) {
            resolve();
            return;
          }

          if (ajaxResult.responseJSON.data) {
            let productObject = ajaxResult.responseJSON.data;
            let price = productObject['decProductWholesalePrice'];

            if (this_is_this.get('current.salePricing').toLowerCase() == 'retail') {
              price = productObject['dclProductSellingPrice'];
            }

            let sizeGroup = productObject['strProductProductSizeGroup'];
            productObject.sizeGroup = sizeGroup; // for bridging 

            let productSize = this_is_this.get('entityProductSize');
            let myPromise = productSize.getSizes(sizeGroup);
            myPromise.then(function (arrSize) {
              let arrTextBox = productSizeHandler.generateArrTextBox(arrSize); // incrementone 

              let foundTextBoxSize = false;
              arrTextBox.forEach(function (textBoxItem, textBoxindex) {
                if (textBoxItem.sizeID == sizeID) {
                  textBoxItem.set('qty', numeral(textBoxItem.get('qty')).value() + 1);
                  foundTextBoxSize = true;
                }
              });

              if (foundTextBoxSize) {
                let transactionline = _transactionSaleLine.default.create({
                  itemCode: itemCode,
                  description: productObject['strProductDescription'],
                  qtyDescription: productSizeHandler.getQtyDescription(arrTextBox),
                  // make this a computed from arrTextBox
                  sellingPrice: price,
                  totalQty: productSizeHandler.getTotalQty(arrTextBox),
                  subtotal: price,
                  note: '',
                  sizeGroup: sizeGroup,
                  arrTextBox: arrTextBox,
                  data: productObject
                });

                this_is_this.get('arrTransactionLine').pushObject(transactionline);
              }

              resolve();
            });
          } else {
            resolve();
            return;
          }
        } else {
          if (added) forceRefreshTransactionLine(this_is_this);
          resolve();
        }
      });
    });
  }

  function validateForm(this_is_this) {
    if (!this_is_this.arrTransactionLine || this_is_this.arrTransactionLine.length == 0) {
      alert('There is no stock in line');
      return false;
    } else if (numeral(this_is_this.get('current.grandTotalLine')).value() < 0) {
      alert('Grand total is less than 0.');
      return false;
    }

    return true;
  }

  function getTotal(arrTransactionLine) {
    let total = 0;
    arrTransactionLine.forEach(function (item, index) {
      total += numeral(item.totalQty).value();
    });
    return total;
  }

  function setLineItem(this_is_madness, data, productSize, existing, productSizeInputHandler, transactionLine) {
    let myPromise = productSize.getSizes(data['sizeGroup']);
    let outsideData = data;
    myPromise.then(data => {
      this_is_madness.set('arrProductSize', data);

      if (!existing) {
        this_is_madness.set('productSizeChanged', new Date()); // here to reset arrTextBox 
      } else {
        // no reset needed cos we are setting it.
        productSizeInputHandler.set('arrTextBox', transactionLine.arrTextBox);
        this_is_madness.set('current.currentLineTotalQty', productSizeInputHandler.getTotalQty());
      } // call function here to load product size 


      productSizeInputHandler.loadRemainingQty(outsideData['strProductItemCode']);
    });
    this_is_madness.set('currProduct', data);
    this_is_madness.set('current.itemCode', data['strProductItemCode']);
    this_is_madness.set('current.productDescription', data['strProductDescription']);

    if (!existing) {
      this_is_madness.set('current.currentLineTotalQty', 0);
    }
  }

  function clearLineItem(this_is_madness) {
    this_is_madness.set('arrProductSize', []);
    this_is_madness.set('productSizeChanged', new Date());
    this_is_madness.set('currProduct', null);
    this_is_madness.set('current.itemCode', '');
    this_is_madness.set('current.productDescription', '');
    this_is_madness.set('current.currentLineTotalQty', 0);
  }

  var _default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),
    commonNumber: Ember.inject.service('common-number'),
    entityProductSize: Ember.inject.service('entity-productsize'),
    entitySale: Ember.inject.service('entity-sale'),
    commonUtility: Ember.inject.service(),
    session: Ember.inject.service(),
    webTableSalelnHandler: null,
    queue: null,

    init() {
      var maxConcurrent = 1;
      this.set('queue', new Queue(maxConcurrent));
      this.set('current.stockInDate', new Date());
    },

    myInitDone: false,

    isDirty() {
      console.log(this.get('status').toUpperCase());
      return this.get('status').toUpperCase() == 'NEW';
    },

    myInit() {
      if (!this.get('myInitDone')) {
        // to kickstart the init , move this to init place
        this.get('commonNumber').getStockInNo().then(data => {
          this.set('current.referenceNo', data);
        });
        let productSize = this.get('entityProductSize');
        this.set('myInitDone', true);
      }
    },

    disabledControl: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED' || this.get('status').toUpperCase() == 'EDIT';
    }),
    disabledControlExceptEdit: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED';
    }),
    status: 'NEW',
    savingStatus: 'idle',
    // TO Control locking of screen
    onStatusChanged: Ember.observer('savingStatus', function () {
      if (this.get('savingStatus').toUpperCase() == 'SAVING') {
        this.get('appController').openDialog();
      } else {
        this.get('appController').closeDialog();
      }
    }),
    productSizeChanged: null,
    // changed this variable to trigger event to productsizeinput component
    searchResults: Ember.A([]),
    // this is for searching customer, pump in here, you get your result 
    productSearchResults: Ember.A([]),
    // this is for searching product, pump in here, you get your result
    openSearch: false,
    // this is to control searching for customer
    openSearchProduct: false,
    // this is to control searching for product 
    arrProductSize: null,
    // this is for component inputcontrol
    arrTransactionLine: Ember.A([]),
    productSizeInputHandler: 'a',
    actions: {
      modal_closeSearch_onHide() {
        this.set('openSearchProduct', false);
        console.log($('#actrl_description').focus());
      },

      btnVoidTransaction_click() {
        console.log(' I am about to be gone');
        let answer = confirm('Are you sure you want to delete this transaction?');

        if (!answer) {
          console.log('phew...');
          return;
        }

        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('stockin/void/' + this.get('current.stockInID'));
        let headers = commonUtility.giveMeHeader();
        let this_is_this = this;
        let applicationController = this.get('appController');
        applicationController.openDialog('Deleting..');
        $.ajax({
          type: 'DELETE',
          url: url,
          headers: headers
        }).then(function (datasent) {
          makeNewBeginning(this_is_this);
          alert('Transaction voided.');
        }).catch(function (error) {
          console.log(error);
          alert('Transaction not voided.');
        }).then(function () {
          applicationController.closeDialog();
        });
      },

      btnNew_click() {
        if (this.get('status') == 'NEW') {
          let answer = confirm('Are you sure you want to clear everything?');

          if (!answer) {
            return;
          }
        }

        makeNewBeginning(this);
      },

      btnToggleStatus_click() {
        let status = this.get('status');

        if (status == 'NEW') {
          this.set('status', 'EDIT');
        } else if (status == 'EDIT') {
          this.set('status', 'SAVED');
        } else if (status == 'SAVED') {
          this.set('status', 'NEW');
        }
      },

      btnEdit_click(source) {
        this.set('status', 'EDIT');
      },

      btnSaveTransaction_click() {
        if (!validateForm(this)) {
          return;
        }

        this.startSaving();
      },

      btnDeleteTransctionLine_click(itemToDelete) {
        //console.log('btnDeleteTransctionLine_click');
        let productSize = this.get('entityProductSize');
        let data = itemToDelete.data;
        setLineItem(this, data, productSize, true, this.productSizeInputHandler, itemToDelete);
        this.arrTransactionLine.removeObject(itemToDelete);
        return new Promise(function (resolve, reject) {
          resolve({
            success: true
          });
        });
      },

      qtyChanged(totalQty) {
        this.set('current.currentLineTotalQty', totalQty);
      },

      searchSelect(source, data) {
        if (source == 'product') {
          this.set('openSearchProduct', false);
          let productSize = this.get('entityProductSize'); // bridging for delete when loading from sale

          data.sizeGroup = data['strProductProductSizeGroup'];
          setLineItem(this, data, productSize, false, this.productSizeInputHandler);
        }
      },

      itemOnFocus(event) {
        let itemCode = this.get('current.itemCode');
        this.set('oldItemCode', itemCode);
      },

      itemLostFocus(event) {
        let oldItemCode = this.get('oldItemCode');
        let currentItemCode = this.get('current.itemCode');
        let this_is_this = this;

        if (oldItemCode !== currentItemCode) {
          if (currentItemCode.length == 6) {
            let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
            let headers = this.get('commonUtility').giveMeHeader();
            let ajaxResult = $.ajax({
              type: 'GET',
              url: url,
              headers: headers
            }).then(function (datasent) {
              let data = datasent.data;

              if (data) {
                let productSizeHandler = this_is_this.get('productSizeInputHandler');
                let productSize = this_is_this.get('entityProductSize');
                data['sizeGroup'] = data['strProductProductSizeGroup'];
                setLineItem(this_is_this, datasent.data, productSize, false, productSizeHandler);
              } else {
                clearLineItem(this_is_this);
              }
            }).catch(function (error) {
              console.log(error);
              clearLineItem(this);
            });
          } else {
            // clear line item
            clearLineItem(this);
          }
        }
      },

      itemCodeKeyUp(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('current.itemCode', '');
          schedulePromise(word, this); //    let itemCode =  word.substr(0, 6);
          //    let size = word.substr(6);
          //    // check transaction line for item. 
          //    // if not, get the data and add it. 
          //    // ask productsizehandler to generate arrtextbox
          //     let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
          //     let headers = this.get('commonUtility').giveMeHeader();
          //     let ajaxResult = $.ajax({
          //             type : 'GET',
          //             url : url,                                         
          //             headers : headers,                         
          //     }).then(function(data) {
          //             console.log(data);
          //     }).catch(function(error) {
          //             console.log(error);
          //     });              
        }
      },

      productKeyDown(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('openSearchProduct', true);
          let entitySaleService = this.get('entitySale');
          let keyword = this.get('current.productDescription');
          entitySaleService.searchProduct(keyword).then(results => {
            this.set('productSearchResults', results);
          });
        }
      },

      btnAdd_click(event) {
        //console.log('btnAddClick');
        if (!this.get('currProduct')) {
          alert('There is no product.');
          $('#actrl_description').focus();
          return;
        } else if (this.get('current.currentLineTotalQty') == 0) {
          alert('Please enter some qty.');
          return;
        }

        let productSizeHandler = this.get('productSizeInputHandler');

        let transactionline = _transactionSaleLine.default.create({
          itemCode: this.get('current.itemCode'),
          description: this.get('current.productDescription'),
          qtyDescription: productSizeHandler.getQtyDescription(),
          // make this a computed from arrTextBox                
          totalQty: this.get('current.currentLineTotalQty'),
          sizeGroup: this.get('currProduct')['strProductProductSizeGroup'],
          arrTextBox: productSizeHandler.get('arrTextBox'),
          data: this.get('currProduct')
        });

        this.arrTransactionLine.pushObject(transactionline);
        clearLineItem(this);
      },

      btnCancelChanges_click() {
        let answer = confirm('Are you sure you want to revert?');

        if (!answer) {
          return;
        }

        this.loadStockIn(this.get('current.stockID'));
      }

    },
    transactionWebTable: {
      columns: [{
        label: 'Item Code',
        valuePath: 'itemCode'
      }, {
        label: 'Description',
        valuePath: 'description',
        resizable: true,
        width: '300px'
      }, {
        label: 'Qty',
        valuePath: 'qtyDescription'
      }, {
        label: 'Total Qty',
        valuePath: 'totalQty'
      }, {
        label: 'Note',
        valuePath: 'note'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    productTableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'UniqueID'
      }, {
        label: 'Thumbnail',
        valuePath: 'strProductItemCode',
        cellComponent: 'web-table-thumbnail'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription',
        resizable: true,
        width: '300px'
      }, {
        label: 'Location',
        valuePath: 'strLocationName'
      }, {
        label: 'Size Group',
        valuePath: 'strProductProductSizeGroup'
      }, {
        label: 'selection',
        valuePath: 'UniqueID',
        cellComponent: 'web-table-scroll-button'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      itemCode: '',
      autoGenerate: true,
      productDescription: '',
      currentLineTotalQty: 0,
      referenceNo: '',
      stockInDate: '',
      remark: '',
      grandTotalLine: 0
    },
    arrTransactionLineChanged: Ember.observer('arrTransactionLine.[]', 'current.discountAmount', function () {
      let total = getTotal(this.arrTransactionLine);
      this.set('current.grandTotalLine', numeral(total).format('0,0.00')); // let servicePHSetting = this.get('servicePHSetting');
      // let GST =total * parseFloat(servicePHSetting.getKey('GST'));
      // this.set('current.grandGST', GST);
      // this.set('current.grandTotal', total + GST);
    }),
    currProduct: null,

    // this is to send out to space to be saved
    getTransaction() {
      let transactLine = [];
      this.arrTransactionLine.forEach(function (item, value) {
        let qtyList = [];
        item.arrTextBox.forEach(function (itemTextBox, indexTextBox) {
          if (itemTextBox.qty > 0) {
            qtyList.pushObject({
              sizeID: itemTextBox.sizeID,
              qty: itemTextBox.qty
            });
          }
        });
        let currObject = {
          itemCode: item.itemCode,
          qtyList: qtyList //console.log(currObject);

        };
        transactLine.pushObject(currObject);
      });
      let data = {
        autoGenerate: this.get('current.autoGenerate'),
        stockInDate: (0, _moment.default)(this.get('current.stockInDate')).format('DD/MM/YYYY'),
        referenceNo: this.get('current.referenceNo'),
        remark: this.get('current.remark'),
        transactLine: transactLine //console.log('get payment');
        //console.log(data);

      };
      return data;
    },

    startSaving() {
      let stockInController = this;
      let originalStatus = this.get('status');
      this.set('savingStatus', 'saving');
      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('stockin/save');
      let headers = commonUtility.giveMeHeader();
      let myPromiseToSaveYou = new Promise(function (resolve, reject) {
        let instruction = 'NEW';

        if (originalStatus == 'EDIT') {
          instruction = 'EDIT';
        }

        let data = {
          instruction: instruction,
          stockin: stockInController.getTransaction()
        };
        $.ajax({
          type: 'POST',
          dataType: "json",
          headers: headers,
          url: url,
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(data),
          success: function (data) {
            //console.log(data);
            stockInController.setSaved(data);
          }
        }).catch(function (error) {
          console.log(error);
          let errorMessage = error.responseJSON.errors[0].error;

          if (!errorMessage) {
            errorMessage = '';
          }

          alert('Saving Error. ' + errorMessage);
        }).then(function (finalData) {
          stockInController.set('savingStatus', 'FAIL');
        });
        resolve();
      });
    },

    setSaved(data) {
      this.set('status', 'SAVED');
      this.set('savingStatus', 'IDLE');
      this.set('current.stockInID', data.stockInID);
      this.set('current.referenceNo', data.referenceNo);
    },

    loadStockIn(intStockInID) {
      let applicationController = this.get('appController');
      applicationController.openDialog('Loading record...');
      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('stockin/load');
      let data = {
        stockInID: intStockInID
      };
      let this_is_this = this;
      let headers = commonUtility.giveMeHeader();
      let ajaxResult = $.ajax({
        type: 'GET',
        url: url,
        data: data,
        headers: headers,
        dataType: "json"
      }).then(function (data) {
        //console.log(data);
        this_is_this.loadBasicInfo(data.basicSaleInfo);
        this_is_this.loadTransactionLine(data.transactionLines);
        this_is_this.set('status', 'SAVED');
      }).catch(function (error) {
        console.log('loading error');
        console.log(error);
      }).then(function (data) {
        applicationController.closeDialog();
      });
    },

    loadBasicInfo(basicSaleInfo) {
      this.set('current.stockInDate', _moment.default.utc(basicSaleInfo.stockInDateFormatted, 'DD/MM/YYYY').toDate());
      this.set('current.stockInID', basicSaleInfo.stockInID);
      this.set('current.referenceNo', basicSaleInfo.referenceNo);
      this.set('current.remark', basicSaleInfo.remark);
    },

    loadTransactionLine(transactionLines) {
      console.log('load Transaction line');
      console.log(transactionLines);
      let arrTransactionLine = this.arrTransactionLine;
      arrTransactionLine.clear();
      let productSizeHandler = this.get('productSizeInputHandler');
      transactionLines.forEach(function (item, index) {
        // cos when delete product size handler only accepts ember Object
        let theBridgingArrTextBox = Ember.A([]);
        item.arrTextBox.forEach(function (item, index) {
          theBridgingArrTextBox.addObject(Ember.Object.create({
            qty: item.qty,
            sizeID: item.sizeID
          }));
        });
        item.sizeGroup = item.strSalelnProductSizeStockSizeGroup;
        console.log('item in loading transaction line admin stock in');
        console.log(item);

        let transactionline = _transactionSaleLine.default.create({
          itemCode: item.strProductItemCode,
          description: item.strProductDescription,
          qtyDescription: productSizeHandler.getQtyDescription(item.arrTextBox),
          totalQty: item.totalQty,
          sizeGroup: item.sizeGroup,
          arrTextBox: theBridgingArrTextBox,
          data: item
        });

        arrTransactionLine.pushObject(transactionline);
      });
    }

  });

  _exports.default = _default;
});