define("webcostume/controllers/admin/stock-take", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function refreshLowStock(this_is_this, toExport) {
    this_is_this.set('fetchingReport', true);
    let commonUtility = this_is_this.get('commonUtility');
    let url = 'admin/productstocktake';
    this_is_this.set('isLoading', true);
    let valueList = [];
    let productSearch = this_is_this.get('productSearch');
    commonUtility.HTTPGetSomething(url, {
      search: productSearch
    }).then(function (data) {
      this_is_this.set('lowStockResult', data.data);
      this_is_this.set('fetchingReport', false);
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      this_is_this.set('fetchingReport', false);
      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),
    periodList: Ember.A([]),
    periodListSelected: '',
    fetchingReport: false,
    productSearch: '',

    init() {},

    isLoading: false,

    refreshLowStock() {
      refreshLowStock(this);
    },

    selectedDate: 'predefined',
    disableDate: Ember.computed('selectedDate', function () {
      return this.get('selectedDate') !== 'date';
    }),
    actions: {
      findRecords() {
        refreshLowStock(this);
      },

      btnReview() {
        this.transitionToRoute('admin.stock-take-preview');
      },

      saveRow(row, identifier) {
        let productIDColumn = 'ProductID';
        let sizeGroupColumn = 'strStockFlowlnProductSizeGroup';
        let sizeIDColumn = 'strStockFlowlnProductSizeID';
        let productID = row.get(productIDColumn);
        let sizeGroup = row.get(sizeGroupColumn);
        let sizeID = row.get(sizeIDColumn);
        let newStock = row.get('NewStock');
        let currentStock = row.get('CurrentStock');
        let change = newStock - currentStock;

        if (change >= 0) {
          Ember.set(row, 'remark', 'MORE ' + change.toString() + ' ' + sizeID);
        } else {
          Ember.set(row, 'remark', 'LESS ' + Math.abs(change).toString() + ' ' + sizeID);
        }

        let commonUtility = this.get('commonUtility');

        if (identifier == 'NewStock') {
          let url = 'admin/stocktaketempsave';
          commonUtility.HTTPPostSomething(url, {
            productID: productID,
            newStock: newStock,
            sizeGroup: sizeGroup,
            sizeID: sizeID
          }).then(function (data) {}).catch(function (error) {
            let errorMessage = error.responseJSON.errors[0].error;

            if (!errorMessage) {
              errorMessage = '';
            }

            alert('Saving Error. ' + errorMessage);
          });
        }
      }

    },
    tableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'ProductID',
        width: '100px'
      }, {
        label: 'Product Name',
        valuePath: 'strProductDescription',
        resizable: true,
        width: '400px'
      }, {
        label: 'Size Type',
        valuePath: 'strStockFlowlnProductSizeID',
        width: '60px'
      }, {
        label: 'Current Stock',
        valuePath: 'CurrentStock',
        width: '100px'
      }, {
        label: 'New Qty',
        valuePath: 'NewStock',
        cellComponent: 'web-table-input',
        identifier: 'NewStock',
        classNames: 'columnBlue',
        width: '150px'
      }, {
        label: 'Remark',
        valuePath: 'remark',
        classNames: 'columnBlue',
        width: '200px'
      }]
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: new Date(),
      endDate: new Date()
    },
    currentData: {
      lowStock: false
    },
    lowStockResult: Ember.A([])
  });

  _exports.default = _default;
});