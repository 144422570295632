define("webcostume/models/product", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    productID: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    cost: (0, _attr.default)('number'),
    sellingPrice: (0, _attr.default)('number'),
    brandID: (0, _attr.default)('string'),
    productCategoryID: (0, _attr.default)('string'),
    active: (0, _attr.default)('number'),
    pictureName: (0, _attr.default)('string'),
    locationID: (0, _attr.default)('number'),
    sizeGroup: (0, _attr.default)('string'),
    remark: (0, _attr.default)('string'),
    wholesalePrice: (0, _attr.default)('number'),
    itemCode: (0, _attr.default)('string'),
    canOrder: (0, _attr.default)('string'),
    uniqueID: Ember.computed('productID', 'productCategoryID', function () {
      return this.get('productCategoryID') + this.get('productID');
    })
  });

  _exports.default = _default;
});