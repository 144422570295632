define("webcostume/routes/setup-bank/setup-bank-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      if (params.bankCode) {
        var record = this.store.peekRecord('bank', params.bankCode);

        if (record == null) {
          return this.store.findRecord('bank', params.bankCode);
        }

        return record;
      }
    },

    actions: {
      didTransition() {
        this.controllerFor('setup-bank').set('title', 'Setup Bank - Edit');
      }

    }
  });

  _exports.default = _default;
});