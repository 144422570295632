define("webcostume/templates/setup-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GaaS1iRl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"page-header\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[2,\" /.col-lg-12 \"],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[2,\" /.row \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-bank.hbs"
    }
  });

  _exports.default = _default;
});