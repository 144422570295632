define("webcostume/controllers/setup-location/list-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locationList: Ember.A([]),
    commonUtility: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),

    refreshLocation() {
      this.set('locationList', Ember.A([]));
      let commonUtility = this.get('commonUtility');
      let appController = this.get('applicationController');
      appController.openDialog('Fetching records...');
      let this_is_this = this;
      commonUtility.HTTPGetSomething('locations').then(function (data) {
        this_is_this.set('locationList', data.data);
        appController.closeDialog();
      }).catch(function (error) {
        console.log(error);
        alert('Unable to fetch location list');
      }).then(function (data) {
        appController.closeDialog();
      });
    },

    actions: {
      deleteThisRecord(record) {
        let appController = this.get('applicationController');
        let commonUtility = this.get('commonUtility');
        appController.openDialog('Deleting location...');
        let locationID = Ember.get(record, 'intLocationID');
        let this_is_this = this;
        commonUtility.HTTPPostSomething('location/delete', {
          locationID: locationID
        }).then(function (data) {
          alert('Record deleted.');
          this_is_this.refreshLocation();
        }).catch(function (data) {
          alert('Delete failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      }

    },
    tableData: {
      columns: [{
        label: 'Location ID',
        valuePath: 'intLocationID'
      }, {
        label: 'Description',
        valuePath: 'strLocationName'
      }, {
        label: 'Edit',
        valuePath: 'intLocationID',
        linkTo: 'setup-location.setup-location-input',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Edit'
      }, {
        label: 'Delete',
        valuePath: 'bankCode',
        cellComponent: 'web-table-scroll-button',
        linkDescription: 'Delete'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});