define("webcostume/templates/components/product-size-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EvMMFup7",
    "block": "{\"symbols\":[\"textBox\",\"index\",\"productSize\",\"&default\"],\"statements\":[[7,\"table\"],[11,\"style\",\"max-width:500px\"],[9],[0,\"\\n\\n    \\n\"],[4,\"if\",[[23,[\"arrProductSizeWithQty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n        \\n\"],[4,\"each\",[[23,[\"arrProductSizeWithQty\"]]],null,{\"statements\":[[0,\" \"],[7,\"td\"],[11,\"style\",\"font-size:12px\"],[9],[0,\" \\n   \"],[1,[22,3,[\"sizeID\"]],false],[0,\" (\"],[1,[22,3,[\"qty\"]],false],[0,\")\\n   \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[10],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n        \\n\"],[4,\"each\",[[23,[\"arrTextBox\"]]],null,{\"statements\":[[7,\"td\"],[9],[0,\"    \\n    \"],[1,[27,\"number-input\",null,[[\"class\",\"tabindex\",\"size\",\"placeholder\",\"focus-out\",\"value\"],[\"form-control form-control-inline  sale_formatnumber\",[27,\"add\",[[22,2,[]],10],null],1,[22,1,[\"sizeID\"]],[27,\"action\",[[22,0,[]],\"outOfFocus\"],null],[22,1,[\"qty\"]]]]],false],[0,\"    \\n\\n   \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n     Size\\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n     \"],[1,[27,\"number-input\",null,[[\"class\",\"disabled\"],[\"form-control form-control-inline form-control-number\",true]]],false],[0,\"    \\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[14,4],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/product-size-input.hbs"
    }
  });

  _exports.default = _default;
});