define("webcostume/routes/setup-location/list-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let listlocationContoller = this.controllerFor('setup-location.list-location');
      listlocationContoller.refreshLocation();
    }

  });

  _exports.default = _default;
});