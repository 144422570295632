define("webcostume/controllers/setup-location/setup-location-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    appController: Ember.inject.controller('application'),
    currLocation: null,
    editting: false,

    resetUser() {
      this.set('currLocation', this.get('store').createRecord('location', {
        locationID: 0,
        name: ''
      }));
    },

    init() {},

    loadingNecessaryData(locationID) {
      let commonUtility = this.get('commonUtility');
      let appController = this.get('appController'); // set here first time (during init) will set it to the correct model
      // everything's gonna be fine. 
      // :-)

      this.resetUser();
      let this_is_this = this;

      if (locationID == 'new') {
        this.set('editting', false);
      } else {
        appController.openDialog('Loading location...');
        commonUtility.HTTPGetSomething('location/load', {
          locationID: locationID
        }).then(function (data) {
          this_is_this.setLocation(data);
          this_is_this.set('editting', true);
          appController.closeDialog();
        }).catch(function (error) {
          console.log('Error while retrieving location');
          console.log(error);
          alert('Error while retrieving location');
          appController.closeDialog();
        });
      }
    },

    setLocation(data) {
      this.set('currLocation.locationID', data.locationID);
      this.set('currLocation.name', data.name);
    },

    actions: {
      btnSave_click() {
        let appController = this.get('appController');

        if (!this.validateFormData()) {
          return;
        }

        let currLocation = JSON.parse(JSON.stringify(this.get('currLocation')));
        let this_is_this = this;
        let commonUtility = this.get('commonUtility');
        appController.openDialog('Saving...');
        commonUtility.HTTPPostSomething('location/save', {
          edit: this.get('editting'),
          location: currLocation
        }).then(function (data) {
          this_is_this.set('currLocation.locationID', data.locationID);
          appController.closeDialog();
          alert('Save succesfully.');
          this_is_this.set('editting', true);
        }).catch(function (data) {
          alert('Save failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      },

      btnCancel_click() {
        let answer = confirm('Are  you sure you want to cancel?');

        if (answer) {
          this.transitionToRoute('setup-location.list-location');
        }
      }

    },

    validateFormData() {
      if (this.get('currLocation.name') === '') {
        alert('Name is empty');
        $('#actr_description').focus();
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});