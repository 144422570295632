define("webcostume/routes/admin/stock-take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition() {}

    }
  });

  _exports.default = _default;
});