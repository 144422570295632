define("webcostume/controllers/setup-productcategory/list-productcategory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    productCategoryList: Ember.A([]),
    commonUtility: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),

    refreshproductCategoryList() {
      this.set('productCategoryList', Ember.A([]));
      let commonUtility = this.get('commonUtility');
      let appController = this.get('applicationController');
      appController.openDialog('Fetching records...');
      let this_is_this = this;
      commonUtility.HTTPGetSomething('productcategory/list').then(function (data) {
        this_is_this.set('productCategoryList', data.data);
        appController.closeDialog();
      }).catch(function (error) {
        console.log(error);
        alert('Unable to fetch product category');
      }).then(function (data) {
        appController.closeDialog();
      });
    },

    actions: {
      deleteThisRecord(record) {
        let appController = this.get('applicationController');
        let commonUtility = this.get('commonUtility');
        console.log('Deleeting user');
        appController.openDialog('Deleting category...');
        let productCategoryID = Ember.get(record, 'strProductCategoryID');
        let this_is_this = this;
        commonUtility.HTTPPostSomething('productcategory/delete', {
          productCategoryID: productCategoryID
        }).then(function (data) {
          alert('Record deleted.');
          this_is_this.refreshproductCategoryList();
        }).catch(function (data) {
          alert('Delete failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      }

    },
    tableData: {
      columns: [{
        label: 'CategoryID',
        valuePath: 'strProductCategoryID'
      }, {
        label: 'Description',
        valuePath: 'strProductCategoryDescription'
      }, {
        label: 'Edit',
        valuePath: 'strProductCategoryID',
        linkTo: 'setup-productcategory.setup-productcategory-input',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Edit'
      }, {
        label: 'Delete',
        valuePath: 'bankCode',
        cellComponent: 'web-table-scroll-button',
        linkDescription: 'Delete'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});