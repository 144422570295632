define("webcostume/models/reserve", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reserveID: (0, _attr.default)('number'),
    referenceNo: (0, _attr.default)('string'),
    reserveDate: (0, _attr.default)('date'),
    customerID: (0, _attr.default)('string'),
    expired: (0, _attr.default)('date'),
    inputDate: (0, _attr.default)('date'),
    userID: (0, _attr.default)('number'),
    remark: (0, _attr.default)('string')
  });

  _exports.default = _default;
});