define("webcostume/routes/setup-productcategory/setup-productcategory-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let productCategoryID = params.productCategoryID;
      let setupProductCategoryController = this.controllerFor('setup-productcategory.setup-productcategory-input');
      setupProductCategoryController.loadingNecessaryData(productCategoryID);
    }

  });

  _exports.default = _default;
});