define("webcostume/routes/transaction-reserve/reserve-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition() {
        let reserveController = this.controllerFor('transaction-reserve.reserve-index');
        reserveController.myInit(); // console.log('didTransition happen');
        // if (exchangeController.get('saleID') != '') {
        //     exchangeController.loadDataFromSaleList();
        // }
      }

    }
  });

  _exports.default = _default;
});