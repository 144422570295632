define("webcostume/apai/entity/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.productfieldName = void 0;
  let productfieldName = {
    productID: 'strProductId',
    description: 'strProductDescription',
    cost: 'dclProductCost',
    sellingPrice: 'dclProductSellingPrice',
    brandID: 'strProductBrandId',
    productCategoryID: 'strProductProductCategoryID',
    active: 'blnProductActive',
    pictureName: 'strProductPictureName',
    locationID: 'intProductLocationID',
    sizeGroup: 'strProductProductSizeGroup',
    remark: 'strProductRemark',
    wholesalePrice: 'decProductWholesalePrice',
    itemCode: 'strProductItemCode',
    canOrder: 'strProductCanOrder'
  };
  _exports.productfieldName = productfieldName;
});