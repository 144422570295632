define("webcostume/templates/admin/stock-take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dzdEc4fg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card \"],[9],[0,\"\\n   \\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n        \\n        \\n\\n        \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n            \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[0,\"                \\n                Search \\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"insert-newline\"],[[23,[\"productSearch\"]],\"Search product\",[27,\"action\",[[22,0,[]],\"findRecords\"],null]]]],false],[0,\" \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Search\"],[3,\"action\",[[22,0,[]],\"findRecords\"]],[10],[0,\" \\n           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n             \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n          \\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n                \\n                \"],[7,\"button\"],[11,\"class\",\"btn btn-success\"],[9],[0,\"Review\"],[3,\"action\",[[22,0,[]],\"btnReview\"]],[10],[0,\" \\n\"],[0,\"           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n    \"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"isLoading\",\"columns\",\"customActions\",\"scrollHeight\"],[[23,[\"lowStockResult\"]],[23,[\"fetchingReport\"]],[23,[\"tableData\",\"columns\"]],[27,\"hash\",null,[[\"saveRow\"],[[27,\"action\",[[22,0,[]],\"saveRow\"],null]]]],\"60vh\"]]],false],[0,\" \\n    \\n    \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/admin/stock-take.hbs"
    }
  });

  _exports.default = _default;
});