define("webcostume/routes/transaction-sale/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel(transition) {
      let paymentController = this.controllerFor('transaction-sale.payment');
      let allowTransition = paymentController.get('allowedTransition'); // this must be set to allow to transition to paymentController
      // set it prior transition 

      if (!allowTransition) {
        this.transitionTo('transaction-sale');
      } else {
        this.set('allowTransition', false);
      }
    },

    actions: {
      didTransition() {
        let appControler = this.controllerFor('application');
        appControler.setRouteName('transaction-sale.payment');
        this.controllerFor('transaction-sale').set('title', 'Sale Payment - ');
      }

    }
  });

  _exports.default = _default;
});