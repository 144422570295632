define("webcostume/apai/gridline/transaction-sale-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    arrTextBox: [],
    qtyDescription: Ember.computed('arrTextBox', function () {
      let arrOfQty = [];
      this.arrTextBox.forEach(function (item, index) {
        if (item.qty == 0) {} else {
          arrOfQty.push(item.qty.toString() + ' [' + item.sizeID + ']');
        }
      });
      return arrOfQty.join(', ');
    }),
    sellingPrice: 0
  });

  _exports.default = _default;
});