define("webcostume/models/exchange", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    exchangeID: (0, _attr.default)('number'),
    referenceNo: (0, _attr.default)('string'),
    exchangeDate: (0, _attr.default)('date'),
    inputDate: (0, _attr.default)('date'),
    userID: (0, _attr.default)('number'),
    remark: (0, _attr.default)('string'),
    previousDiscount: (0, _attr.default)('number'),
    generation: (0, _attr.default)('number'),
    paymentMethod: (0, _attr.default)('string'),
    adjustment: (0, _attr.default)('number'),
    saleID: (0, _attr.default)('number')
  });

  _exports.default = _default;
});