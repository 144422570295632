define("webcostume/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    userName: Ember.computed('session.user', function () {
      console.log('Get user name');
      return this.get('session').get('user.userFullName');
    })
  });

  _exports.default = _default;
});