define("webcostume/routes/setup-user/setup-user-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let userID = params.userID;
      let setupUserInputController = this.controllerFor('setup-user.setup-user-input');
      setupUserInputController.loadingNecessaryData(userID);
    }

  });

  _exports.default = _default;
});