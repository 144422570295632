define("webcostume/templates/setup-customer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVBrXNuY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header bg-info\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"m-0 font-weight-bold\"],[11,\"style\",\"color:white\"],[9],[0,\"Search\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n\"],[1,[27,\"input\",null,[[\"type\",\"value\"],[\"text\",[23,[\"tableData\",\"searchField\"]]]]],false],[0,\" \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Search\"],[3,\"action\",[[22,0,[]],\"findRecords\"]],[10],[0,\"\\n\\n \\n\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header \"],[9],[0,\"\\n            \"],[4,\"link-to\",[\"setup-customer.setup-customer-new\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"New Customer \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n  \\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n\"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"scrollHeight\",\"columns\",\"selectAction\"],[[23,[\"model\"]],\"50vh\",[23,[\"tableData\",\"columns\"]],[27,\"action\",[[22,0,[]],\"deleteThisRecord\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-customer/index.hbs"
    }
  });

  _exports.default = _default;
});