define("webcostume/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel(transition) {
      let session = this.get('session');
      session.isLogin().then(result => {
        if (!result) {
          this.transitionTo('login');
        }
      });
    },

    afterModel() {
      hendri();
    }

  });

  _exports.default = _default;
});