define("webcostume/apai/entity/sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.salefieldName = void 0;
  let salefieldName = {
    pricing: 'strSalePricing',
    includeGST: 'blnSaleIncludeGST',
    userID: 'intSalePHUserID',
    dueDate: 'dteSaleDueDate',
    inputDate: 'dteSaleInputDate',
    GST: 'dclSaleGST',
    paymentTerm: 'strSalePaymentTerm',
    paid: 'blnSalePaid',
    customerID: 'strSaleCustomerID',
    invoiceNo: 'strSaleInvoiceNo',
    saleDate: 'dteSaleDate',
    saleID: 'intSaleID',
    discountAmount: 'decSaleDiscount',
    saleType: 'strSaleType',
    remark: 'strSaleRemark'
  };
  _exports.salefieldName = salefieldName;
});