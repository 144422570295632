define("webcostume/controllers/setup-user/setup-user-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    appController: Ember.inject.controller('application'),
    currPHUser: null,
    editting: Ember.computed('currPHUser.userID', function () {
      return this.get('currPHUser.userID') !== 0;
    }),

    resetUser() {
      this.set('currPHUser', this.get('store').createRecord('phuser', {
        userID: 0,
        userName: '',
        fullName: '',
        password: ''
      }));
    },

    init() {},

    loadingNecessaryData(userID) {
      let commonUtility = this.get('commonUtility');
      let this_is_this = this;
      let appController = this.get('appController'); // set here first time (during init) will set it to the correct model
      // everything's gonna be fine. 
      // :-)

      this.resetUser();

      if (userID == 'new') {} else {
        appController.openDialog('Loading user...');
        commonUtility.HTTPGetSomething('user/load', {
          userID: userID
        }).then(function (data) {
          this_is_this.setUserData(data);
          appController.closeDialog();
        }).catch(function (error) {
          console.log('Error while retrieving user');
          console.log(error);
          alert('Error while retrieving user');
          appController.closeDialog();
        });
      }
    },

    setUserData(data) {
      this.set('currPHUser.userID', data.userID);
      this.set('currPHUser.userName', data.userName);
      this.set('currPHUser.fullName', data.fullName);
    },

    actions: {
      btnSave_click() {
        let appController = this.get('appController');

        if (!this.validateFormData()) {
          return;
        }

        let currPHUser = JSON.parse(JSON.stringify(this.get('currPHUser')));
        let hashedPassword = '';

        if (currPHUser.password !== '') {
          var shaObj = new jsSHA("SHA-512", "TEXT");
          shaObj.update(currPHUser.password);
          hashedPassword = shaObj.getHash("B64");
          this.set('currPHUser.password', '');
          this.set('currentData.confirmPassword', '');
        }

        let commonUtility = this.get('commonUtility');
        let this_is_this = this;
        appController.openDialog('Saving...');
        commonUtility.HTTPPostSomething('user/save', {
          hashedPassword: hashedPassword,
          edit: this.get('editting'),
          user: currPHUser
        }).then(function (data) {
          this_is_this.set('currPHUser.userID', data.userID);
          appController.closeDialog();
          alert('Save succesfully.');
        }).catch(function (data) {
          alert('Save failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      },

      btnCancel_click() {
        let answer = confirm('Are  you sure you want to cancel?');

        if (answer) {
          this.transitionToRoute('setup-user.listuser');
        }
      }

    },

    validateFormData() {
      if (this.get('currPHUser.userName') === '') {
        alert('User Name is empty');
        $('#actr_username').focus();
        return false;
      }

      if (this.get('currPHUser.userName').indexOf(' ') >= 0) {
        alert('User Name cannot contains space');
        $('#actr_username').focus();
        return false;
      }

      if (this.get('currPHUser.fullName') === '') {
        alert('Full Name is empty');
        $('#actr_fullname').focus();
        return false;
      }

      if (!this.get('editting')) {
        // new record must supply password
        if (this.get('currPHUser.password') === '') {
          alert('Password is empty');
          $('#actr_password').focus();
          return false;
        }
      }

      if (this.get('currPHUser.password') !== this.get('currentData.confirmPassword')) {
        alert('Password and confirm password does not match');
        $('#actr_password').focus();
        return false;
      }

      return true;
    },

    currentData: {
      confirmPassword: ''
    }
  });

  _exports.default = _default;
});