define("webcostume/controllers/setup-user/userpermission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    permissionList: null,
    currentUserID: -1,

    refreshPermission(userID) {
      console.log('Refreshing.. permission');
      console.log(userID);
      this.set('currentUserID', userID);
      this.set('permissionList', Ember.A([]));
      let commonUtility = this.get('commonUtility');
      let appController = this.get('applicationController');
      appController.openDialog('Loading permission...');
      let this_is_this = this;
      commonUtility.HTTPGetSomething('permission/listpermissions', {
        userID: userID
      }).then(function (data) {
        console.log(data);
        this_is_this.set('permissionList', data.data);
        appController.closeDialog();
      }).catch(function (error) {
        console.log(error);
        alert('Unable to fetch user');
      }).then(function (data) {
        appController.closeDialog();
      });
    },

    actions: {
      chkPermissionChanged(webmenuid, permissiontypeid, event) {
        console.log(event);
        let commonUtility = this.get('commonUtility');
        let appController = this.get('applicationController');
        let userID = this.get('currentUserID');
        appController.openDialog('Saving permission...');
        let this_is_this = this;
        commonUtility.HTTPPostSomething('permission/savepermission', {
          userID: userID,
          webmenuid: webmenuid,
          permissiontypeid: permissiontypeid,
          havePermission: event.target.checked
        }).then(function (data) {
          console.log(data);
          appController.closeDialog();
        }).catch(function (error) {
          console.log(error);
          alert('Unable to save permission');
        }).then(function (data) {
          appController.closeDialog();
        });
      }

    }
  });

  _exports.default = _default;
});