define("webcostume/services/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    commonUtility: Ember.inject.service(),

    setSessionID(sessionID, user) {
      this.set('sessionID', sessionID);
      this.set('user', user);
    },

    logout() {
      this.set('sessionID', '');
      this.set('user', 'Guest');
    },

    isLogin() {
      if (this.get('sessionID') == '') {
        return new Promise(function (resolve, reject) {
          resolve(false);
        });
      }

      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('checklogin');
      let data = {
        sessionID: this.sessionID
      };
      let stillLogin = false;
      return $.ajax({
        type: 'POST',
        url: url,
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: "json"
      }).then(function () {
        return true;
      }).catch(function () {
        return false;
      });
    },

    sessionID: '',

    giveMeSessionID() {
      return this.sessionID;
    }

  });

  _exports.default = _default;
});