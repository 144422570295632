define("webcostume/routes/admin/stock-take-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      this.controllerFor('admin.stock-take-preview').refreshPreview();
    }

  });

  _exports.default = _default;
});