define("webcostume/components/product-size-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // set arrProductSize as base
  // ---> then it will need to generate a text box to be filled
  // ---> set the arrProductSizeWithQty as a header for remaining qty
  // 
  function resetWhenProductSizeHeaderChange(this_is_this) {
    console.log('resetWhenProductSizeHeaderChange');
    let arrTextBox = this_is_this.generateArrTextBox(this_is_this.arrProductSize);
    this_is_this.set('totalQty', 0);
    this_is_this.set('arrTextBox', arrTextBox);
  }

  var _default = Ember.Component.extend({
    commonUtility: Ember.inject.service(),
    entityProductSize: Ember.inject.service('entity-productsize'),

    didInsertElement() {
      console.log('product size did insert element');
      this.set('myself', this);
    },

    itemCode: null,
    myself: null,

    // set this so that the parent could have the handler
    clearAll() {
      this.set('arrProductSize', []);
      this.set('arrProductSizeWithQty', []);
      resetWhenProductSizeHeaderChange(this);
    },

    // sizeGroup refer to the size of the group like 'FS', 'Letter', 'Number'
    loadSizeGroup(sizeGroup) {
      let productSize = this.get('entityProductSize');
      let myPromise = productSize.getSizes(sizeGroup);
      let this_is_this = this;
      let myPromiseAgain = myPromise.then(data => {
        this_is_this.set('arrProductSize', data);
        return data;
      }).then(again => {
        console.log('again');
      });
      return myPromiseAgain;
    },

    // arrRemaingQty must fulfil 
    // be an array of  {sizeID : 'sizeID', qty : 999}    
    loadRemainingQtyOffline(sizeGroup, arrRemaingQty) {
      let arrRemaingQtyOutsize = arrRemaingQty;
      let this_is_this = this;
      let myPromise = this.loadSizeGroup(sizeGroup);
      myPromise.then(data => {
        console.log('after loadSize Group');
        resetWhenProductSizeHeaderChange(this_is_this);
        console.log('after loadSize Group');
        let arrProductSizeWithQty = Ember.A([]);
        this_is_this.get('arrProductSize').forEach(function (item, index) {
          let sizeID = item.strProductSizeID;
          let qty = 0;
          let reserve = 0;

          for (let i = 0; i < arrRemaingQtyOutsize.length; i++) {
            if (arrRemaingQtyOutsize[i].sizeID.toUpperCase() == sizeID.toUpperCase()) {
              qty = arrRemaingQtyOutsize[i].qty;
              break;
            }
          }

          arrProductSizeWithQty.pushObject(Ember.Object.create({
            sizeID: sizeID,
            qty: qty
          }));
        });
        this_is_this.set('arrProductSizeWithQty', arrProductSizeWithQty);
      });
    },

    loadRemainingQty(itemCode) {
      //console.log(itemCode);
      this.set('itemCode', itemCode);
      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('stockchecker', {
        itemcode: itemCode
      });
      let headers = commonUtility.giveMeHeader();
      let this_is_this = this;
      $.ajax({
        type: 'GET',
        dataType: "json",
        headers: headers,
        url: url,
        success: function (data) {
          let arrProductSizeWithQty = Ember.A([]);
          this_is_this.get('arrProductSize').forEach(function (item, index) {
            let sizeID = item.strProductSizeID;
            let stock = 0;
            let reserve = 0;

            for (let i = 0; i < data.length; i++) {
              if (data[i].strStockFlowlnProductSizeID.toUpperCase() == sizeID.toUpperCase()) {
                stock = data[i].CurrentStock;
                reserve = data[i].CurrentReservation;
                break;
              }
            }

            arrProductSizeWithQty.pushObject(Ember.Object.create({
              sizeID: sizeID,
              qty: stock - reserve
            }));
          });
          this_is_this.set('arrProductSizeWithQty', arrProductSizeWithQty);
        }
      }).catch(function (error) {
        let errorMessage = error.responseJSON.errors[0].error;

        if (!errorMessage) {
          errorMessage = '';
        }

        alert('Loading Error. ' + errorMessage);
      });
    },

    arrProductSizeWithQty: Ember.A([]),

    generateArrTextBox(arrProductSize) {
      let arrTextBox = [];
      arrProductSize.forEach(function (item, index) {
        arrTextBox.addObject(Ember.Object.create({
          qty: 0,
          sizeID: item.strProductSizeID
        }));
      });
      return arrTextBox;
    },

    getQtyDescription(arrTextBox) {
      if (!arrTextBox) {
        arrTextBox = this.arrTextBox;
      }

      let arrOfQty = [];
      arrTextBox.forEach(function (item, index) {
        if (item.qty == 0) {} else {
          arrOfQty.push(item.qty.toString() + ' [' + item.sizeID + ']');
        }
      });
      return arrOfQty.join(', ');
    },

    arrProductSize: null,
    onQtyChanged: null,
    arrTextBox: [],
    totalQty: 0,
    productSizeChanged: null,
    // changed this variable to trigger recreation of arrTextBox
    arrProductSizeChanged: Ember.observer('productSizeChanged', function () {
      this.set('arrProductSizeWithQty', []);
      let arrTextBox = this.generateArrTextBox(this.arrProductSize);
      this.set('totalQty', 0);
      this.set('arrTextBox', arrTextBox);
    }),

    getTotalQty(arrTextBox) {
      if (!arrTextBox) {
        arrTextBox = this.arrTextBox;
      }

      let totalQty = 0;
      arrTextBox.forEach(function (item, index) {
        totalQty += numeral(item.qty).value();
      });
      return totalQty;
    },

    actions: {
      outOfFocus(event) {
        let totalQty = 0;
        this.arrTextBox.forEach(function (item, index) {
          totalQty += numeral(item.qty).value();
        });

        if (totalQty != this.totalQty) {
          this.set('totalQty', totalQty);

          if (this.onQtyChanged != null) {
            this.onQtyChanged(totalQty);
          }
        }
      }

    },

    checkEnoughQty() {
      let this_is_this = this;
      console.log('check enough qty');
      let answer = this.arrTextBox.every(function (item, index) {
        let itemProductsizeWithQty = this_is_this.get('arrProductSizeWithQty').objectAt(index); //  console.log(item);
        //  console.log(numeral(item.qty).value());
        //  console.log(itemProductsizeWithQty.qty);            
        //  console.log(numeral(item.qty).value() <= itemProductsizeWithQty.qty); 
        //  console.log(numeral(item.qty).value() <= itemProductsizeWithQty.qty || numeral(itemProductsizeWithQty.qty).value() === 0); 
        // if key in zero, then no need to check if the qty is enough

        return numeral(item.qty).value() <= itemProductsizeWithQty.qty || numeral(item.qty).value() === 0;
      });
      return answer;
    }

  });

  _exports.default = _default;
});