define("webcostume/controllers/setup-customer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),
    actions: {
      findRecords() {
        this.send('refreshData');
      },

      deleteThisRecord(record) {
        let appController = this.get('appController');
        let bankRecord = this.store.peekRecord('customer', record.get('id'));
        let meparent = this;
        appController.openDialog('deleting record...');

        if (bankRecord) {
          bankRecord.destroyRecord().then(results => {
            meparent.send('findRecords');
            alert('Record deleted succesfully');
            appController.closeDialog();
          }).catch(reason => {
            bankRecord.rollbackAttributes();
            alert('Delete failed.' + reason.errors[0].reason);
            appController.closeDialog();
          });
        } else {
          alert('Cannot find bank record');
        }
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Remark',
        valuePath: 'remark'
      }, {
        label: 'Blacklisted',
        valuePath: 'blacklistedString'
      }, {
        label: 'Edit',
        valuePath: 'id',
        linkTo: 'setup-customer.setup-customer-input',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Edit'
      }, {
        label: 'Delete',
        valuePath: 'id',
        cellComponent: 'web-table-scroll-button',
        linkDescription: 'Delete'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});