define("webcostume/router", ["exports", "webcostume/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('setup-bank', function () {
      this.route('setup-bank-input', {
        path: '/setup-bank-input/:bankCode'
      });
      this.route('setup-bank-new', {
        path: '/new'
      });
    });
    this.route('setup-customer', function () {
      this.route('setup-customer-input', {
        path: '/setup-customer-input/:customerID'
      });
      this.route('setup-customer-new', {
        path: '/new'
      });
      this.route('setup-customer-info', {
        path: '/setup-customer-info/:customerID'
      });
    });
    this.route('transaction-sale', function () {
      this.route('payment');
      this.route('sale-list');
      this.route('sale-void-history');
    });
    this.route('login');
    this.route('report', function () {
      this.route('report-moneymovement');
      this.route('report-unpaid-customer');
      this.route('report-stockflow');
      this.route('report-productsummary');
      this.route('report-lowstock');
    });
    this.route('admin-stockin', function () {
      this.route('stockin-list');
    });
    this.route('setup-product', function () {
      this.route('setup-product-input', {
        path: '/setup-product-input/:productID'
      });
    });
    this.route('admin', function () {
      this.route('synctowoocommerce');
      this.route('stock-take');
      this.route('stock-take-preview');
      this.route('stock-take-history');
    });
    this.route('transaction-exchange', function () {
      this.route('exchange-list');
    });
    this.route('setup-permission');
    this.route('setup-user', function () {
      this.route('listuser');
      this.route('userpermission', {
        path: '/userpermission/:userID'
      });
      this.route('setup-user-input', {
        path: '/setup-user-input/:userID'
      });
    });
    this.route('transaction-reserve', function () {
      this.route('reserve-index');
      this.route('reserve-list');
    });
    this.route('setup-productcategory', function () {
      this.route('list-productcategory');
      this.route('setup-productcategory-input', {
        path: '/setup-productcategory-input/:productCategoryID'
      });
    });
    this.route('setup-location', function () {
      this.route('list-location');
      this.route('setup-location-input', {
        path: '/setup-location-input/:locationID'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});