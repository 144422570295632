define("webcostume/apai/entity/bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bankfieldName = void 0;
  let bankfieldName = {
    bankCode: 'strBankCode',
    bankName: 'strBankName'
  };
  _exports.bankfieldName = bankfieldName;
});