define("webcostume/templates/setup-location/list-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xDTT7Zcj",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"link-to\",[\"setup-location.setup-location-input\",\"new\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"New Location\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"columns\",\"selectAction\"],[[23,[\"locationList\"]],[23,[\"tableData\",\"columns\"]],[27,\"action\",[[22,0,[]],\"deleteThisRecord\"],null]]]],false],[0,\"\\n\\n \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-location/list-location.hbs"
    }
  });

  _exports.default = _default;
});