define("webcostume/templates/components/web-table-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sEZ3a4kp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"class\",\"andersonthumbnail\"],[12,\"src\",[28,[[21,\"imageUrl\"],\"?itemCode=\",[21,\"rawValue\"],\"&sessionid=\",[21,\"sessionID\"],\"&random=\",[21,\"randomizeValue\"]]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-thumbnail.hbs"
    }
  });

  _exports.default = _default;
});