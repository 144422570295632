define("webcostume/components/web-table-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setEditable() {
        let identifier = this.get('column.identifier');
        this.set('row.identifier', identifier);
      },

      saveEditable() {
        let identifier = this.get('column.identifier');
        this.get('tableActions.customActions.saveRow')(this.get('row'), identifier);
        this.set('row.identifier', '');
      }

    }
  });

  _exports.default = _default;
});