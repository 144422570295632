define("webcostume/services/entity-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    commonUtility: Ember.inject.service(),

    getInvoiceNo() {
      let host = this.get('commonUtility').get('host');
      let url = host + "/commonutility/getinvoiceno";
      let headers = this.get('commonUtility').giveMeHeader();
      return $.ajax({
        dataType: "json",
        url: url,
        headers: headers
      }).then(function (data) {
        return data.invoiceno;
      }).catch(function () {
        return '';
      });
    },

    searchProduct(keyword) {
      let host = this.get('commonUtility').get('host');
      let url = host + "/product/search/" + keyword;
      let headers = this.get('commonUtility').giveMeHeader();
      return $.ajax({
        dataType: "json",
        url: url,
        headers: headers
      }).then(function (data) {
        return data.data;
      }).catch(function (error) {
        return [];
      });
    }

  });

  _exports.default = _default;
});