define("webcostume/models/sale", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    pricing: (0, _attr.default)('string'),
    includeGST: (0, _attr.default)('bool'),
    userID: (0, _attr.default)('string'),
    dueDate: (0, _attr.default)('date'),
    inputDate: (0, _attr.default)('date'),
    GST: (0, _attr.default)('number'),
    paymentTerm: (0, _attr.default)('string'),
    paid: (0, _attr.default)('bool'),
    customerID: (0, _attr.default)('string'),
    invoiceNo: (0, _attr.default)('string'),
    saleDate: (0, _attr.default)('date'),
    saleID: (0, _attr.default)('number'),
    discountAmount: (0, _attr.default)('number'),
    saleType: (0, _attr.default)('string'),
    remark: (0, _attr.default)('string')
  });

  _exports.default = _default;
});