define("webcostume/routes/setup-customer/setup-customer-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'setup-customer/setup-customer-input',
    model: function () {
      return this.store.createRecord('customer');
    },
    actions: {
      didTransition() {
        this.controllerFor('setup-customer').set('title', 'Setup Customer - New');
      }

    }
  });

  _exports.default = _default;
});