define("webcostume/models/saleln", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    saleID: (0, _attr.default)('number'),
    salelnID: (0, _attr.default)('number'),
    productCost: (0, _attr.default)('number'),
    productCategoryID: (0, _attr.default)('string'),
    productID: (0, _attr.default)('string'),
    sizeGroup: (0, _attr.default)('string'),
    sizeID: (0, _attr.default)('string'),
    sellingPrice: (0, _attr.default)('number'),
    qty: (0, _attr.default)('number'),
    note: (0, _attr.default)('string'),
    group: (0, _attr.default)('number'),
    subTotal: Ember.computed('sellingPrice', 'qty', function () {
      return this.get('sellingPrice') * this.qty;
    })
  });

  _exports.default = _default;
});