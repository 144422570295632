define("webcostume/templates/components/web-table-scroll-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wj3P2ycR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-info\"],[9],[0,\"\\n    \\n\"],[4,\"if\",[[23,[\"column\",\"linkDescription\"]]],null,{\"statements\":[[0,\"       \"],[1,[23,[\"column\",\"linkDescription\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"       Select\\n\"]],\"parameters\":[]}],[0,\"    \\n    \"],[3,\"action\",[[22,0,[]],[23,[\"tableActions\",\"selectAction\"]],[23,[\"row\",\"content\"]],[23,[\"column\",\"linkDescription\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-scroll-button.hbs"
    }
  });

  _exports.default = _default;
});