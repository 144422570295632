define("webcostume/controllers/report/report-unpaid-customer", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function search(this_is_this) {
    let data = {
      customerID: this_is_this.get('current.customerID')
    };

    if (this_is_this.get('selectedDate') == 'date') {
      data.startDate = (0, _moment.default)(this_is_this.get('current.startDate')).format(officialDateFormat), data.endDate = (0, _moment.default)(this_is_this.get('current.endDate')).format(officialDateFormat);
    }

    console.log('search in report unpaid customer');
    console.log(data);
    let commonUtility = this_is_this.get('commonUtility');
    let url = commonUtility.giveMeFullUrl('report/unpaidcustomer');
    let headers = commonUtility.giveMeHeader();
    $.ajax({
      type: 'GET',
      dataType: "json",
      url: url,
      data: data,
      headers: headers,
      success: function (data) {
        //console.log('success');
        this_is_this.set('invoiceResult', data.data);
      }
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),

    init() {
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    selectedDate: 'all',

    search() {//search(this);
    },

    disableDate: Ember.computed('selectedDate', function () {
      return this.get('selectedDate') !== 'date';
    }),
    summaryTotal: Ember.computed('invoiceResult', function () {
      console.log('summaryTotal');
      let total = 0;
      this.get('invoiceResult').forEach(function (item) {
        total = numeral(item.decAmountOwed).add(total).value();
      });
      console.log(total);
      return numeral(total).format('0,0.00');
    }),
    actions: {
      modal_closeSearchCustomer_onHide() {
        this.set('openSearch', false);
      },

      selectRadioButton(event) {
        let target = event.target;
        let value = target.value;

        if (target.checked) {
          this.set('selectedDate', value);
        }
      },

      selectSale(source, item) {
        //console.log(item);
        let saleController = this.get('saleController');
        saleController.loadSale(item.intSaleID);
        this.transitionToRoute('transaction-sale.index');
      },

      btnClearCustomer_click() {
        this.set('current.customerID', '');
        this.set('current.customerName', '');
      },

      findRecords() {
        search(this);
      },

      customerKeyDown() {
        this.set('openSearch', true);
        let requestString = {
          search: this.get('current.customerName')
        };
        let dataPromise = this.store.query('customer', requestString).then(results => {
          this.set('searchResults', results);
        });
      },

      searchSelect(source, data) {
        this.set('openSearch', false);
        this.set('current.customerName', data.get('name'));
        this.set('current.customerID', data.get('customerID'));
        this.set('currCustomer', data);
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Customer Name',
        valuePath: 'strCustomerName'
      }, {
        label: 'Sale date',
        valuePath: 'dteSaleDate',
        cellComponent: 'web-table-date'
      }, {
        label: 'Invoice No',
        valuePath: 'strSaleInvoiceNo'
      }, {
        label: 'Amount Owed',
        valuePath: 'decAmountOwed'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    customerTableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'Remark',
        valuePath: 'remark'
      }, {
        label: 'Blacklisted',
        valuePath: 'isBlacklisted'
      }, {
        label: 'selection',
        valuePath: 'id',
        cellComponent: 'web-table-scroll-button'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: '',
      endDate: ''
    },
    openSearch: false,
    invoiceResult: Ember.A([])
  });

  _exports.default = _default;
});