define("webcostume/controllers/transaction-sale/index", ["exports", "webcostume/apai/gridline/transaction-sale-line", "moment"], function (_exports, _transactionSaleLine, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getCurrentPage(this_is_this) {
    return this_is_this.get('appController').currentPath;
  }

  function setCustomerTextBox(this_is_this, data) {
    this_is_this.currSale.set('customerID', data.get('customerID'));
    this_is_this.set('current.customerName', data.get('name'));
    this_is_this.set('current.address', data.get('address'));
    this_is_this.set('current.contactPerson', data.get('contactPerson'));
    this_is_this.set('currCustomer', data);
    refreshCustomerInfoList(this_is_this);
    this_is_this.set('blacklisted', data.get('isBlacklisted') == 1);
  }

  function refreshCustomerInfoList(this_is_this, customerInfoList) {
    let customerID = this_is_this.get('currSale.customerID');

    if (customerInfoList) {
      let custInfoList = Ember.A([]);
      customerInfoList.forEach(function (item, index) {
        let row = Ember.Object.create({
          id: item.intCustomerInfoID,
          contactPerson: item.strCustomerInfoContactPerson,
          contactNumber: item.strCustomerInfoContactNumber,
          email: item.strCustomerInfoEmail,
          ICNo: item.strCustomerInfoICNo,
          selected: item.selected
        });
        custInfoList.pushObject(row);
      });
      this_is_this.set('customerInfoList', custInfoList);
    } else {
      console.log('refreshCustomer LIst');
      console.log(customerID);
      this_is_this.store.query('customerinfo', {
        strCustomerInfoCustomerID: customerID
      }).then(data => {
        let custInfoList = Ember.A([]);
        data.forEach(function (item, index) {
          let row = Ember.Object.create({
            id: item.get('customerInfoID'),
            contactPerson: item.get('contactPerson'),
            contactNumber: item.get('contactNumber'),
            email: item.get('email'),
            ICNo: item.get('ICNo'),
            selected: true
          });
          custInfoList.pushObject(row);
        });
        this_is_this.set('customerInfoList', custInfoList);
      });
    }
  }

  function makeNewBeginning(this_is_this) {
    // if click new when it didn't set customer at all
    if (this_is_this.get('customerInfoList')) {
      this_is_this.get('customerInfoList').clear();
    }

    let arrTransactionLine = this_is_this.arrTransactionLine;
    arrTransactionLine.clear();
    this_is_this.set('currSale', this_is_this.get('store').createRecord('sale', {
      saleDate: new Date(),
      remark: ''
    }));
    this_is_this.set('currCustomer', null);
    this_is_this.set('current.customerName', '');
    this_is_this.set('current.address', '');
    this_is_this.set('current.contactPerson', '');
    this_is_this.set('currSale.saleDate', new Date());
    this_is_this.set('currSale.saleID', -1);
    this_is_this.set('currSale.customerID', '');
    let currSale = this_is_this.get('currSale');
    this_is_this.get('entitySale').getInvoiceNo().then(function (data) {
      currSale.set('invoiceNo', data);
    });
    this_is_this.set('current.salePricing', 'wholesale');
    this_is_this.set('current.discountAmount', 0); //this.set('current.saleType', basicSaleInfo.saleType);

    let paymentController = this_is_this.get('paymentController');
    paymentController.clearEverything();
    this_is_this.set('status', 'NEW');
    this_is_this.set('amendPaymentOnly', false);
  }

  function forceRefreshTransactionLine(this_is_this) {
    let omgThis = Ember.A([]);
    this_is_this.arrTransactionLine.forEach(function (item, index) {
      let transactionline = _transactionSaleLine.default.create({
        itemCode: item.itemCode,
        description: item.description,
        qtyDescription: item.qtyDescription,
        // make this a computed from arrTextBox
        sellingPrice: item.sellingPrice,
        totalQty: item.totalQty,
        subtotal: item.totalQty * item.sellingPrice,
        note: item.note,
        sizeGroup: item.sizeGroup,
        arrTextBox: item.arrTextBox,
        data: item.data
      });

      omgThis.addObject(transactionline);
    });
    this_is_this.set('arrTransactionLine', omgThis);
  }

  function schedulePromise(word, this_is_this) {
    let itemCode = word.substr(0, 6);
    let sizeID = word.substr(6);
    this_is_this.get('queue').add(function () {
      return new Promise(function (resolve, reject) {
        let found = false;
        let added = false;
        let productSizeHandler = this_is_this.get('productSizeInputHandler'); // check whether inside the transaction line

        this_is_this.arrTransactionLine.forEach(function (item, index) {
          if (item.itemCode == itemCode) {
            console.log('sucess in locating item code');
            item.arrTextBox.forEach(function (textBoxItem, textBoxindex) {
              if (textBoxItem.sizeID == sizeID) {
                textBoxItem.set('qty', numeral(textBoxItem.get('qty')).value() + 1);
                let totalQty = productSizeHandler.getTotalQty(item.arrTextBox);
                item.set('qtyDescription', productSizeHandler.getQtyDescription(item.arrTextBox));
                item.set('totalQty', totalQty);
                item.set('subtotal', totalQty * item.sellingPrice);
                added = true; // console.log(item);
              }
            });
            found = true;
          }
        }); //console.log('not found');

        if (!found) {
          let url = this_is_this.get('commonUtility').giveMeFullUrl('product/' + itemCode);
          let headers = this_is_this.get('commonUtility').giveMeHeader();
          let ajaxResult = $.ajax({
            type: 'GET',
            url: url,
            headers: headers,
            async: false
          });

          if (ajaxResult.status == 0) {
            resolve();
            return;
          }

          if (ajaxResult.responseJSON.data) {
            let productObject = ajaxResult.responseJSON.data;
            let price = productObject['decProductWholesalePrice'];

            if (this_is_this.get('current.salePricing').toLowerCase() == 'retail') {
              price = productObject['dclProductSellingPrice'];
            }

            let sizeGroup = productObject['strProductProductSizeGroup'];
            productObject.sizeGroup = sizeGroup; // for bridging 

            let productSize = this_is_this.get('entityProductSize');
            let myPromise = productSize.getSizes(sizeGroup);
            myPromise.then(function (arrSize) {
              let arrTextBox = productSizeHandler.generateArrTextBox(arrSize); // incrementone 

              let foundTextBoxSize = false;
              arrTextBox.forEach(function (textBoxItem, textBoxindex) {
                if (textBoxItem.sizeID == sizeID) {
                  textBoxItem.set('qty', numeral(textBoxItem.get('qty')).value() + 1);
                  foundTextBoxSize = true;
                }
              });

              if (foundTextBoxSize) {
                let transactionline = _transactionSaleLine.default.create({
                  itemCode: itemCode,
                  description: productObject['strProductDescription'],
                  qtyDescription: productSizeHandler.getQtyDescription(arrTextBox),
                  // make this a computed from arrTextBox
                  sellingPrice: price,
                  totalQty: productSizeHandler.getTotalQty(arrTextBox),
                  subtotal: price,
                  note: '',
                  sizeGroup: sizeGroup,
                  arrTextBox: arrTextBox,
                  data: productObject
                });

                this_is_this.get('arrTransactionLine').pushObject(transactionline);
              }

              resolve();
            });
          } else {
            resolve();
            return;
          }
        } else {
          // if (added)
          //     forceRefreshTransactionLine(this_is_this);
          resolve();
        }
      });
    });
  }

  function validateForm(this_is_this) {
    if (!this_is_this.get('currCustomer')) {
      alert('Please select a customer.');
      $('#actr_customerName').focus();
      return false;
    } else if (!this_is_this.arrTransactionLine || this_is_this.arrTransactionLine.length == 0) {
      alert('There is no sale line');
      return false;
    } else if (numeral(this_is_this.get('current.grandsubTotal')).value() < 0) {
      alert('Grand total is less than 0.');
      return false;
    }

    return true;
  }

  function getTotal(arrTransactionLine) {
    let total = 0;
    arrTransactionLine.forEach(function (item, index) {
      total += numeral(item.subtotal).value();
    });
    return total;
  }

  function setLineItem(this_is_madness, data, productSize, existing, productSizeInputHandler, transactionLine) {
    console.log('set Line Item');
    console.log(data);
    console.log(data.sizeGroup);
    console.log(data['sizeGroup']);
    let myPromise = productSize.getSizes(data['sizeGroup']);
    let outsideData = data;
    myPromise.then(data => {
      console.log('my promise data on set line item');
      console.log(data);
      this_is_madness.set('arrProductSize', data);

      if (!existing) {
        this_is_madness.set('productSizeChanged', new Date()); // here to reset arrTextBox 
      } else {
        // no reset needed cos we are setting it.
        productSizeInputHandler.set('arrTextBox', transactionLine.arrTextBox);
        this_is_madness.set('current.currentLineTotalQty', productSizeInputHandler.getTotalQty());
      } // call function here to load product size 


      productSizeInputHandler.loadRemainingQty(outsideData['strProductItemCode']);
    });
    this_is_madness.set('currProduct', data);
    this_is_madness.set('current.itemCode', data['strProductItemCode']);
    this_is_madness.set('current.productDescription', data['strProductDescription']);

    if (!existing) {
      this_is_madness.set('current.currentLineTotalQty', 0);

      if (this_is_madness.get('current.salePricing').toUpperCase() == 'RETAIL') {
        this_is_madness.set('currSaleln.sellingPrice', data['dclProductSellingPrice']);
      } else {
        this_is_madness.set('currSaleln.sellingPrice', data['decProductWholesalePrice']);
      }
    } else {
      this_is_madness.set('currSaleln.note', transactionLine.note);
      this_is_madness.set('currSaleln.sellingPrice', transactionLine.sellingPrice);
    }
  }

  function clearLineItem(this_is_madness) {
    this_is_madness.set('arrProductSize', []);
    this_is_madness.set('productSizeChanged', new Date());
    this_is_madness.set('currProduct', null);
    this_is_madness.set('current.itemCode', '');
    this_is_madness.set('current.productDescription', '');
    this_is_madness.set('currSaleln.sellingPrice', 0);
    this_is_madness.set('current.currentLineTotalQty', 0);
    this_is_madness.set('currSaleln.note', '');
  }

  var _default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),
    webtreepermission: Ember.inject.service('webtreepermission'),
    paymentController: Ember.inject.controller('transaction-sale.payment'),
    entitySale: Ember.inject.service('entity-sale'),
    entityProductSize: Ember.inject.service('entity-productsize'),
    servicePHSetting: Ember.inject.service('phsetting'),
    commonUtility: Ember.inject.service(),
    session: Ember.inject.service(),
    webTableSalelnHandler: null,
    queue: null,

    init() {
      var maxConcurrent = 1;
      this.set('queue', new Queue(maxConcurrent));
      this.set('currSale', this.get('store').createRecord('sale', {
        saleDate: new Date(),
        remark: ''
      }));
      this.set('currSaleln', this.get('store').createRecord('saleln', {
        note: ''
      })); //this.set('currProduct', this.get('store').createRecord('product'));

      this.set('current.salePricing', 'wholesale');
    },

    myInitDone: false,
    blacklisted: false,

    myInit() {
      if (!this.get('myInitDone')) {
        let currSale = this.get('currSale');
        this.get('entitySale').getInvoiceNo().then(function (data) {
          currSale.set('invoiceNo', data);
        }); // to kickstart the init , move this to init place
        // let productSize = this.get('entityProductSize');

        let service = this.get('servicePHSetting');
        this.set('myInitDone', true);
      }
    },

    disabledControl: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED' || this.get('status').toUpperCase() == 'EDIT';
    }),
    disabledControlExceptEdit: Ember.computed('status', function () {
      return this.get('status').toUpperCase() == 'SAVED';
    }),

    refreshCustomerInfoList() {
      refreshCustomerInfoList(this);
    },

    refreshCustomer() {
      let customerID = this.get('currSale.customerID');
      if (!customerID) return;
      this.get('store').findRecord('customer', customerID).then(data => {
        console.log(data);
        this.set('current.customerName', data.get('name'));
        this.set('current.address', data.get('address'));
        this.set('current.contactPerson', data.get('contactPerson'));
        this.set('currCustomer', data);
      });
    },

    makeNewBeginning() {
      makeNewBeginning(this);
    },

    status: 'NEW',
    savingStatus: 'idle',
    // TO Control locking of screen
    onStatusChanged: Ember.observer('savingStatus', function () {
      if (this.get('savingStatus').toUpperCase() == 'SAVING') {
        this.get('appController').openDialog();
      } else {
        this.get('appController').closeDialog();
      }
    }),
    currCustomer: null,
    productSizeChanged: null,
    // changed this variable to trigger event to productsizeinput component
    searchResults: Ember.A([]),
    // this is for searching customer, pump in here, you get your result 
    productSearchResults: Ember.A([]),
    // this is for searching product, pump in here, you get your result
    openSearch: false,
    // this is to control searching for customer
    openSearchProduct: false,
    // this is to control searching for product 
    arrProductSize: null,
    // this is for component inputcontrol
    arrTransactionLine: Ember.A([]),
    productSizeInputHandler: 'a',
    // onCustomerIDChanged: Ember.observer('currSale.customerID', function () {
    //     //console.log('Deal with it');
    // }
    // ),
    onTotalQtyPricechanged: Ember.observer('current.currentLineTotalQty', 'currSaleln.sellingPrice', function () {
      let subtotal = numeral(this.get('current.currentLineTotalQty')).value() * numeral(this.get('currSaleln.sellingPrice')).value();
      this.set('current.subTotal', numeral(subtotal).format('0,0.00'));
    }),
    actions: {
      btnTryTry() {
        alert('try try');
      },

      btnAmend_click() {
        if (!this.get('currSale.customerID')) return;
        this.get('appController').set('fromRoute', 'transaction-sale');
        this.transitionToRoute('setup-customer.setup-customer-input', this.get('currSale.customerID'));
      },

      btnAmendInfo_click() {
        if (!this.get('currSale.customerID')) return;
        this.get('appController').set('fromRoute', 'transaction-sale');
        this.transitionToRoute('setup-customer.setup-customer-info', this.get('currSale.customerID'));
      },

      btnVoidTransaction_click() {
        let webtreePermission = this.get('webtreepermission');

        if (!this.get('webtreepermission').allowDelete(getCurrentPage(this))) {
          alert('You are not allowed to void transaction ');
          return;
        }

        let answer = confirm('Are you sure you want to delete this transaction?');

        if (!answer) {
          console.log('phew...');
          return;
        }

        if (this.get('havePaymentAlready')) {
          let answer = confirm('There are existing payments. Are you sure you want to void?');

          if (!answer) {
            return;
          }
        }

        let commonUtility = this.get('commonUtility');
        let url = commonUtility.giveMeFullUrl('sale/void/' + this.get('currSale.saleID'));
        let headers = commonUtility.giveMeHeader();
        let this_is_this = this;
        let applicationController = this.get('appController');
        applicationController.openDialog('Deleting..');
        $.ajax({
          type: 'DELETE',
          url: url,
          headers: headers
        }).then(function (datasent) {
          makeNewBeginning(this_is_this);
          alert('Transaction voided.');
        }).catch(function (error) {
          console.log(error);
          alert('Transaction not voided.');
        }).then(function () {
          applicationController.closeDialog();
        });
      },

      btnNew_click() {
        if (this.get('status') == 'NEW') {
          let answer = confirm('Are you sure you want to clear everything?');

          if (!answer) {
            return;
          }
        }

        makeNewBeginning(this);
      },

      btnReceipt_click() {
        let commonUtility = this.get('commonUtility');
        let session = this.get('session');
        let headerToSend = {
          saleID: this.get('currSale.saleID'),
          sessionID: session.giveMeSessionID()
        };
        let url = commonUtility.giveMeFullUrl('sale/report', headerToSend);
        var myWindow = window.open(url);
        myWindow.document.addEventListener("DOMContentLoaded", event => {
          console.log('abc');
        });
        console.log(myWindow);
      },

      btnToggleStatus_click() {
        let status = this.get('status');

        if (status == 'NEW') {
          this.set('status', 'EDIT');
        } else if (status == 'EDIT') {
          this.set('status', 'SAVED');
        } else if (status == 'SAVED') {
          this.set('status', 'NEW');
        }
      },

      btnEdit_click(source) {
        let webtreePermission = this.get('webtreepermission');

        if (!this.get('webtreepermission').allowEdit(getCurrentPage(this))) {
          alert('You are not allowed to edit ');
          return;
        }

        this.set('status', 'EDIT');
      },

      btnSaveInvoice_click() {
        let webtreePermission = this.get('webtreepermission');

        if (!this.get('webtreepermission').allowSave(getCurrentPage(this))) {
          alert('You are not allowed to save');
          return;
        }

        if (!validateForm(this)) {
          return;
        }

        this.startSaving();
      },

      btnToggleSaleType_click() {
        if (this.get('status').toUpperCase() == 'NEW') {
          if (this.get('current.saleType') == 'POS') {
            if (!this.get('webtreepermission').allowInvoice(getCurrentPage(this))) {
              alert('You are not allowed to enter invoice page');
              return;
            }

            this.set('current.saleType', 'Invoice');
          } else {
            this.set('current.saleType', 'POS');
          }
        }
      },

      btnViewPayment_click(source) {
        let total = getTotal(this.arrTransactionLine);
        let discountAmount = numeral(this.get('current.discountAmount')).value();
        total = total - discountAmount;
        this.get('paymentController').initialPayment(this.get('current.customerName'), total, 'NETS', this.get('current.saleType'), this.get('currSale.saleDate'), false);
        this.get('paymentController').lockPayment();
        this.transitionToRoute('transaction-sale.payment');
      },

      btnAmendPaymentOnly_click() {
        let webtreePermission = this.get('webtreepermission');

        if (!this.get('webtreepermission').allowEdit(getCurrentPage(this))) {
          alert('You are not allowed to edit this page ');
          return;
        }

        this.set('amendPaymentOnly', true);
        let total = getTotal(this.arrTransactionLine);
        let discountAmount = numeral(this.get('current.discountAmount')).value();
        total = total - discountAmount;
        this.get('paymentController').initialPayment(this.get('current.customerName'), total, 'NETS', this.get('current.saleType'), this.get('currSale.saleDate'), true);
        this.transitionToRoute('transaction-sale.payment');
      },

      btnPayment_click(source) {
        let webtreePermission = this.get('webtreepermission');

        if (!this.get('webtreepermission').allowSave(getCurrentPage(this))) {
          alert('You are not allowed to save ');
          return;
        }

        if (!validateForm(this)) {
          return;
        }

        let total = getTotal(this.arrTransactionLine);
        let discountAmount = numeral(this.get('current.discountAmount')).value();
        total = total - discountAmount;
        this.get('paymentController').initialPayment(this.get('current.customerName'), total, source, this.get('current.saleType'), this.get('currSale.saleDate'), this.get('status').toUpperCase() == 'EDIT');
        this.transitionToRoute('transaction-sale.payment');
      },

      btnDeleteTransctionLine_click(itemToDelete) {
        //console.log('btnDeleteTransctionLine_click');
        let productSize = this.get('entityProductSize');
        let data = itemToDelete.data;
        setLineItem(this, data, productSize, true, this.productSizeInputHandler, itemToDelete);
        this.arrTransactionLine.removeObject(itemToDelete);
        return new Promise(function (resolve, reject) {
          resolve({
            success: true
          });
        });
      },

      qtyChanged(totalQty) {
        this.set('current.currentLineTotalQty', totalQty);
      },

      cboPricingChange(item) {
        this.set('current.salePricing', item.target.value);

        if (this.arrTransactionLine.length != 0) {
          let answer = confirm('Do you want to refresh the pricing?');

          if (answer) {
            let this_is_this = this;
            let omgThis = Ember.A([]);
            this.arrTransactionLine.forEach(function (item, index) {
              let sellingPrice = 0;

              if (this_is_this.get('current.salePricing').toUpperCase() == 'RETAIL') {
                sellingPrice = item.data['dclProductSellingPrice'];
              } else {
                sellingPrice = item.data['decProductWholesalePrice'];
              }

              item.set('sellingPrice', sellingPrice);
              item.set('subtotal', sellingPrice * item.totalQty); // this is to force refresh                                  
            }); //forceRefreshTransactionLine(this);
          }
        }

        if (this.get('current.salePricing').toUpperCase() == 'RETAIL') {
          console.log('in changing to retail');
          let appController = this.get('appController');
          let servicePHSetting = this.get('servicePHSetting');
          let this_is_this = this;
          appController.openDialog('Setting customer information');
          let currCustomer = this.get('currCustomer'); // only set customer id when customer is blank
          // https://track.excitethefuture.com/bz/show_bug.cgi?id=131

          if (currCustomer == null) {
            if (!servicePHSetting.fetchKeyAlready()) {
              servicePHSetting.fetchInfo().then(function () {
                let customerID = servicePHSetting.getKey('POSCUSTOMERID');
                this_is_this.store.findRecord('customer', customerID).then(function (data) {
                  setCustomerTextBox(this_is_this, data);
                  appController.closeDialog();
                });
              });
            } else {
              console.log('fetch key liao ');
              let customerID = servicePHSetting.getKey('POSCUSTOMERID');
              this.store.findRecord('customer', customerID).then(function (data) {
                setCustomerTextBox(this_is_this, data);
                appController.closeDialog();
              });
            }
          } // this.currSale.set('customerID', data.get('customerID'));
          // this.set('current.customerName', data.get('name'));
          // this.set('current.address', data.get('address'));
          // this.set('current.contactPerson', data.get('contactPerson'));
          // this.set('currCustomer', data);
          // refreshCustomerInfoList(this);

        }
      },

      modal_closeSearch_onHide() {
        this.set('openSearchProduct', false);
        $('#actrl_description').focus();
      },

      modal_closeSearchCustomer_onHide() {
        this.set('openSearch', false);
      },

      searchSelect(source, data) {
        console.log('search select');
        console.log(source);

        if (source == 'product') {
          this.set('openSearchProduct', false);
          let productSize = this.get('entityProductSize'); // bridging for delete when loading from sale

          data.sizeGroup = data['strProductProductSizeGroup'];
          setLineItem(this, data, productSize, false, this.productSizeInputHandler);
        } else {
          this.set('openSearch', false);
          setCustomerTextBox(this, data);
          $('#actrl_description').focus();
        }
      },

      itemOnFocus(event) {
        let itemCode = this.get('current.itemCode');
        this.set('oldItemCode', itemCode);
      },

      itemLostFocus(event) {
        let oldItemCode = this.get('oldItemCode');
        let currentItemCode = this.get('current.itemCode');
        let this_is_this = this;

        if (oldItemCode !== currentItemCode) {
          if (currentItemCode.length == 6) {
            let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
            let headers = this.get('commonUtility').giveMeHeader();
            let ajaxResult = $.ajax({
              type: 'GET',
              url: url,
              headers: headers
            }).then(function (datasent) {
              let data = datasent.data;

              if (data) {
                let productSizeHandler = this_is_this.get('productSizeInputHandler');
                let productSize = this_is_this.get('entityProductSize');
                data['sizeGroup'] = data['strProductProductSizeGroup'];
                setLineItem(this_is_this, datasent.data, productSize, false, productSizeHandler);
              } else {
                clearLineItem(this_is_this);
              }
            }).catch(function (error) {
              console.log(error);
              clearLineItem(this);
            });
          } else {
            // clear line item
            clearLineItem(this);
          }
        }
      },

      itemCodeKeyUp(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('current.itemCode', '');
          schedulePromise(word, this); //    let itemCode =  word.substr(0, 6);
          //    let size = word.substr(6);
          //    // check transaction line for item. 
          //    // if not, get the data and add it. 
          //    // ask productsizehandler to generate arrtextbox
          //     let url = this.get('commonUtility').giveMeFullUrl('product/' + currentItemCode);
          //     let headers = this.get('commonUtility').giveMeHeader();
          //     let ajaxResult = $.ajax({
          //             type : 'GET',
          //             url : url,                                         
          //             headers : headers,                         
          //     }).then(function(data) {
          //             console.log(data);
          //     }).catch(function(error) {
          //             console.log(error);
          //     });              
        }
      },

      productKeyDown(word, event) {
        let e = event.originalEvent;

        if (e.keyCode == 13) {
          this.set('openSearchProduct', true);
          let entitySaleService = this.get('entitySale');
          let keyword = this.get('current.productDescription');
          entitySaleService.searchProduct(keyword).then(results => {
            this.set('productSearchResults', results);
          });
        }
      },

      customerKeyDown() {
        this.set('openSearch', true);
        let requestString = {
          search: this.get('current.customerName')
        };
        let dataPromise = this.store.query('customer', requestString).then(results => {
          this.set('searchResults', results);
        });
      },

      btnAdd_click(event) {
        //console.log('btnAddClick');
        if (!this.get('currProduct')) {
          alert('There is no product.');
          $('#actrl_description').focus();
          return; // } else if (this.get('currSaleln.sellingPrice') == 0) {
          //     alert('Price is zero');
          //     $('#actrl_price').focus();
          //     return;
        } else if (this.get('current.currentLineTotalQty') == 0) {
          alert('Please enter some qty.');
          return;
        } else if (this.get('current.currentLineTotalQty') < 0) {
          let answer = confirm('You are entering min qty. Continue?');

          if (!answer) {
            return;
          }
        }

        let productSizeHandler = this.get('productSizeInputHandler');

        if (!productSizeHandler.checkEnoughQty()) {
          let answer = confirm('Some of qty(s) specified is/are more than stocked. Continue');

          if (!answer) {
            return;
          }
        }

        let transactionline = _transactionSaleLine.default.create({
          itemCode: this.get('current.itemCode'),
          description: this.get('current.productDescription'),
          qtyDescription: productSizeHandler.getQtyDescription(),
          // make this a computed from arrTextBox
          sellingPrice: this.get('currSaleln.sellingPrice'),
          totalQty: this.get('current.currentLineTotalQty'),
          subtotal: this.get('current.subTotal'),
          note: this.get('currSaleln.note'),
          sizeGroup: this.get('currProduct')['strProductProductSizeGroup'],
          arrTextBox: productSizeHandler.get('arrTextBox'),
          data: this.get('currProduct')
        });

        this.arrTransactionLine.pushObject(transactionline);
        clearLineItem(this);
        $('#actrl_description').focus();
      },

      btnCancelChanges_click() {
        let answer = confirm('Are you sure you want to revert?');

        if (!answer) {
          return;
        }

        this.loadSale(this.get('currSale.saleID'));
      }

    },
    transactionWebTable: {
      columns: [{
        label: 'Item Code',
        valuePath: 'itemCode',
        width: '80px'
      }, {
        label: 'Description',
        valuePath: 'description',
        resizable: true,
        width: '200px'
      }, {
        label: 'Qty',
        valuePath: 'qtyDescription',
        resizable: true,
        width: '150px'
      }, {
        label: 'Total Qty',
        valuePath: 'totalQty'
      }, {
        label: 'Selling Price',
        valuePath: 'sellingPrice'
      }, {
        label: 'Sub Total',
        valuePath: 'subtotal'
      }, {
        label: 'Note',
        valuePath: 'note'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    tableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name',
        width: '300px',
        resizable: true
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Address',
        valuePath: 'address',
        width: '200px'
      }, {
        label: 'Remark',
        valuePath: 'remark'
      }, {
        label: 'Blck List',
        valuePath: 'blacklistedString',
        width: '50px'
      }, {
        label: 'selection',
        valuePath: 'id',
        cellComponent: 'web-table-scroll-button'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    productTableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'UniqueID'
      }, {
        label: 'Thumbnail',
        valuePath: 'strProductItemCode',
        cellComponent: 'web-table-thumbnail'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription'
      }, {
        label: 'Location',
        valuePath: 'strLocationName'
      }, {
        label: 'Size Group',
        valuePath: 'strProductProductSizeGroup'
      }, {
        label: 'selection',
        valuePath: 'UniqueID',
        cellComponent: 'web-table-scroll-button'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      saleType: 'POS',
      discountAmount: 0,
      salePricing: 'wholesale',
      // wholesale or retail
      itemCode: '',
      customerName: '',
      contactPerson: '',
      address: '',
      autoGenerate: true,
      productDescription: '',
      subTotal: 0,
      currentLineTotalQty: 0,
      grandsubTotal: 0,
      grandGST: 0,
      grandTotal: 0
    },
    arrTransactionLineChanged: Ember.observer('arrTransactionLine.@each.subtotal', 'current.discountAmount', function () {
      let total = getTotal(this.arrTransactionLine);
      total = total - this.get('current.discountAmount');
      this.set('current.grandsubTotal', numeral(total).format('0,0.00')); // let servicePHSetting = this.get('servicePHSetting');
      // let GST =total * parseFloat(servicePHSetting.getKey('GST'));
      // this.set('current.grandGST', GST);
      // this.set('current.grandTotal', total + GST);
    }),
    currProduct: null,
    currSaleln: null,
    currSale: null,

    getCustomerInfo() {
      let selectedCustomerInfoList = [];
      this.customerInfoList.forEach(function (item, value) {
        if (item.get('selected')) {
          selectedCustomerInfoList.pushObject({
            customerInfoID: item.get('id')
          });
        }
      });
      return selectedCustomerInfoList;
    },

    // this is to send out to space to be saved
    getTransaction(originalStatus) {
      let transactLine = [];
      this.arrTransactionLine.forEach(function (item, value) {
        let qtyList = [];
        item.arrTextBox.forEach(function (itemTextBox, indexTextBox) {
          if (itemTextBox.qty > 0) {
            qtyList.pushObject({
              sizeID: itemTextBox.sizeID,
              qty: itemTextBox.qty
            });
          }
        });
        let currObject = {
          itemCode: item.itemCode,
          sellingPrice: item.sellingPrice,
          note: item.note,
          qtyList: qtyList //console.log(currObject);

        };
        transactLine.pushObject(currObject);
      });
      let intSaleID = -1;

      if (originalStatus != 'NEW') {
        intSaleID = this.get('currSale.saleID');
      }

      let data = {
        'version': '1.1',
        originalStatus: originalStatus,
        customer: this.get('currSale').get('customerID'),
        autoGenerate: this.get('current.autoGenerate'),
        saleDate: (0, _moment.default)(this.get('currSale.saleDate')).format('DD/MM/YYYY'),
        invoiceNo: this.get('currSale.invoiceNo'),
        saleID: intSaleID,
        salePricing: this.get('current.salePricing'),
        discountAmount: this.get('current.discountAmount'),
        saleType: this.get('current.saleType'),
        remark: this.get('currSale.remark'),
        transactLine: transactLine //console.log('get payment');
        //console.log(data);

      };
      return data;
    },

    startSaving() {
      let paymentController = this.get('paymentController');
      paymentController.set('paymentDone', false);
      let saleController = this;
      let originalStatus = this.get('status');
      this.set('savingStatus', 'saving');
      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('sale/save');
      let headers = commonUtility.giveMeHeader();
      let myPromiseToSaveYou = new Promise(function (resolve, reject) {
        //console.log('saleController promise');
        let instruction = 'NEW'; //console.log(originalStatus);

        if (originalStatus == 'EDIT') {
          instruction = 'EDITALL';
        } else if (saleController.get('amendPaymentOnly')) {
          instruction = 'EDITPAYMENT';
          saleController.set('amendPaymentOnly', false);
        }

        let data = {
          instruction: instruction,
          sale: saleController.getTransaction(originalStatus),
          payment: paymentController.getPayment(),
          customerinfo: saleController.getCustomerInfo()
        };
        saleController.set('havePaymentAlready', false);

        if (data.payment.length > 0) {
          saleController.set('havePaymentAlready', true);
        }

        $.ajax({
          type: 'POST',
          dataType: "json",
          headers: headers,
          url: url,
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(data),
          success: function (data) {
            //console.log(data);
            saleController.setSaved(data);
          }
        }).catch(function (error) {
          console.log(error);
          let errorMessage = error.responseJSON.errors[0].error;

          if (!errorMessage) {
            errorMessage = '';
          }

          alert('Saving Error. ' + errorMessage);
        }).then(function (finalData) {
          saleController.set('savingStatus', 'FAIL');
        });
        resolve();
      });
    },

    havePaymentAlready: false,
    // to control whether can edit invoice, have payment cannot edit invoice  
    amendPaymentOnly: false,

    setSaved(data) {
      this.set('status', 'SAVED');
      this.set('savingStatus', 'IDLE');
      this.set('currSale.saleID', data.saleID);
      console.log('setSaved');
      console.log(data);
      let paymentController = this.get('paymentController');
      if (data.paymentList) paymentController.setPaymentNo(data.paymentList);
    },

    loadReserve(intReserveID) {
      let applicationController = this.get('appController');
      applicationController.openDialog('Loading sales');
      let commonUtility = this.get('commonUtility');
      let url = 'reserve/sale/load';
      let data = {
        reserveID: intReserveID
      };
      let this_is_this = this;
      commonUtility.HTTPGetSomething(url, data).then(function (data) {
        console.log('start of getsomething in sale loadreserve');
        console.log(this_is_this);
        makeNewBeginning(this_is_this);
        this_is_this.set('currSale.customerID', data.customerInfo.strCustomerId);
        this_is_this.loadCustomerInfo(data.customerInfo);
        console.log('refreshing customer info list1');
        refreshCustomerInfoList(this_is_this);
        this_is_this.loadTransactionLine(data.transactionLines);
        this_is_this.set('status', 'NEW');
        applicationController.closeDialog();
      }).catch(function () {
        alert('Loading failed');
        applicationController.closeDialog();
      });
    },

    loadSale(intSaleID) {
      let applicationController = this.get('appController');
      applicationController.openDialog('Loading sales');
      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('sale/load');
      let data = {
        saleID: intSaleID
      };
      let this_is_this = this;
      let paymentController = this.get('paymentController');
      let headers = commonUtility.giveMeHeader();
      let saleID = intSaleID;
      let ajaxResult = $.ajax({
        type: 'GET',
        url: url,
        data: data,
        headers: headers,
        dataType: "json"
      }).then(function (data) {
        //console.log(data);
        this_is_this.loadBasicInfo(data.basicSaleInfo, data.customerInfo);
        this_is_this.loadTransactionLine(data.transactionLines);
        refreshCustomerInfoList(this_is_this, data.customerInfoList);
        this_is_this.set('status', 'SAVED');

        if (!data.paymentLines) {
          this_is_this.set('havePaymentAlready', false);
        } else {
          if (data.paymentLines.length == 0) {
            this_is_this.set('havePaymentAlready', false);
          } else {
            this_is_this.set('havePaymentAlready', true);
          }
        }

        paymentController.loadPaymentLine(data.paymentLines, saleID);
      }).catch(function (error) {
        console.log('loading error');
        console.log(error);
      }).then(function (data) {
        applicationController.closeDialog();
      });
    },

    loadCustomerInfo(customerInfo) {
      this.set('currCustomer', this.get('store').createRecord('customer'));
      this.set('currCustomer.customerID', customerInfo.strCustomerId);
      this.set('current.customerName', customerInfo.strCustomerName);
      this.set('current.address', customerInfo.strCustomerAddress);
      this.set('current.contactPerson', customerInfo.strCustomerContactPerson);
    },

    loadBasicInfo(basicSaleInfo, customerInfo) {
      //console.log('customerinfo')    ;
      //console.log(customerInfo)    ;
      this.set('currSale', this.get('store').createRecord('sale', {
        saleDate: new Date(),
        remark: ''
      }));
      this.loadCustomerInfo(customerInfo);
      this.set('currSale.saleDate', _moment.default.utc(basicSaleInfo.saleDateFormatted, 'DD/MM/YYYY').toDate());
      this.set('currSale.saleID', basicSaleInfo.saleID);
      this.set('currSale.customerID', basicSaleInfo.customerID);
      this.set('currSale.invoiceNo', basicSaleInfo.invoiceNo); //console.log('basicSaleInfo.invoiceNo');
      //console.log(basicSaleInfo.invoiceNo);

      this.set('current.salePricing', basicSaleInfo.pricing.toLowerCase());
      this.set('current.discountAmount', basicSaleInfo.discountAmount);
      this.set('current.saleType', basicSaleInfo.saleType);
      this.set('currSale.remark', basicSaleInfo.remark);
    },

    loadTransactionLine(transactionLines) {
      console.debug('load Transaction line');
      console.debug(transactionLines);
      let arrTransactionLine = this.arrTransactionLine;
      arrTransactionLine.clear();
      transactionLines.forEach(function (item, index) {
        //item.sellingPrice = item.decSalelnSellingPrice; // bridging for delete
        // cos when delete product size handler only accepts ember Object
        let theBridgingArrTextBox = Ember.A([]);
        item.arrTextBox.forEach(function (item, index) {
          theBridgingArrTextBox.addObject(Ember.Object.create({
            qty: item.qty,
            sizeID: item.sizeID
          }));
        });
        console.log('loadTransactionLine arrTextBox');
        console.log(item); // if blank, will set sizeGroup to null 

        if (item.strSalelnProductSizeStockSizeGroup) {
          item.sizeGroup = item.strSalelnProductSizeStockSizeGroup;
        }

        let transactionline = _transactionSaleLine.default.create({
          itemCode: item.strProductItemCode,
          description: item.strProductDescription,
          qtyDescription: item.qty,
          // make this a computed from arrTextBox
          sellingPrice: item.sellingPrice,
          totalQty: item.totalQty,
          subtotal: parseFloat(item.totalQty * item.sellingPrice).toFixed(2),
          note: item.note,
          sizeGroup: item.sizeGroup,
          arrTextBox: theBridgingArrTextBox,
          data: item
        });

        arrTransactionLine.pushObject(transactionline);
      });
    }

  });

  _exports.default = _default;
});