define("webcostume/controllers/setup-user/listuser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userList: Ember.A([]),
    commonUtility: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),

    refreshUserList() {
      this.set('userList', Ember.A([]));
      let commonUtility = this.get('commonUtility');
      let appController = this.get('applicationController');
      appController.openDialog('Fetching user...');
      let this_is_this = this;
      commonUtility.HTTPGetSomething('user/userlist').then(function (data) {
        this_is_this.set('userList', data.data);
        appController.closeDialog();
      }).catch(function (error) {
        console.log(error);
        alert('Unable to fetch user');
      }).then(function (data) {
        appController.closeDialog();
      });
    },

    actions: {
      deleteThisRecord(record) {
        let appController = this.get('applicationController');
        let commonUtility = this.get('commonUtility');
        console.log('Deleeting user');
        appController.openDialog('Deleting user...');
        let userID = Ember.get(record, 'intPHUserID');
        let this_is_this = this;
        commonUtility.HTTPPostSomething('user/delete', {
          userID: userID
        }).then(function (data) {
          alert('Record deleted.');
          this_is_this.refreshUserList();
        }).catch(function (data) {
          alert('Delete failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      }

    },
    tableData: {
      columns: [{
        label: 'User Name',
        valuePath: 'strPHUserName'
      }, {
        label: 'Full Name',
        valuePath: 'strPHUserFullName'
      }, {
        label: 'Permission',
        valuePath: 'intPHUserID',
        linkTo: 'setup-user.userpermission',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Set Permission'
      }, {
        label: 'Edit',
        valuePath: 'intPHUserID',
        linkTo: 'setup-user.setup-user-input',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Edit'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});