define("webcostume/templates/components/web-table-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d3I4H+bH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"rawValue\"]],1],null]],null,{\"statements\":[[0,\" \"],[7,\"span\"],[11,\"style\",\"color:red\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-check-circle\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-icon.hbs"
    }
  });

  _exports.default = _default;
});