define("webcostume/controllers/setup-product/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    commonUtility: Ember.inject.service('common-utility'),
    fetchingRecords: false,

    refreshData() {
      let commonUtility = this.get('commonUtility');
      let keyword = this.get('tableData.searchField');
      let this_is_this = this;
      this.set('fetchingRecords', true);
      commonUtility.HTTPGetSomething('product/search/' + keyword).then(function (data) {
        this_is_this.set('productList', data.data);
        this_is_this.set('fetchingRecords', false);
      }).catch(function (data) {
        console.log('Eror occured when fetching data');
        console.log(data);
        this_is_this.set('fetchingRecords', false);
      });
    },

    actions: {
      findRecords() {
        this.refreshData();
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [// {label : 'Product ID',
      //     valuePath : 'UniqueID'},
      {
        label: 'Thumbnail',
        valuePath: 'strProductItemCode',
        cellComponent: 'web-table-thumbnail'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription'
      }, {
        label: 'Location',
        valuePath: 'strLocationName'
      }, // {label : 'Size Group',
      //     valuePath : 'strProductProductSizeGroup'},
      {
        label: 'Retail',
        valuePath: 'dclProductSellingPrice',
        width: '80px'
      }, {
        label: 'W/S',
        valuePath: 'decProductWholesalePrice',
        width: '80px'
      }, // {label : 'Cost',
      // valuePath : 'dclProductCost'},
      {
        label: 'Stock',
        valuePath: 'StockInfo',
        width: '300px'
      }, {
        label: 'Remark',
        valuePath: 'strProductRemark'
      }, {
        label: 'Edit',
        valuePath: 'UniqueID',
        linkTo: 'setup-product.setup-product-input',
        cellComponent: 'web-table-linkto',
        linkDescription: 'Edit'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    }
  });

  _exports.default = _default;
});