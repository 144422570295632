define("webcostume/controllers/setup-bank/setup-bank-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    open: false,
    headerMessage: {
      message: 'Yahoo',
      showMessage: false,
      type: 'success'
    },
    openConfirm: false,
    actions: {
      transitionBack() {
        //console.log('trans');
        this.transitionToRoute('setup-bank');
      },

      cancelAction() {
        let answer = confirm('Are you sure you want to cancel?');
        if (answer) this.send('transitionBack');
      },

      submit() {
        var baba = this; //console.log(this.get('model').get('bankCode'));

        this.get('model').save().then(function (event) {
          alert('Save successfully');
          baba.send('transitionBack');
        }).catch(function (event) {
          alert('Save failed.');
          console.log(event);
          baba.set('headerMessage.showMessage', true);
          baba.set('headerMessage.type', 'error');
          baba.set('headerMessage.message', event.errors[0].reason);
        });
      }

    }
  });

  _exports.default = _default;
});