define("webcostume/routes/setup-bank/setup-bank-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      return this.store.createRecord('bank');
    },
    actions: {
      didTransition() {
        this.controllerFor('setup-bank').set('title', 'Setup Bank - New');
      }

    }
  });

  _exports.default = _default;
});