define("webcostume/helpers/send", ["exports", "ember-component-inbound-actions/helpers/send"], function (_exports, _send) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _send.default;
    }
  });
});