define("webcostume/controllers/setup-productcategory/setup-productcategory-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    appController: Ember.inject.controller('application'),
    currProductCategory: null,
    editting: false,

    resetUser() {
      this.set('currProductCategory', this.get('store').createRecord('productcategory', {
        productCategoryID: '',
        description: ''
      }));
    },

    init() {},

    loadingNecessaryData(productCategoryID) {
      let commonUtility = this.get('commonUtility');
      let appController = this.get('appController'); // set here first time (during init) will set it to the correct model
      // everything's gonna be fine. 
      // :-)

      this.resetUser();
      let this_is_this = this;

      if (productCategoryID == 'new') {
        this.set('editting', false);
      } else {
        appController.openDialog('Loading product category...');
        commonUtility.HTTPGetSomething('productcategory/load', {
          productCategoryID: productCategoryID
        }).then(function (data) {
          this_is_this.setProductCategory(data);
          this_is_this.set('editting', true);
          appController.closeDialog();
        }).catch(function (error) {
          console.log('Error while retrieving product category');
          console.log(error);
          alert('Error while retrieving product category');
          appController.closeDialog();
        });
      }
    },

    setProductCategory(data) {
      this.set('currProductCategory.productCategoryID', data.productCategoryID);
      this.set('currProductCategory.description', data.description);
    },

    actions: {
      btnSave_click() {
        let appController = this.get('appController');

        if (!this.validateFormData()) {
          return;
        }

        let currProductCategory = JSON.parse(JSON.stringify(this.get('currProductCategory')));
        let this_is_this = this;
        let commonUtility = this.get('commonUtility');
        appController.openDialog('Saving...');
        commonUtility.HTTPPostSomething('productcategory/save', {
          edit: this.get('editting'),
          productCategory: currProductCategory
        }).then(function (data) {
          this_is_this.set('currProductCategory.productCategoryID', data.productCategoryID);
          appController.closeDialog();
          alert('Save succesfully.');
          this_is_this.set('editting', true);
        }).catch(function (data) {
          alert('Save failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      },

      btnCancel_click() {
        let answer = confirm('Are  you sure you want to cancel?');

        if (answer) {
          this.transitionToRoute('setup-productcategory.list-productcategory');
        }
      }

    },

    validateFormData() {
      if (this.get('currProductCategory.productCategoryID') === '') {
        alert('ID is empty');
        $('#actr_categoryID').focus();
        return false;
      }

      if (this.get('currProductCategory.description') === '') {
        alert('Description is empty');
        $('#actr_description').focus();
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});