define("webcostume/controllers/admin/stock-take-preview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function refreshPreview(this_is_this, toExport) {
    this_is_this.set('fetchingReport', true);
    let commonUtility = this_is_this.get('commonUtility');
    let url = 'admin/stocktakepreview';
    this_is_this.set('isLoading', true);
    let valueList = [];
    commonUtility.HTTPGetSomething(url).then(function (data) {
      this_is_this.set('lowStockResult', data.data);
      this_is_this.set('fetchingReport', false);
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      this_is_this.set('fetchingReport', false);
      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),
    periodList: Ember.A([]),
    periodListSelected: '',
    fetchingReport: false,
    productSearch: '',

    init() {},

    isLoading: false,

    refreshPreview() {
      refreshPreview(this);
    },

    actions: {
      selectRadioButton(event) {
        let target = event.target;
        let value = target.value;

        if (target.checked) {
          this.set('selectedDate', value);
        }

        console.log(value);
      },

      btnConfirm() {
        let answer = confirm('Are you sure you want to commence this stock take?');

        if (!answer) {
          return;
        }

        let commonUtility = this.get('commonUtility');
        let url = 'admin/stocktakecommence';
        let this_is_this = this;
        commonUtility.HTTPPostSomething(url).then(function (data) {
          alert('Commenced succesfully.');
          this_is_this.transitionToRoute('admin.stock-take');
        }).catch(function (error) {
          let errorMessage = error.responseJSON.errors[0].error;

          if (!errorMessage) {
            errorMessage = '';
          }

          alert('Saving Error. ' + errorMessage);
        });
      }

    },
    tableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'ProductID',
        width: '100px'
      }, {
        label: 'Product Name',
        valuePath: 'strProductDescription',
        resizable: true,
        width: '400px'
      }, {
        label: 'Size Type',
        valuePath: 'strStockFlowlnProductSizeID',
        width: '60px'
      }, {
        label: 'Current Stock',
        valuePath: 'CurrentStock',
        width: '100px'
      }, {
        label: 'New Qty',
        valuePath: 'NewStock',
        identifier: 'NewStock',
        classNames: 'columnBlue',
        width: '150px'
      }, {
        label: 'Remark',
        valuePath: 'remark',
        classNames: 'columnBlue',
        width: '200px'
      }]
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: new Date(),
      endDate: new Date()
    },
    currentData: {
      lowStock: false
    },
    lowStockResult: Ember.A([])
  });

  _exports.default = _default;
});