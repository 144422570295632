define("webcostume/components/number-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    tagName: "input",
    type: "text",
    decimal: 0,

    didInsertElement() {
      //console.log('did INsert element'); 
      $('#' + this.elementId).autoNumeric('init', {
        decimalPlacesOverride: this.decimal
      });
    }

  });

  _exports.default = _default;
});