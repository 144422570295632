define("webcostume/routes/setup-location/setup-location-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let locationID = params.locationID;
      let stupLocationController = this.controllerFor('setup-location.setup-location-input');
      stupLocationController.loadingNecessaryData(locationID);
    }

  });

  _exports.default = _default;
});