define("webcostume/models/productsizestock", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = DS.Model.extend({
    productCategoryID: (0, _attr.default)('string'),
    productID: (0, _attr.default)('string'),
    productSizeID: (0, _attr.default)('string'),
    qty: (0, _attr.default)('number'),
    sizeGroup: (0, _attr.default)('string'),
    maxQty: (0, _attr.default)('number'),
    topUp: (0, _attr.default)('number'),
    reorderPoint: (0, _attr.default)('number')
  });

  _exports.default = _default;
});