define("webcostume/controllers/admin/stock-take-history", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function refreshPreview(this_is_this, toExport) {
    this_is_this.set('fetchingReport', true);
    let commonUtility = this_is_this.get('commonUtility');
    let url = 'admin/stocktakehistory';
    this_is_this.set('isLoading', true);
    let valueList = [];
    commonUtility.HTTPGetSomething(url).then(function (data) {
      this_is_this.set('stockTakeHistory', data.data);
      this_is_this.set('fetchingReport', false);
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      this_is_this.set('fetchingReport', false);
      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),
    periodList: Ember.A([]),
    periodListSelected: '',
    fetchingReport: false,
    productSearch: '',

    init() {},

    isLoading: false,

    refreshPreview() {
      refreshPreview(this);
    },

    actions: {
      selectRecord(row) {
        let this_is_this = this;
        this_is_this.set('fetchingReportln', true);
        let commonUtility = this_is_this.get('commonUtility');
        let url = 'admin/stocktakehistoryln';
        this_is_this.set('isLoading', true);
        let dateStamp = Ember.get(row, 'strStockTakeDateStamp');
        commonUtility.HTTPGetSomething(url, {
          dateStamp: dateStamp
        }).then(function (data) {
          this_is_this.set('stockTakeHistoryln', data.data);
          this_is_this.set('fetchingReportln', false);
        }).catch(function (error) {
          let errorMessage = error.responseJSON.errors[0].error;

          if (!errorMessage) {
            errorMessage = '';
          }

          this_is_this.set('fetchingReportln', false);
          alert('Saving Error. ' + errorMessage);
        });
      }

    },
    tableData: {
      columns: [{
        label: 'Product ID',
        valuePath: 'ProductID',
        width: '100px'
      }, {
        label: 'Product Name',
        valuePath: 'strProductDescription',
        resizable: true,
        width: '400px'
      }, {
        label: 'Size Type',
        valuePath: 'strStockTakelnProductSizeID',
        width: '60px'
      }, {
        label: 'Qty',
        valuePath: 'decStockTakelnQty',
        width: '100px'
      }]
    },
    tableDataHistory: {
      columns: [{
        label: 'Commence Date',
        valuePath: 'dteStockTakeCommenceDate',
        width: '200px',
        cellComponent: 'web-table-formatdate'
      }, {
        label: 'Stock Take By',
        valuePath: 'strPHUserFullName',
        width: '200px'
      }, {
        label: 'Stock Take Ref',
        valuePath: 'strStockTakeDateStamp',
        width: '200px'
      }, {
        label: 'View',
        valuePath: 'strStockTakeDateStamp',
        width: '200px',
        cellComponent: 'web-table-button'
      }]
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: new Date(),
      endDate: new Date()
    },
    currentData: {
      lowStock: false
    },
    lowStockResult: Ember.A([])
  });

  _exports.default = _default;
});