define("webcostume/controllers/admin-stockin/stockin-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function searchMoneyMovement(this_is_this) {
    let value = this_is_this.get('selectedValue');
    let startDate = (0, _moment.default)().format(officialDateFormat);
    let endDate = (0, _moment.default)().format(officialDateFormat);

    if (value === '7days') {
      startDate = (0, _moment.default)().add(-7, 'days').format(officialDateFormat);
    } else if (value === 'date') {
      startDate = (0, _moment.default)(this_is_this.get('current.startDate')).format(officialDateFormat), endDate = (0, _moment.default)(this_is_this.get('current.endDate')).format(officialDateFormat);
    }

    let data = {
      startDate: startDate,
      endDate: endDate
    };
    let commonUtility = this_is_this.get('commonUtility');
    let url = commonUtility.giveMeFullUrl('stockin/search');
    let headers = commonUtility.giveMeHeader();
    $.ajax({
      type: 'GET',
      dataType: "json",
      url: url,
      data: data,
      headers: headers,
      success: function (data) {
        this_is_this.set('results', data.data); // let total = 0;
        // data.summary.forEach(function(item, index){
        //     total += numeral(item.TotalReceived).value()
        // });
        // this_is_this.set('summaryTotal', total);
        // this_is_this.set('summaryData', data.summary);
      }
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      alert('Saving Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    stockInController: Ember.inject.controller('admin-stockin.index'),

    init() {
      console.log('init in stock in list');
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    selectedValue: 'today',

    searchCustomer() {
      searchCustomer(this);
    },

    disableDate: Ember.computed('selectedValue', function () {
      return this.get('selectedValue') !== 'date';
    }),
    summaryTotal: 0,
    actions: {
      selectStockIn(source, item) {
        //console.log(item);
        let stockInController = this.get('stockInController');
        stockInController.loadStockIn(item.intStockInID);
        this.transitionToRoute('admin-stockin.index');
      },

      selectRadioButton(event) {
        let target = event.target;
        let value = target.value;

        if (target.checked) {
          this.set('selectedValue', value);
        }
      },

      findRecords() {
        searchMoneyMovement(this);
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Stock In Date',
        valuePath: 'dteStockInDate'
      }, {
        label: 'Reference No',
        valuePath: 'strStockInReferenceNo'
      }, {
        label: 'Remark',
        valuePath: 'strStockInRemark'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: '',
      endDate: ''
    },
    openSearch: false,
    results: Ember.A([])
  });

  _exports.default = _default;
});