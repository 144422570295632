define("webcostume/components/delete-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didUpdateAttrs() {
      this._super(...arguments);

      this.set('disabledButton', false);
    },

    message: 'Are you sure you want to delete ',
    okButton: 'Yes',
    okAction: null,
    cancelButton: 'No',
    itemName: 'this record?',
    disabledButton: false,
    enableButton: Ember.computed.not('disabledButton'),
    actions: {
      onSubmit() {
        this.set('disabledButton', true);
        this.get('okAction')(this);
      }

    }
  });

  _exports.default = _default;
});