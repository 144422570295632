define("webcostume/helpers/is-not-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNotEqual = isNotEqual;
  _exports.default = void 0;

  function isNotEqual([param1, param2]
  /*, hash*/
  ) {
    return param1 != param2;
  }

  var _default = Ember.Helper.helper(isNotEqual);

  _exports.default = _default;
});