define("webcostume/templates/admin/stock-take-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J2e/s5HJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card \"],[9],[0,\"\\n   \\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n        \\n        \\n\\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n             \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n\\n           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n          \\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n                \\n                \"],[7,\"button\"],[11,\"class\",\"btn btn-warning\"],[9],[0,\"Confirm and Commence\"],[3,\"action\",[[22,0,[]],\"btnConfirm\"]],[10],[0,\" \\n\"],[0,\"           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n    \"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"isLoading\",\"columns\",\"scrollHeight\"],[[23,[\"lowStockResult\"]],[23,[\"fetchingReport\"]],[23,[\"tableData\",\"columns\"]],\"60vh\"]]],false],[0,\" \\n    \\n    \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/admin/stock-take-preview.hbs"
    }
  });

  _exports.default = _default;
});