define("webcostume/helpers/apai-formatdate", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apaiFormatdate = apaiFormatdate;
  _exports.default = void 0;

  function apaiFormatdate([param1]
  /*, hash*/
  ) {
    return (0, _moment.default)(param1).format('DD/MM/YYYY');
  }

  var _default = Ember.Helper.helper(apaiFormatdate);

  _exports.default = _default;
});