define("webcostume/apai/entity/exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exchangefieldName = void 0;
  let exchangefieldName = {
    exchangeID: 'intExchangeID',
    referenceNo: 'strExchangeReferenceNo',
    exchangeDate: 'dteExchangeDate',
    inputDate: 'dtmExchangeInputDate',
    userID: 'intExchangePHUserID',
    remark: 'strExchangeRemark',
    previousDiscount: 'decExchangePreviousDiscount',
    generation: 'intExchangeGeneration',
    paymentMethod: 'strExchangePaymentMethod',
    adjustment: 'decExchangeAdjustment'
  };
  _exports.exchangefieldName = exchangefieldName;
});