define("webcostume/templates/report/report-productsummary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UWwd5W68",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card \"],[9],[0,\"\\n   \\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n          \\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"\\n         \\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Refresh\"],[3,\"action\",[[22,0,[]],\"findRecords\"]],[10],[0,\" \\n                \"],[7,\"button\"],[11,\"class\",\"btn\"],[9],[0,\"Export\"],[3,\"action\",[[22,0,[]],\"btnExport\"]],[10],[0,\" \\n           \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n   \\n\\n    \"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"isLoading\",\"columns\",\"scrollHeight\"],[[23,[\"customerResult\"]],[23,[\"isLoading\"]],[23,[\"tableData\",\"columns\"]],\"60vh\"]]],false],[0,\" \\n    \\n    \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/report/report-productsummary.hbs"
    }
  });

  _exports.default = _default;
});