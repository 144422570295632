define("webcostume/controllers/setup-customer/setup-customer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function refreshData(this_is_this) {
    let customerID = this_is_this.get('customerID');
    this_is_this.store.query('customerinfo', {
      strCustomerInfoCustomerID: customerID
    }).then(data => {
      this_is_this.set('results', data);
    });
    var currentCustomerInfo = this_is_this.store.createRecord('customerinfo', {
      ICNo: '',
      email: '',
      contactNumber: ''
    });
    this_is_this.set('currentCustomerInfo', currentCustomerInfo);
  }

  var _default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),

    resetData() {
      console.log('resetdata');
    },

    refreshData() {
      console.log('here in refresh data of setup-customer=info');
      refreshData(this);
    },

    init() {// this.get('currentCustomerInfo').set('customerID','abc');
    },

    tableData: {
      columns: [{
        label: 'Name',
        valuePath: 'contactPerson'
      }, {
        label: 'NRIC',
        valuePath: 'ICNo'
      }, {
        label: 'Email',
        valuePath: 'email'
      }, {
        label: 'Contact Number',
        valuePath: 'contactNumber'
      }, {
        label: 'Delete',
        valuePath: 'id',
        cellComponent: 'web-table-scroll-button',
        linkDescription: 'Edit'
      }, {
        label: 'Delete',
        valuePath: 'id',
        cellComponent: 'web-table-scroll-button',
        linkDescription: 'Delete'
      }],
      page: 1,
      noofrecord: 10,
      searchField: ''
    },
    isEdit: false,
    actions: {
      transitionBack() {
        let fromRoute = this.get('appController.fromRoute');
        console.log('from customer info');
        console.log(fromRoute);
        let custid = this.get('customerID');

        if (fromRoute == '' || this.get('appController').isFromRoute('setup-customer.setup-customer-input')) {
          this.transitionToRoute('setup-customer.setup-customer-input', custid);
        } else if (fromRoute != '') {
          this.transitionToRoute(fromRoute);
        }
      },

      deleteRecord(record, linkDescription) {
        if (linkDescription == 'Delete') {
          let areyousure = confirm('Delete this record?');

          if (!areyousure) {
            return;
          }

          let bankRecord = this.store.peekRecord('customerinfo', record.get('id'));
          let meparent = this;

          if (bankRecord) {
            return bankRecord.destroyRecord().then(results => {
              return {
                success: true
              };
            }).catch(reason => {
              bankRecord.rollbackAttributes();
              alert('Delete failed. ' + reason.errors[0].reason);
              return {
                success: false,
                reason: reason.errors[0].reason
              };
            });
          }

          return new Ember.RSVP.Promise(function (resolve, reject) {
            resolve({
              success: false,
              reason: 'cannot find bank record'
            });
          });
        } else if (linkDescription == 'Edit') {
          this.set('isEdit', true);
          let currentCustomerInfo = this.store.peekRecord('customerinfo', record.get('id'));
          this.set('currentCustomerInfo', currentCustomerInfo);
        }
      },

      submit() {
        // this customerID is set during model retrieval
        var currentCustomerInfo = this.get('currentCustomerInfo');
        this.get('currentCustomerInfo').set('customerID', this.get('customerID')); // inside refresh data there is create new account to cater from coming from other route

        this.get('currentCustomerInfo').save().then(() => {
          this.set('isEdit', false);
          var currentCustomerInfo = this.store.createRecord('customerinfo', {
            ICNo: '',
            email: '',
            contactNumber: ''
          });
          this.set('currentCustomerInfo', currentCustomerInfo);
          this.refreshData();
        }).catch(exceptionReason => {
          alert('Update failed');
          console.log(exceptionReason);
        });
      },

      btnCancel_click() {
        this.set('isEdit', false);
        var currentCustomerInfo = this.get('currentCustomerInfo');
        currentCustomerInfo.rollbackAttributes();
        var currentCustomerInfo = this.store.createRecord('customerinfo', {
          ICNo: '',
          email: '',
          contactNumber: ''
        });
        this.set('currentCustomerInfo', currentCustomerInfo);
      }

    }
  });

  _exports.default = _default;
});