define("webcostume/services/phsetting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    commonUtility: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    fetchKeyAlready() {
      return this.get('isDone');
    },

    fetchInfo() {
      let commonUtility = this.get('commonUtility');
      let url = commonUtility.giveMeFullUrl('phsetting');
      let headers = commonUtility.giveMeHeader();
      let hello = this;
      var myPromise = new Promise(function (presolve, preject) {
        $.ajax({
          url: url,
          headers: headers
        }).then(function (data) {
          console.log('ajax fetch phsetting');
          console.log(data);
          hello.set('data', data);
          hello.set('isDone', true);
          presolve();
        }).catch(reason => {
          preject();
        });
      });
      this.set('myPromise', myPromise);
      return myPromise;
    },

    getKey(key) {
      return this.get('data')[key];
    },

    data: {},
    isDone: false
  });

  _exports.default = _default;
});