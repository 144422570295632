define("webcostume/controllers/setup-product/setup-product-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    appController: Ember.inject.controller('application'),
    setupProductIndex: Ember.inject.controller('setup-product.index'),
    session: Ember.inject.service('session'),
    productCategoryList: Ember.A([]),
    locationList: Ember.A([]),
    sizeGroupList: Ember.A([]),
    firstTimeSetup: false,
    currProduct: null,
    editting: Ember.computed('currProduct.itemCode', function () {
      return this.get('currProduct.itemCode') !== '';
    }),
    imageUrl: Ember.computed(function () {
      let commonUtility = this.get('commonUtility');
      return commonUtility.giveMeFullUrl('product/getimagethumbnail');
    }),
    sessionID: Ember.computed(function () {
      let session = this.get('session');
      return session.giveMeSessionID();
    }),
    productChange: Ember.observer('currProduct.{itemCode,description,cost,sellingPrice,wholesalePrice,active,remark,canOrder}', function () {
      console.log('currPRoduct change');
      this.set('isDirty', true);
    }),
    dirtyChange: Ember.observer('isDirty', function () {
      console.log('dirty change');
      console.log(this.get('isDirty'));
    }),

    resetProduct() {
      this.set('currProduct', this.get('store').createRecord('product', {
        itemCode: '',
        cost: 0,
        sellingPrice: 0,
        wholesalePrice: 0,
        active: true,
        remark: '',
        description: '',
        canOrder: ''
      }));
    },

    init() {
      this.resetProduct();
    },

    loadingNecessaryData(productID) {
      let commonUtility = this.get('commonUtility');
      let this_is_this = this;
      let appController = this.get('appController');

      if (!this.get('firstTimeSetup')) {
        console.log('first time setup');
        appController.openDialog('Setting up for first time');
        commonUtility.HTTPGetSomething('product/firsttimesetup').then(function (data) {
          if (productID == 'new') {
            this_is_this.resetProduct(); // to clear the old product
          }

          this_is_this.set('productCategoryList', data.productCategoryList);
          this_is_this.set('locationList', data.locationList);
          this_is_this.set('sizeGroupList', data.sizeGroupList);
          this_is_this.setProductCategory(0);
          this_is_this.setSizeGroup(2);
          this_is_this.setLocation(0);
          this_is_this.set('firstTimeSetup', true);

          if (productID == 'new') {
            this_is_this.refreshProductCategoryNumber();
            appController.closeDialog(); // can close now
          } else {
            commonUtility.HTTPGetSomething('product/load', {
              productID: productID
            }).then(function (data) {
              this_is_this.setProductData(data);
              this_is_this.loadPicture();
              appController.closeDialog();
              this_is_this.set('isDirty', false);
            }).catch(function (error) {
              console.log('Error while retrieving product');
              console.log(error);
            });
          }

          this_is_this.set('isDirty', false);
        }).catch(function (error) {
          console.log('Error while retrieving product');
          console.log(error);
          appController.closeDialog();
        });
      } else if (productID != 'new') {
        appController.openDialog('Loading product');
        commonUtility.HTTPGetSomething('product/load', {
          productID: productID
        }).then(function (data) {
          this_is_this.setProductData(data);
          this_is_this.loadPicture();
          appController.closeDialog();
          this_is_this.set('isDirty', false);
        }).catch(function (error) {
          console.log('Error while retrieving product');
          console.log(error);
          appController.closeDialog();
        });
      } else {
        this_is_this.resetProduct(); // to clear the old product

        this_is_this.setProductCategory(0);
        this_is_this.setSizeGroup(2);
        this_is_this.setLocation(0);
        this_is_this.refreshProductCategoryNumber();
        this_is_this.set('isDirty', false);
      }
    },

    setProductData(data) {
      this.set('currProduct.productID', data.productID);
      this.set('currProduct.itemCode', data.itemCode);
      this.set('currProduct.cost', data.cost);
      this.set('currProduct.sellingPrice', data.sellingPrice);
      this.set('currProduct.wholesalePrice', data.wholesalePrice);
      this.set('currProduct.active', data.active);
      this.set('currProduct.remark', data.remark);
      this.set('currProduct.description', data.description);
      this.set('currProduct.canOrder', data.canOrder);
      this.set('currProduct.productCategoryID', data.productCategoryID);
      this.set('currProduct.locationID', data.locationID);
      this.set('currProduct.sizeGroup', data.sizeGroup);
      let locationList = this.get('locationList');

      for (let i = 0; i < locationList.length; i++) {
        if (locationList[i].intLocationID == data.locationID) {
          console.log('found location');
          console.log(locationList[i]);
          this.set('currentData.currLocation', locationList[i]);
          break;
        }
      }

      let sizeGroupList = this.get('sizeGroupList');

      for (let i = 0; i < sizeGroupList.length; i++) {
        if (sizeGroupList[i].strProductSizeGroup == data.sizeGroup) {
          this.set('currentData.currSizeGroup', sizeGroupList[i]);
          break;
        }
      }

      let productCategoryList = this.get('productCategoryList');

      for (let i = 0; i < productCategoryList.length; i++) {
        let currProductCategory = productCategoryList[i];

        if (currProductCategory.strProductCategoryID == data.productCategoryID) {
          this.set('currentData.currProductCategoryDescription', Ember.get(currProductCategory, 'strProductCategoryDescription'));
          this.set('currentData.currProductCategoryID', Ember.get(currProductCategory, 'strProductCategoryID'));
          break;
        }
      }
    },

    imageList: null,
    uploading: false,
    isDirty: false,

    refreshProductCategoryNumber() {
      let commonUtility = this.get('commonUtility');
      let this_is_this = this;
      commonUtility.HTTPGetSomething('product/productcategorykey', {
        productCategoryID: this.get('currProduct.productCategoryID')
      }).then(function (key) {
        this_is_this.set('currProduct.productID', key);
      });
    },

    actions: {
      deleteThisRecord() {
        let appController = this.get('applicationController');
        let commonUtility = this.get('commonUtility');
        let record = this.get('currProduct');
        let productID = Ember.get(record, 'productID');
        let productCategoryID = Ember.get(record, 'productCategoryID');
        let uniqueID = productCategoryID + productID;
        let this_is_this = this;
        let answer = confirm('Are you sure you want to delete this product?');

        if (!answer) {
          return;
        }

        commonUtility.HTTPPostSomething('product/delete', {
          productID: uniqueID
        }).then(function (data) {
          alert('Record deleted.');
          this_is_this.get('setupProductIndex').refreshData();
          this_is_this.transitionToRoute('setup-product.index');
        }).catch(function (data) {
          alert('Delete failed.');
          console.log(data);
          alert(data.responseJSON.errors[0].error);
        });
      },

      btnMakeItDefault_click(image, event) {
        let commonUtility = this.get('commonUtility');
        let itemCode = this.get('currProduct.itemCode');
        let imageList = this.get('imageList');
        commonUtility.HTTPPostSomething('product/makedefaultimage', {
          itemCode: itemCode,
          imageID: image.imageID
        }).then(function (pictureList) {
          imageList.forEach(function (imageListAgain, index) {
            imageListAgain.forEach(function (item, index) {
              Ember.set(item, 'imageDefault', 0);
            });
          });
          Ember.set(image, 'imageDefault', 1);
        }).catch(function (data) {
          alert('Setting default failed.');
          console.log(data);
        });
      },

      btnDeleteImage_click(image, event) {
        let commonUtility = this.get('commonUtility');
        let itemCode = this.get('currProduct.itemCode');
        let imageList = this.get('imageList');
        let this_is_this = this;

        if (image.imageDefault == 1) {
          alert('Deleting default is not allowed.');
          return;
        }

        commonUtility.HTTPPostSomething('product/deleteimage', {
          itemCode: itemCode,
          imageID: image.imageID
        }).then(function (pictureList) {
          this_is_this.loadPicture();
        }).catch(function (data) {
          alert('Image delete failed.');
          console.log(data);
        });
      },

      btnUpload_click() {
        let commonUtility = this.get('commonUtility');
        let fullurl = commonUtility.giveMeFullUrl('product/uploadimage');
        let this_is_this = this;
        let appController = this.get('appController');
        appController.openDialog('Uploading...');
        let session = this.get('session');
        $.ajax({
          url: fullurl,
          type: 'POST',
          cache: false,
          contentType: false,
          headers: {
            sessionID: session.giveMeSessionID()
          },
          data: new FormData($('#formWithFiles')[0]),
          // The form with the file inputs.
          processData: false // Using FormData, no need to process data.,                

        }).done(function () {
          this_is_this.loadPicture();
          appController.closeDialog();
          $('#image').val('');
        }).fail(function (error) {
          alert('Uploading failed. Please upload PNG or JPEG');
          appController.closeDialog();
        });
      },

      cboProductCategory_change(event) {
        this.setProductCategory(event.target.selectedIndex);
        this.refreshProductCategoryNumber(); // get number here
      },

      cboLocation_change(selectedLocation) {
        this.set('currentData.currLocation', selectedLocation);
        this.set('currProduct.locationID', Ember.get(selectedLocation, 'intLocationID'));
      },

      cboSizeGroup_change(selectedSizeGroup) {
        this.set('currentData.currSizeGroup', selectedSizeGroup);
        this.set('currProduct.sizeGroup', Ember.get(selectedSizeGroup, 'strProductSizeGroup'));
      },

      btnSave_click() {
        let appController = this.get('appController');

        if (!this.validateFormData()) {
          return;
        }

        let product = JSON.parse(JSON.stringify(this.get('currProduct')));
        let commonUtility = this.get('commonUtility');
        let this_is_this = this;
        appController.openDialog('Saving...');
        commonUtility.HTTPPostSomething('product/save', {
          product: product,
          edit: this.get('editting')
        }).then(function (data) {
          this_is_this.set('currProduct.productID', data.productID);
          this_is_this.set('currProduct.itemCode', data.itemCode);
          alert('Save succesfully. Item Code ' + data.itemCode);
          appController.closeDialog(); // this_is_this.transitionToRoute('setup-product.index');

          this_is_this.set('isDirty', false);
        }).catch(function (data) {
          alert('Save failed.');
          console.log(data);
          appController.closeDialog();
          alert(data.responseJSON.errors[0].error);
        });
      },

      btnCancel_click() {
        let answer = true;

        if (this.get('isDirty')) {
          if (this.get('currProduct.itemCode')) {
            answer = confirm('Are  you sure you want to close? You may have unsaved changes');
          } else {
            answer = confirm('Discard new record?');
          }
        }

        if (answer) {
          this.transitionToRoute('setup-product.index');
        }
      }

    },

    loadPicture() {
      let commonUtility = this.get('commonUtility');
      let this_is_this = this;
      let itemCode = this.get('currProduct.itemCode');
      commonUtility.HTTPGetSomething('product/getimagelist', {
        itemCode: itemCode
      }).then(function (pictureList) {
        let i = 0;
        let imageList = [];

        while (true) {
          if (pictureList.length == 0) {
            break;
          }

          imageList.push(pictureList.splice(0, 4));
        }

        this_is_this.set('imageList', imageList);
      }).catch(function (error) {
        console.log('Error while image list');
        console.log(error);
      });
    },

    validateFormData() {
      if (this.get('currProduct.description') === '') {
        alert('Description is empty');
        $('#actr_description').focus();
        return false;
      }

      if (this.get('currProduct.cost') < 0) {
        alert('Product cost must be more than 0');
        $('#actr_cost').focus();
        return false;
      }

      if (this.get('currProduct.cost') == 0) {
        let answer = confirm('Product cost is zero. Continue?');

        if (!answer) {
          $('#actr_cost').focus();
          return false;
        }
      }

      if (this.get('currProduct.sellingPrice') <= 0) {
        alert('Retail Price cost must be more than 0');
        $('#actr_retailPrice').focus();
        return false;
      }

      if (this.get('currProduct.wholesalePrice') <= 0) {
        alert('Whole sale Price cost must be more than 0');
        $('#actr_wholesalePrice').focus();
        return false;
      }

      if (numeral(this.get('currProduct.sellingPrice')).value() < numeral(this.get('currProduct.wholesalePrice')).value()) {
        let answer = confirm('Wholesale price is more than retail price. Continue?');

        if (!answer) {
          $('#actr_retailPrice').focus();
          return false;
        }
      } // let itemCode = this.get('currProduct.itemCode');
      // if (itemCode.length != 6) {
      //     alert('Item Code must be exactly 6 digit');
      //     $('#actr_itemcode').focus();            
      //     return false;
      // }
      // let  isnum = /^\d+$/.test(itemCode);
      // if (!isnum) {
      //     alert('Item Code must contain only digits');
      //     $('#actr_itemcode').focus();            
      //     return false;
      // }


      return true;
    },

    setProductCategory(index) {
      let productCategoryList = this.get('productCategoryList');
      let currProductCategory = productCategoryList.objectAt(index);
      this.set('currentData.currProductCategoryDescription', Ember.get(currProductCategory, 'strProductCategoryDescription'));
      this.set('currentData.currProductCategoryID', Ember.get(currProductCategory, 'strProductCategoryID'));
      this.set('currProduct.productCategoryID', Ember.get(currProductCategory, 'strProductCategoryID'));
    },

    setLocation(index) {
      let locationList = this.get('locationList');
      let currLocation = locationList.objectAt(index);
      this.set('currentData.currLocation', currLocation);
      this.set('currProduct.locationID', Ember.get(currLocation, 'intLocationID'));
    },

    setSizeGroup(index) {
      let sizeGroupList = this.get('sizeGroupList');
      let currSizeGroup = sizeGroupList.objectAt(index);
      this.set('currentData.currSizeGroup', currSizeGroup);
      this.set('currProduct.sizeGroup', Ember.get(currSizeGroup, 'strProductSizeGroup'));
    },

    currentData: {
      currProductCategoryDescription: '',
      currProductCategoryID: '',
      currSizeGroup: null,
      currLocation: null
    }
  });

  _exports.default = _default;
});