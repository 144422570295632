define("webcostume/components/web-table", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getID(this_is_this, id) {
    return '#' + this_is_this.elementId + id;
  } // displayField should expect a list


  var _default = Ember.Component.extend({
    comName: 'source_webtable',

    didInsertElement() {
      this.set('myself', this);
      console.log(getID(this, 'actr_table'));
    },

    myself: null,

    // set this so that the parent could have the handler
    init() {
      this._super(...arguments);

      this.set('headerMessage.showMessage', false);
    },

    rowSelected: 0,
    noofrecordChanged: Ember.observer('noofrecord', function () {
      console.log('haha');
    }),
    updateRowAction: null,
    columns: [{
      displayHeader: 'sample one',
      displayOrder: 'bankCode'
    }, {
      displayHeader: 'sample two',
      displayOrder: 'bankName'
    }],
    headerMessage: {
      showMessage: false,
      message: 'This is message',
      type: 'success'
    },
    data: null,
    // dataChanged : Ember.observer('data', function() {
    //     console.log(this.comName);
    //     console.log('changed');
    // }), 
    currentPage: 1,
    noofrecord: 5,
    totalRecord: 10,
    totalPage: Ember.computed('noofrecord', 'totalRecord', function () {
      return Math.ceil(this.totalRecord / this.noofrecord);
    }),
    openModal: false,
    deleting: false,
    // this is to control the message deleting
    showPaging: true,
    showDeleteMessage: true,
    showSelect: false,
    // show select button
    enableSelection: false,
    // enable keyboard selection
    selectAction: null,
    showEdit: true,
    showDelete: true,
    askForDeleteConfirmation: true,
    deleteAction: null,
    // this should be passed down from controller to execute deletion, expect a promise 
    editPage: 'setup-bank.setup-bank-input',
    // dataChanged : Ember.observer('data', function() {
    //      console.log('aa');
    //      $(getID(this, 'actr_table')).DataTable();
    // }),
    loading: false,
    // item to force  rerender , 
    // forceRender() {
    //     console.log(this.comName);
    //     this.set('mustrender', ! this.mustrender);
    //     this.set('mustrender', ! this.mustrender);
    //     this.rerender();
    //     console.log(this.data);
    // }, 
    // willRender() {
    //     console.log(this.comName);
    //  console.log('i am going to render');
    // }, 
    moveUpDown: false,
    actions: {
      btnMoveUp_click() {
        let selectedRows = this.get('table').get('selectedRows'); // only allow one row to be selected

        let row = null;

        if (selectedRows.length == 1) {
          row = selectedRows[0];
        } else {
          alert('No row selected');
        }

        let index = this.get('data').indexOf(row.content);

        if (index > 0) {
          let indexMinusOne = index - 1;
          let item = this.get('data').objectAt(index);
          this.get('data').removeAt(index);
          this.get('data').insertAt(indexMinusOne, item);
          let firstRow = this.get('table').get('rows').objectAt(indexMinusOne);
          console.log(firstRow.get('content'));
          firstRow.set('selected', true);
        }
      },

      btnMoveDown_click() {
        let selectedRows = this.get('table').get('selectedRows'); // only allow one row to be selected

        let row = null;

        if (selectedRows.length == 1) {
          row = selectedRows[0];
        } else {
          alert('No row selected');
        }

        let index = this.get('data').indexOf(row.content);

        if (index < this.get('data').length - 1) {
          let indexPlusOne = index + 1;
          let item = this.get('data').objectAt(index);
          this.get('data').removeAt(index);
          this.get('data').insertAt(indexPlusOne, item);
          let firstRow = this.get('table').get('rows').objectAt(indexPlusOne);
          console.log(firstRow.get('content'));
          firstRow.set('selected', true);
        }
      },

      didRender() {
        console.log('didRender datatable');
      },

      showDeleteDialog(record) {
        this.set('todelete', record);

        if (this.askForDeleteConfirmation) {
          let friendName = 'this record';

          if (record.friendlyName) {
            friendName = record.friendlyName();
          }

          let answer = confirm('Are you sure you want to delete ' + friendName + '?');

          if (answer) {
            this.send('deleteThisRecord');
          }
        } else {
          this.send('deleteThisRecord');
        }
      },

      deleteThisRecord(dialog) {
        if (this.get('deleteAction')) {
          this.get('deleteAction')(this.get('todelete'));
        }
      },

      selectRow(row) {
        if (this.get('selectAction')) {
          this.get('selectAction')(this.comName, row);
        }
      },

      changePage(type) {
        let lastCurrentPage = this.currentPage;

        switch (type) {
          case 'first':
            this.set('currentPage', 1);
            break;

          case 'next':
            if (this.get('currentPage') < this.get('totalPage')) {
              this.incrementProperty('currentPage', 1);
            }

            break;

          case 'prev':
            if (this.get('currentPage') > 1) {
              this.decrementProperty('currentPage', 1);
            }

            break;

          case 'last':
            this.set('currentPage', this.get('totalPage'));
            break;
        }

        if (lastCurrentPage !== this.get('currentPage')) {
          this.get('refreshFunction')();
        }
      },

      keykey(e) {
        //console.log(e);
        if (!this.enableSelection) {
          return;
        }

        let currentSelected = this.rowSelected;

        if (e.keyCode === 40) {
          currentSelected += 1;
        } else if (e.keyCode === 38) {
          currentSelected -= 1;
        } else if (e.keyCode === 13) {
          if (this.get('selectAction')) {
            this.get('selectAction')(this.comName, this.data.objectAt(currentSelected));
          }

          return true;
        } else {
          return true;
        }

        if (currentSelected < 0 || currentSelected >= this.noofrecord) {
          return false;
        }

        this.set('rowSelected', currentSelected);
        return false;
      },

      clickRow(rowid) {
        this.set('rowSelected', rowid);
      }

    },
    scrollHeight: '100vh',
    table: Ember.computed('data', 'showDelete', 'showSelect', function () {
      let columns = [];
      this.columns.forEach(function (item) {
        columns.push(item);
      });

      if (this.showSelect) {
        columns.push({
          label: 'Link',
          valuePath: '123',
          cellComponent: 'web-table-button'
        });
      }

      if (this.showDelete) {
        columns.push({
          label: 'Delete',
          valuePath: '123',
          cellComponent: 'web-table-delete'
        });
      }

      return new _emberLightTable.default(columns, this.data, {
        enableSync: true
      });
    })
  });

  _exports.default = _default;
});