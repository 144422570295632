define("webcostume/helpers/check-view-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkViewPermission = checkViewPermission;
  _exports.default = void 0;

  function checkViewPermission([params]
  /*, hash*/
  ) {
    let answer = params.some(function (item, index) {
      return item.strPermissionName === 'View' && item.havePermission == 1;
    });
    return answer;
  }

  var _default = Ember.Helper.helper(checkViewPermission);

  _exports.default = _default;
});