define("webcostume/controllers/transaction-sale/sale-void-history", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function searchSale(this_is_this) {
    let data = {
      customerID: this_is_this.get('current.customerID'),
      startDate: (0, _moment.default)(this_is_this.get('current.startDate')).format(officialDateFormat),
      endDate: (0, _moment.default)(this_is_this.get('current.endDate')).format(officialDateFormat),
      transactionType: this_is_this.get('selectedTransaction'),
      invoiceNo: this_is_this.get('current.invoiceNo')
    };
    let commonUtility = this_is_this.get('commonUtility');
    let url = commonUtility.giveMeFullUrl('sale/void/search');
    let headers = commonUtility.giveMeHeader();
    this_is_this.set('isLoading', true);
    $.ajax({
      type: 'GET',
      dataType: "json",
      url: url,
      data: data,
      headers: headers,
      success: function (data) {
        //console.log('success');
        this_is_this.set('saleResult', data.data);
      }
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      alert('Saving Error. ' + errorMessage);
    }).then(function () {
      this_is_this.set('isLoading', false);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),

    init() {
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    estimatedTotalCost: 0,
    isLoading: false,

    searchCustomer() {
      saleResult(this);
    },

    /* send the sales id to one specified here instead 
       only works one time, it will be reset after ward
        ** bug alert ** 
        This might get set and promptly forgot to use it. 
       */
    sendToMeInstead(controller, route) {
      if (controller != null) {
        this.set('placeToSendTo', {
          controller: controller,
          route: route
        });
      } else {
        this.set('placeToSendTo', null);
      }
    },

    transactionTypeList: ['[ALL]', 'Invoice', 'POS'],
    selectedTransaction: '[ALL]',
    webtreepermission: Ember.inject.service('webtreepermission'),
    openViewSaleln: false,
    actions: {
      selectSale(source, item) {
        this.set('openViewSaleln', true);
        console.log(source);
        console.log(item.intSaleID);
        let commonUtility = this.get('commonUtility');
        let url = 'sale/void/load';
        let data = {
          saleID: item.intSaleID
        };
        let this_is_this = this;
        let ajaxResult = commonUtility.HTTPGetSomething(url, data).then(function (data) {
          //console.log(data);
          this_is_this.set('arrTransactionLine', data.transactionLines);
        }).catch(function (error) {
          console.log('loading error');
          console.log(error);
        }).then(function (data) {});
      },

      btnClearCustomer_click() {
        this.set('current.customerID', '');
        this.set('current.customerName', '');
      },

      findRecords() {
        searchSale(this);
      },

      deleteThisRecord(record) {
        let bankRecord = this.store.peekRecord('bank', record.get('id'));
        let meparent = this;

        if (bankRecord) {
          return bankRecord.destroyRecord().then(results => {
            meparent.send('findRecords');
            return {
              success: true
            };
          }).catch(reason => {
            bankRecord.rollbackAttributes();
            return {
              success: false,
              reason: reason.errors[0].reason
            };
          });
        }

        return new Ember.RSVP.Promise(function (resolve, reject) {
          resolve({
            success: false,
            reason: 'cannot find bank record'
          });
        });
      },

      customerKeyDown() {
        console.log('customer key down');
        this.set('openSearch', true);
        let requestString = {
          search: this.get('current.customerName')
        };
        let dataPromise = this.store.query('customer', requestString).then(results => {
          this.set('searchResults', results);
        });
      },

      searchSelect(source, data) {
        this.set('openSearch', false);
        this.set('current.customerName', data.get('name'));
        this.set('current.customerID', data.get('customerID'));
        this.set('currCustomer', data);
      },

      onHiddenCustomer() {
        this.set('openSearch', false);
      },

      onHiddenSaleln() {
        this.set('openViewSaleln', false);
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Date',
        valuePath: 'dteSaleDate'
      }, {
        label: 'Type',
        valuePath: 'strSaleType'
      }, {
        label: 'Invoice No',
        valuePath: 'strSaleInvoiceNo'
      }, {
        label: 'Sub Total',
        valuePath: 'SubTotal'
      }, {
        label: 'Create By',
        valuePath: 'CreateBy'
      }, {
        label: 'Delete By',
        valuePath: 'DeleteBy'
      }, {
        label: 'Deleted When',
        valuePath: 'DeletedWhen'
      }, {
        label: 'Remark',
        valuePath: 'strSaleRemark'
      }, {
        label: 'Cust ID',
        valuePath: 'strCustomerID'
      }, {
        label: 'Cust Name',
        valuePath: 'strCustomerName'
      }, {
        label: 'Payment Method',
        valuePath: 'paymentList'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    transactionWebTable: {
      columns: [{
        label: 'Item Code',
        valuePath: 'strProductItemCode',
        width: '80px'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription',
        resizable: true,
        width: '420px'
      }, {
        label: 'Qty',
        valuePath: 'qty',
        resizable: true,
        width: '250px'
      }, {
        label: 'Total Qty',
        valuePath: 'totalQty'
      }, {
        label: 'Selling Price',
        valuePath: 'sellingPrice'
      }, {
        label: 'Sub Total',
        valuePath: 'SubTotal'
      }, {
        label: 'Note',
        valuePath: 'note'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    customerTableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'Remark',
        valuePath: 'remark'
      }, {
        label: 'Blacklisted',
        valuePath: 'isBlacklisted'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: '',
      endDate: '',
      invoiceNo: ''
    },
    openSearch: false,
    saleResult: Ember.A([])
  });

  _exports.default = _default;
});