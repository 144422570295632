define("webcostume/apai/entity/productsize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.productsizefieldName = void 0;
  let productsizefieldName = {
    sizeID: 'strProductSizeID',
    sizeGroup: 'strProductSizeGroup',
    order: 'intProductSizeOrder'
  };
  _exports.productsizefieldName = productsizefieldName;
});