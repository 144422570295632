define("webcostume/apai/entity/customerinfo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customerinfofieldName = void 0;
  let customerinfofieldName = {
    customerInfoID: 'intCustomerInfoID',
    customerID: 'strCustomerInfoCustomerID',
    contactPerson: 'strCustomerInfoContactPerson',
    email: 'strCustomerInfoEmail',
    ICNo: 'strCustomerInfoICNo',
    contactNumber: 'strCustomerInfoContactNumber'
  };
  _exports.customerinfofieldName = customerinfofieldName;
});