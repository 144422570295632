define("webcostume/services/common-utility", ["exports", "moment", "webcostume/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    host: _environment.default.APP.host,

    HTTPGetSomething(url, params) {
      let session = this.get('session');
      let fullUrl = this.giveMeFullUrl(url, params);
      let request = $.ajax({
        type: 'GET',
        url: fullUrl,
        headers: {
          sessionID: session.giveMeSessionID()
        }
      });
      return request;
    },

    HTTPAjaxDownload(url, fileName, postData, params) {
      let session = this.get('session');
      let fullUrl = this.giveMeFullUrl(url, params);
      let request = $.ajax({
        type: 'POST',
        url: fullUrl,
        data: JSON.stringify(postData),
        contentType: "application/json",
        headers: {
          sessionID: session.giveMeSessionID()
        },
        xhrFields: {
          responseType: 'blob'
        },
        error: function (xhr, desc, err) {
          console.log(xhr);
          console.log("Details: " + desc + "\nError:" + err);
        }
      }).done(function (blob, textStatus, response) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.download = fileName;
        link.target = "_blank";
        link.click();
        document.body.removeChild(link);
      });
      return request;
    },

    HTTPPostSomething(url, postData, params) {
      let session = this.get('session');
      let fullUrl = this.giveMeFullUrl(url, params);
      let request = $.ajax({
        type: 'POST',
        url: fullUrl,
        data: JSON.stringify(postData),
        contentType: "application/json",
        headers: {
          sessionID: session.giveMeSessionID()
        }
      });
      return request;
    },

    giveMeFullUrl(url, params) {
      if (params) {
        return this.get('host') + '/' + url + '?' + $.param(params);
      } else {
        return this.get('host') + '/' + url;
      }
    },

    giveMeHeader() {
      let session = this.get('session');
      return {
        sessionID: session.giveMeSessionID()
      };
    },

    giveMeUniqueDate() {
      return (0, _moment.default)().format("DD_MM_YYYY_HH_MM_SS");
    }

  });

  _exports.default = _default;
});