define("webcostume/services/webtreepermission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    allowedPermission: null,

    // hold permission list regarding this user
    allowPagePermission(page, permissionType) {
      console.log('checking allowing permission');
      let allowedPermission = this.get('allowedPermission');
      console.log(allowedPermission);

      if (page in allowedPermission) {
        if (permissionType in allowedPermission[page]) {
          return true;
        }
      }

      ;
      return false;
    },

    allowSave(page) {
      return this.allowPagePermission(page, 'Save');
    },

    allowInvoice(page) {
      return this.allowPagePermission(page, 'InvoicePermission');
    },

    allowEdit(page) {
      return this.allowPagePermission(page, 'Edit');
    },

    allowEditDate(page) {
      return this.allowPagePermission(page, 'EditDate');
    },

    allowDelete(page) {
      return this.allowPagePermission(page, 'Delete');
    },

    allowView(page) {
      return this.allowPagePermission(page, 'View');
    }

  });

  _exports.default = _default;
});