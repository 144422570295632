define("webcostume/serializers/exchange", ["exports", "ember-data", "webcostume/apai/entity/sale"], function (_exports, _emberData, _sale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    primaryKey: 'intExchangeID',
    attrs: _sale.exchangefieldName
  });

  _exports.default = _default;
});