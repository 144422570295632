define("webcostume/apai/entity/stockinln", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stockinlnfieldName = void 0;
  let stockinlnfieldName = {
    stockInlnID: 'intStockInlnID',
    stockInID: 'intStockInlnStockInID',
    productID: 'strStockInlnProductID',
    productCategoryID: 'strStockInlnProductCategoryID',
    qty: 'decStockInlnQty',
    sizeID: 'strStockInlnProductSizeStockSizeID',
    sizeGroup: 'strStockInlnProductSizeStockSizeGroup'
  };
  _exports.stockinlnfieldName = stockinlnfieldName;
});