define("webcostume/templates/setup-location/setup-location-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g6MTwKLC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-7\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n                Location ID\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"type\",\"maxlength\",\"class\",\"disabled\",\"value\",\"id\"],[\"text\",5,\"form-control\",true,[23,[\"currLocation\",\"locationID\"]],\"actr_locationID\"]]],false],[0,\"        \\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n            Name\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"id\"],[\"text\",[23,[\"currLocation\",\"name\"]],\"form-control\",\"actr_description\"]]],false],[0,\"        \\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-8\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Save\"],[3,\"action\",[[22,0,[]],\"btnSave_click\"]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"btnCancel_click\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-location/setup-location-input.hbs"
    }
  });

  _exports.default = _default;
});