define("webcostume/controllers/transaction-exchange/exchange-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function searchExchange(this_is_this) {
    let data = {
      invoiceNo: this_is_this.get('current.invoiceNo'),
      startDate: (0, _moment.default)(this_is_this.get('current.startDate')).format(officialDateFormat),
      endDate: (0, _moment.default)(this_is_this.get('current.endDate')).format(officialDateFormat)
    };
    let commonUtility = this_is_this.get('commonUtility');
    this_is_this.set('isLoading', true);
    commonUtility.HTTPGetSomething('exchange/search', data).then(function (data) {
      this_is_this.set('exchangeResult', data.data);
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      alert('Saving Error. ' + errorMessage);
    }).then(function () {
      this_is_this.set('isLoading', false);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    exchangeController: Ember.inject.controller('transaction-exchange.index'),

    init() {
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    isLoading: false,

    /* send the sales id to one specified here instead 
       only works one time, it will be reset after ward
        ** bug alert ** 
        This might get set and promptly forgot to use it. 
      */
    sendToMeInstead(controller, route) {
      this.set('placeToSendTo', {
        controller: controller,
        route: route
      });
    },

    webtreepermission: Ember.inject.service('webtreepermission'),
    actions: {
      selectExchange(source, item) {
        console.log(item);
        let placeToSendTo = this.get('placeToSendTo');

        if (placeToSendTo) {
          let controller = placeToSendTo['controller'];
          let route = placeToSendTo['route'];
          controller.setExchangeNo(item.intExchangeID, item.str);
          this.transitionToRoute(route);
        } else {
          if (!this.get('webtreepermission').allowView('transaction-exchange.index')) {
            alert('You are not allowed to enter to view this transaction');
            return;
          }

          let exchangeController = this.get('exchangeController');
          exchangeController.loadExchange(item.intExchangeID);
          this.transitionToRoute('transaction-exchange.index');
        }
      },

      btnClearCustomer_click() {
        this.set('current.referenceNo', '');
      },

      findRecords() {
        searchExchange(this);
      }

    },
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Date',
        valuePath: 'dteExchangeDate',
        cellComponent: 'web-table-date'
      }, {
        label: 'Invoice No',
        valuePath: 'strSaleInvoiceNo'
      }, {
        label: 'Ref No',
        valuePath: 'strExchangeReferenceNo'
      }, {
        label: 'Remark',
        valuePath: 'strExchangeRemark'
      }, {
        label: 'Customer Name',
        valuePath: 'strCustomerName'
      }, {
        label: 'Generation',
        valuePath: 'intExchangeGeneration'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      referenceNo: '',
      startDate: '',
      endDate: ''
    },
    openSearch: false,
    saleResult: Ember.A([])
  });

  _exports.default = _default;
});