define("webcostume/routes/setup-customer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      var dataPromise = null;
      let mycontroller = this.controllerFor('setup-customer.index');
      let requestString = {
        page: 0,
        noofrecord: 0,
        search: mycontroller.get('tableData.searchField')
      };
      if (mycontroller.get('tableData.searchField') != '') return this.store.query('customer', requestString);
    },

    actions: {
      refreshData() {
        this.refresh();
      },

      didTransition() {
        this.controllerFor('setup-customer').set('title', 'Setup Customer - List');
      },

      willTransition() {
        this.controllerFor('application').setRouteName('setup-customer.index');
      }

    }
  });

  _exports.default = _default;
});