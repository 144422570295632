define("webcostume/routes/setup-customer/setup-customer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this.controllerFor('setup-customer.setup-customer-info').set('customerID', params.customerID);
      this.controllerFor('setup-customer.setup-customer-info').refreshData();
    },

    actions: {
      didTransition() {
        this.controllerFor('setup-customer').set('title', 'Setup Customer - Customer Info ');
      },

      willTransition() {
        console.log('setup customer input will transition');
        this.controllerFor('application').setRouteName('setup-customer.setup-customer-info');
      }

    },
    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        controller.resetData();
      }
    }
  });

  _exports.default = _default;
});