define("webcostume/serializers/productsizestock", ["exports", "ember-data/serializers/json", "webcostume/apai/entity/productsizestock"], function (_exports, _json, _productsizestock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend({
    attrs: _productsizestock.productsizestockfieldName,

    /*
       my format is to be {
             data : [
                 {records1},
                 {records2},
                 {records3},
             ],
             meta : [
                 {'abc' :'def'}
             ]
        or simply 
           [
               {records1 },
               {records2},
               {records33}
           ]
    */
    _normalizeResponse(store, primaryModelClass, payload, id, requestType, isSingle) {
      let documentHash = {
        data: null,
        included: []
      };
      let meta = this.extractMeta(store, primaryModelClass, payload);

      if (meta) {
        (true && !(Ember.typeOf(meta) === 'object') && Ember.assert('The `meta` returned from `extractMeta` has to be an object, not "' + Ember.typeOf(meta) + '".', Ember.typeOf(meta) === 'object'));
        documentHash.meta = meta;
      }

      if (payload.data) {
        payload = payload.data;
      }

      if (isSingle) {
        let {
          data,
          included
        } = this.normalize(primaryModelClass, payload);
        documentHash.data = data;

        if (included) {
          documentHash.included = included;
        }
      } else {
        let ret = new Array(payload.length);

        for (let i = 0, l = payload.length; i < l; i++) {
          let item = payload[i];
          let {
            data,
            included
          } = this.normalize(primaryModelClass, item);

          if (included) {
            documentHash.included.push(...included);
          }

          ret[i] = data;
        }

        documentHash.data = ret;
      }

      return documentHash;
    }

  });

  _exports.default = _default;
});