define("webcostume/templates/components/web-table-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LbhWxGmW",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"moment-format\",[[23,[\"rawValue\"]],\"DD/MM/YYYY\",\"YYYY/MM/DD\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/components/web-table-date.hbs"
    }
  });

  _exports.default = _default;
});