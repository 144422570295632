define("webcostume/templates/admin/stock-take-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LnIUU/OP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card \"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[0,\"Stock Take History\"],[10],[0,\"\\n    \"],[10],[0,\"   \\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n        \\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n                \\n                \"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"isLoading\",\"columns\",\"scrollHeight\",\"selectAction\"],[[23,[\"stockTakeHistory\"]],[23,[\"fetchingReport\"]],[23,[\"tableDataHistory\",\"columns\"]],\"40vh\",[27,\"action\",[[22,0,[]],\"selectRecord\"],null]]]],false],[0,\" \\n    \\n           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[7,\"div\"],[11,\"class\",\"card \"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Stock Take History Detail\"],[10],[0,\"\\n    \"],[10],[0,\"   \\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n        \\n         \"],[7,\"div\"],[11,\"class\",\"row anderson_space\"],[9],[0,\"         \\n            \"],[7,\"div\"],[11,\"class\",\"col-md-5\"],[9],[0,\"\\n                \\n    \"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"isLoading\",\"columns\",\"scrollHeight\"],[[23,[\"stockTakeHistoryln\"]],[23,[\"fetchingReportln\"]],[23,[\"tableData\",\"columns\"]],\"60vh\"]]],false],[0,\" \\n    \\n           \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n    \\n\\n    \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/admin/stock-take-history.hbs"
    }
  });

  _exports.default = _default;
});