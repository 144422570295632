define("webcostume/components/web-table-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      btnEdit_click() {
        this.get('row').set('isEditting', true);
      },

      btnSave_click() {
        this.get('row').set('isEditting', false);
        let saveRowAction = this.get('tableActions').saveRowAction;

        if (saveRowAction != null) {
          saveRowAction(this.get('row'));
        }
      }

    }
  });

  _exports.default = _default;
});