define("webcostume/controllers/report/report-stockflow", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let officialDateFormat = 'DD/MM/YYYY';

  function searchCustomer(this_is_this) {
    let data = {
      transactionType: this_is_this.get('selectedTransType'),
      startDate: (0, _moment.default)(this_is_this.get('current.startDate')).format(officialDateFormat),
      endDate: (0, _moment.default)(this_is_this.get('current.endDate')).format(officialDateFormat),
      description: this_is_this.get('txtProductDescription')
    };
    let commonUtility = this_is_this.get('commonUtility');
    let url = commonUtility.giveMeFullUrl('report/stockflow');
    let headers = commonUtility.giveMeHeader();
    console.log(data);
    $.ajax({
      type: 'GET',
      dataType: "json",
      url: url,
      data: data,
      headers: headers,
      success: function (data) {
        //console.log('success');
        this_is_this.set('customerResult', data.data);
      }
    }).catch(function (error) {
      let errorMessage = error.responseJSON.errors[0].error;

      if (!errorMessage) {
        errorMessage = '';
      }

      alert('Loading Error. ' + errorMessage);
    });
  }

  var _default = Ember.Controller.extend({
    commonUtility: Ember.inject.service('common-utility'),
    saleController: Ember.inject.controller('transaction-sale.index'),

    init() {
      console.log('i am here for stock flow');
      this.set('current.startDate', (0, _moment.default)().add(-7, 'days').toDate());
      this.set('current.endDate', new Date());
    },

    searchCustomer() {
      searchCustomer(this);
    },

    txtProductDescription: '',
    transactionTypeList: ['[ALL]', 'Exchange', 'Sale', 'Stockin', 'StockTake'],
    actions: {
      selectSale(source, item) {
        //console.log(item);
        let saleController = this.get('saleController');
        saleController.loadSale(item.intSaleID);
        this.transitionToRoute('transaction-sale.index');
      },

      findRecords() {
        searchCustomer(this);
      },

      selectTransactionType(event) {
        let targetEvent = event.target;
        console.log('select trans type4 report stock flow ');
        console.log(targetEvent);
        this.set('selectedTransType', targetEvent.value);
      }

    },
    selectedTransType: '[ALL]',
    totalRecord: 10,
    tableData: {
      columns: [{
        label: 'Type',
        valuePath: 'strStockFlowOriginal',
        width: '150px'
      }, {
        label: 'Date',
        valuePath: 'dteStockFlowDate',
        cellComponent: 'web-table-date',
        width: '150px'
      }, {
        label: 'Product ID',
        valuePath: 'ProductID',
        width: '150px'
      }, {
        label: 'Description',
        valuePath: 'strProductDescription',
        resizable: true
      }, {
        label: 'Size',
        valuePath: 'strStockFlowlnProductSizeID',
        width: '100px'
      }, {
        label: 'Qty',
        valuePath: 'intStockFlowlnQty',
        width: '100px'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    customerTableData: {
      columns: [{
        label: 'Customer ID',
        valuePath: 'id'
      }, {
        label: 'Customer',
        valuePath: 'name'
      }, {
        label: 'Contact Person',
        valuePath: 'contactPerson'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'Remark',
        valuePath: 'remark'
      }, {
        label: 'Blacklisted',
        valuePath: 'isBlacklisted'
      }],
      page: 1,
      noofrecord: 5,
      searchField: ''
    },
    current: {
      customerID: '',
      customerName: '',
      startDate: '',
      endDate: ''
    },
    openSearch: false,
    customerResult: Ember.A([])
  });

  _exports.default = _default;
});