define("webcostume/templates/setup-bank/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sblbKBEh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n\\n    \"],[4,\"link-to\",[\"setup-bank.setup-bank-new\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"New Bank \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n\"],[1,[27,\"web-table-scroll\",null,[[\"data\",\"scrollHeight\",\"columns\",\"selectAction\"],[[23,[\"model\"]],\"60vh\",[23,[\"tableData\",\"columns\"]],[27,\"action\",[[22,0,[]],\"deleteThisRecord\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-bank/index.hbs"
    }
  });

  _exports.default = _default;
});