define("webcostume/templates/setup-bank/setup-bank-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n5SRWqmQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"com-setup-bank\",null,[[\"model\",\"headerMessage\",\"closeAction\",\"submitAction\",\"open\",\"cancelAction\",\"openConfirm\"],[[23,[\"model\"]],[23,[\"headerMessage\"]],[27,\"action\",[[22,0,[]],\"transitionBack\"],null],[27,\"action\",[[22,0,[]],\"submit\"],null],[23,[\"open\"]],[27,\"action\",[[22,0,[]],\"cancelAction\"],null],[23,[\"openConfirm\"]]]]],false],[0,\" \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webcostume/templates/setup-bank/setup-bank-input.hbs"
    }
  });

  _exports.default = _default;
});